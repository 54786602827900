import React, { useState } from 'react';
import InfoIcon from 'Assets/Icons/Info.svg';

const TermsConditions = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    console.log('Hovered: true');
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    console.log('Hovered: false');
  };

  return (
    <div>
      <div className="section-title brd-btm w-full flex relative group gap-2">
        <div
          className="group cursor-pointer flex gap-2"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Terms & Conditions text */}
          Terms & Conditions

          {/* Info Icon */}
          <img src={InfoIcon} alt="info" className="w-[1.25rem]"></img>
        </div>

        {/* The container with group-hover:block */}
        <div
          className={`collection-terms-container border-container  ${
            isHovered ? 'block' : 'hidden'
          } absolute -top-[19rem] left-1/2 transform -translate-x-1/2 p-2 rounded-md w-[101%]`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className='px-[1.5rem] py-[1rem] terms-details flex flex-col gap-4'>
            <div className='flex gap-[2rem]'>
              <div className='text-head w-[11rem]'>
                <span>
                  Copy, Display & Distribute
                </span>
              </div>
              <div className='text-body'>
                <span>
                  You can display, distribute, and copy the artwork of your NFT.
                </span>
              </div>
            </div>
            <div className='flex gap-[2rem]'>
              <div className='text-head w-[11rem]'>
                <span>
                  Sublicense
                </span>
              </div>
              <div className='text-body'>
                <span>
                  You can sublicense your rights to the artwork of your NFT or your modifications and adaptations (if permitted).
                </span>
              </div>
            </div>
            <div className='flex gap-[2rem]'>
              <div className='text-head w-[11rem]'>
                <span>
                  Commercial Use
                </span>
              </div>
              <div className='text-body'>
                <span>
                  You can use the artwork for your NFT for commercial purposes.
                </span>
              </div>
            </div>
            <div className='flex gap-[2rem]'>
              <div className='text-head w-[11rem]'>
                <span>
                  Modify & Adapt
                </span>
              </div>
              <div className='text-body'>
                <span>
                  You can modify and adapt the artwork for your NFT.
                </span>
              </div>
            </div>
            <div className='flex gap-[2rem]'>
              <div className='text-head w-[11rem]'>
                <span>
                  All Speech Permitted
                </span>
              </div>
              <div className='text-body w-[78%]'>
                <span>
                  You may use the artwork of your NFT in any speech.
                  Licenses without a check enable the prohibition of
                  use of the artwork of your NFT in a way that is
                  unlawful, defamatory, harassing, abusive, fraudulent,
                  racist, hateful, vulgar, cruel, illegal or obscene,
                  or that promotes any such activity.
                </span>
              </div>
            </div>
            <div className='flex gap-[2rem]'>
              <div className='text-head w-[11rem]'>
                <span>
                  Creator Retains No Rights
                </span>
              </div>
              <div className='text-body'>
                <span>
                  You have exclusive rights to the artwork for your NFT
                  and the creator retains no right to exploit it.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;

import useHttp from "App/hooks/use-http"
import { useCallback, useEffect, useState } from "react"
import Button from "../Button"
import MultiSelectFilter from "../MultiSelectFilter"
import TemplatePopup from "../TemplatePopup"

const WallColor = (props) => {
    const { triggerAPI } = useHttp();
    const [list, setList] = useState();
    const [wallColor, setWallColor] = useState([])
    const apiResult = useCallback((res) => {
        const list = res.data;
        const arr = list.map((item) => item.name)
        setList(arr)
    }, [])

    const getWallColor = useCallback(() => {
        triggerAPI({
            url: `frame-asset/color/get`, method: 'get'
        }, apiResult);
    }, [apiResult, triggerAPI])

    const handleChange = (e) => {
        if (e.target.checked) {
            setWallColor([...wallColor, e?.target?.name])
        }
        else {
            setWallColor(wallColor.filter(val => val !== e.target.name))
        }
    }

    const applyHandler = () => {
        props.onFilterData({
            ...props.data,
            color: wallColor
        })
        props.setWallColor(wallColor)
    }

    const onCloseHandler = (data) => {
        props.onClose(data)
        setWallColor('')
    }

    useEffect(() => {
        getWallColor()
        setWallColor(props.wallColor)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <TemplatePopup show={props.show} cssClass="date-desk" onClose={props.onCloseHandler}>
            <div className="date-picker p-9 flex flex-col">
                <div className="status-filter-head pb-4 ">Select wall Color</div>
                <div>
                    <MultiSelectFilter list={list} checked={wallColor} onChange={handleChange} />
                </div>
                <div className="flex justify-center gap-4 pb-5 pt-9">
                    <Button label="Reset" className="transaction-cancel" row={{ action: 'color' }} onClick={onCloseHandler} />
                    <Button label="Apply" className="transaction-ok" disabled={wallColor ? false : true} onClick={applyHandler} />
                </div>
            </div>
        </TemplatePopup>
    )
}

export default WallColor
import Button from "App/Components/UI/Button"
import InputBox from "App/Components/UI/Forms/InputBox"
import Validators from "App/Components/UI/Forms/Validators"
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import React, { useCallback, useState } from "react";

const AddWallet = React.memo((props) => {
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        name: {
            value: undefined,
            isRequired: true
        },
        poolAddress: {
            value: undefined,
            isRequired: true
        },
        walletAddress: {
            value: undefined,
            isRequired: true
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const { triggerAPI: createWalletApi } = useHttp();
    const dispatch = useStore(false)[1];
    const onClose = ({ mode = false }) => {
        props.triggerClose(mode)
    }

    const apiWalletResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        onClose({ mode: true });
    }

    const createWalletHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            dispatch('showSpinner');
            createWalletApi({
                url: 'art-bank/add', data: formValues, method: 'post'
            }, apiWalletResult);
        } else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])


    return (
        <div>
            <div className="add-wallet-input">
                <InputBox label="Enter Wallet Name" value={formValues.name} type="text" validateOnBlur className="add-wallet" controlName="name" onChange={inputOnChange} />
                <Validators type="Required" value={formValues.name} message="Required field" controlName="name" onErrors={collectErrors} />
            </div>
            <div className="add-wallet-input pt-2">
                <InputBox label="Enter Pool Address" value={formValues.poolAddress} validateOnBlur type="text" className="add-wallet" controlName="poolAddress" onChange={inputOnChange} />
                <Validators type="Required" value={formValues.poolAddress} message="Required field" controlName="poolAddress" onErrors={collectErrors} />
            </div>
            <div className="add-wallet-input pt-2">
                <InputBox label="Enter Wallet Address" value={formValues.walletAddress} validateOnBlur type="text" className="add-wallet" controlName="walletAddress" onChange={inputOnChange} />
                <Validators type="Required" value={formValues.walletAddress} message="Required field" controlName="walletAddress" onErrors={collectErrors} />
            </div>

            <div className="flex gap-7 justify-center pt-12">
                <Button label="Cancel" onClick={onClose} className='transaction-cancel' />
                <Button className='transaction-ok' label="Add" onClick={createWalletHandler} />
            </div>
        </div>
    )
})

export default AddWallet
import InputBox from "App/Components/UI/Forms/InputBox"
const AddNewRow = () => {
    return (
        <div className="profile-edit-card flex justify-between items-start gap-5">
        <div className="profile-edit-label profile-edit-input">
           <InputBox label="Wallet Name"  type="text" />
        </div>
        <div className="profile-edit-input profile-edit-address">
           <InputBox label="Wallet Address"  type="text"/>
        </div>
        {/* <div className="flex justify-center pt-8">
           <Button label="Delete" className="profile-edit-delete" />
        </div> */}
     </div>
    )
}


export default AddNewRow
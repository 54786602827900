import "./Pagination.scss";
import { useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
const Pagination = ({ itemsPerPage, total, selected = 0, initial = 0, rows = 0, onPageChange }) => {
    const [pageCount, setPageCount] = useState(0);
    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);
    const handlePageClick = (event) => {
        onPageChange(event.selected + 1);
    };
    const leftArrow = <span className="pagination-left"></span>
    const rightArrow = <span className="pagination-right"></span>
    const breakLabel = <span className="pagination-break"></span>
    return (
        <div className="flex justify-center items-center pagination">
            <ReactPaginate
                breakLabel={breakLabel}
                nextLabel={rightArrow}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel={leftArrow}
                initialPage={initial}
                forcePage={selected - 1}
                renderOnZeroPageCount={null}
            />
        </div>
    )
}
export default Pagination;
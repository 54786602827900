import { useEffect, useState } from "react";
import Button from "../Button";
import MultiSelectFilter from "../MultiSelectFilter";
import TemplatePopup from "../TemplatePopup"
import "./StatusFilter.scss"

const StatusFilter = (props) => {
    const list = ['active', 'expired', 'finished', 'prepared', 'error', 'canceled', 'rejected'];
    const [paymentStatus, setPaymentStatus] = useState([])
   
    const handleChange = (e) => {
        if (e.target.checked) {
            setPaymentStatus([...paymentStatus, e.target.name])
        }
        else {
            setPaymentStatus(paymentStatus.filter(val => val !== e.target.name))
        }
    }

    const applyHandler = () => {
        props.onFilterData({
            ...props.data,
            paymentStatus
        })
        props.setPaymentStatus(paymentStatus)
    }

    const onCloseHandler = (data) => {
        props.onClose(data)
        setPaymentStatus('')
    }

    useEffect(() => {
        setPaymentStatus(props.paymentStatus)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <TemplatePopup show={props.show} cssClass="date-desk" onClose={props.onCloseHandler}>
            <div className="date-picker p-9 flex flex-col">
                <div className="status-filter-head pb-4 ">Select Status</div>
                <div>
                    <MultiSelectFilter list={list} checked={paymentStatus} onChange={handleChange} />
                </div>
                <div className="flex justify-center gap-4 pb-5 pt-9">
                    <Button label="Reset" className="transaction-cancel" row={{ action: 'reset' }} onClick={onCloseHandler} />
                    <Button label="Apply" className="transaction-ok" disabled={paymentStatus ? false : true} onClick={applyHandler} />
                </div>
            </div>
        </TemplatePopup>
    )
}

export default StatusFilter
const errorMessage = () => {
   
    const capitalize = (message) => {
        if(typeof message ==='string'){
            message = message.toLowerCase();
            message = message.replace(/['"]+/g, '')
            const messageArr = message.split(" ");
            messageArr[0] = messageArr[0].charAt(0).toUpperCase() +  messageArr[0].slice(1);
            return messageArr.join(" ")
        }
        return message
    }
    return {
        capitalize,
       
    }
}
export default errorMessage;
import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import Button from "App/Components/UI/Button"
import Image from "App/Components/UI/Image"
import Popup from "App/Components/UI/Popup"
import ToggleSwitch from "App/Components/UI/ToggleSwitch"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { Fragment, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import AddCategory from "./AddCategory"
import ActionMenu from "Assets/Images/frame-menu.svg";
import MenuDel from "Assets/Images/menu-del.svg";
import MenuPen from "Assets/Images/menu-pen.svg";
import TemplatePopup from "App/Components/UI/TemplatePopup"
import ConfirmPopup from "App/Components/UI/ConfirmPopup"
import ContentUpload from "../ContentUpload"
const breadCrumbs = [
    {
        link: '/dashboard/information-desk/category',
        label: 'Categories'
    },
    {
        link: null,
        label: 'Category Details'
    }
]

const actionMenu = [{
    label: 'Edit',
    action: 'edit',
    src: MenuPen
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
},
]

const CategoryDetails = () => {
    const [list, setList] = useState();
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const { id } = useParams();
    const [addCategory, setAddCategory] = useState({ mode: false });
    const [viewCategory, setViewCategory] = useState({ mode: false });
    const [showDelete, setShowDelete] = useState({ mode: false });
    const [showData, setShowData] = useState({});
    const [contentData, setContentData] = useState();
    const navigate = useNavigate();
    const toggleSwitch = (id, checked) => {
        getshowOnInfoDesk(id, checked);
    }

    const getshowOnInfoDeskResult = (res) => {
        getInformationDesk()
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const getshowOnInfoDesk = (id, checked) => {
        dispatch('showSpinner');
        const data = {
            id: id,
            showOnInfoDesk: checked
        }
        triggerAPI({
            url: `information-desk/category/status/update`, data, method: 'patch'
        }, getshowOnInfoDeskResult);
    }

    const getDelete = () => {
        dispatch('showSpinner');
        const data = {
            id: showData.id,
        }
        triggerAPI({
            url: `information-desk/category/content/delete`, data, method: 'delete'
        }, getshowOnInfoDeskResult);
    }

    const getInformationDeskResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data;
        setList(list)
    }, [dispatch])

    const getInformationDesk = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `information-desk/category/get/${id}`, method: 'get'
        }, getInformationDeskResult);
    }, [dispatch, getInformationDeskResult, id, triggerAPI])

    const getContentResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data;
        setContentData(list)
    }, [dispatch])

    const getContent = useCallback((e) => {
        dispatch('showSpinner');
        triggerAPI({
            url: `information-desk/category/content/get/${e}`, method: 'get'
        }, getContentResult);
    }, [dispatch, getContentResult, triggerAPI])

    const actionHandler = (e) => {
        if (e.action === 'delete') {
            setShowDelete({ mode: true })
        }
        else {
            navigate(`content/${showData.id}`);
        }
    }

    const editCategoryHanlder = () => {
        setAddCategory({ mode: true })
    }

    const onCloseCategoryDetails = (data) => {
        setAddCategory({ mode: false })
        if (data) {
            getInformationDesk()

        }
    }
    const onCloseViewCategoryDetails = () => {
        setViewCategory({ mode: false })
    }
    const addContentHandler = (e) => {
        navigate(`content/create`);
    }
    const showDataHandler = (e) => {
        getContent(e.currentTarget?.dataset.info)
        setViewCategory({ mode: true })

    }
    const onCloseDelete = (data) => {
        setShowDelete({ mode: false })
        if (data) {
            getDelete()
        }
    }
    useEffect(() => {
        getInformationDesk()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={breadCrumbs} title="Category Details" className="category-breadcrumbs" />
                </div>
                <div>
                    <Button label="Add New Slides" row={list?.id} onClick={addContentHandler} />
                </div>
            </div>
            <div className="pt-7">
                <div className="category-full flex flex-wrap justify-between gap-6">
                    <div className="flex gap-3">
                        <Image alt="image" src={list?.imageUrl} className="category-details-image" width="165px" height="65px" style={{ 'maxWidth': '6.5rem', 'maxHeight': '7.5rem' }} />
                        <div className="mr-3 pl-3">
                            <div className="category-head">{list?.name}</div>
                            <div className="flex gap-1 pt-2 items-center">
                                <div className="category-content">Slides:<span className="ml-2">0{list?.contentCount}</span> </div>
                            </div>
                            <div className="flex gap-2 items-center gallery-details-name pt-3">
                                <div>Active</div>
                                <ToggleSwitch check={list?.showOnInfoDesk} className="gallery-switch" row={list} onChange={toggleSwitch} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between">
                        <div className="flex flex-wrap gap-3">

                            <Button label="Edit" className="transaction-cancel" onClick={editCategoryHanlder} />
                        </div>

                    </div>
                </div>
                <div className="pb-7 pt-5 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-4 gap-4">
                    {list?.infoDeskContents?.map((infos) =>
                        <div className="flex flex-col category cursor-pointer" key={infos.id} data-info={infos.id} onClick={showDataHandler}>
                            <Fragment>
                                <div className="flex justify-center items-center">
                                    <ContentUpload disableDragging={true} width="332px" height="189px"
                                        style={{ 'minHeight': '10.42rem', minWidth: '100%' }} list={list} menu={actionMenu} icon={ActionMenu} onAction={actionHandler} showData={setShowData} datainfo={infos.id} className="info-card info-menu" metaInfo={infos?.metaInfo} src={infos?.imageUrl} videoUrl={infos?.videoUrl} />
                                </div>
                                <div className="p-3 flex flex-col">
                                    <span className="info-title">{infos?.title}</span>
                                    <span className="pt-1 info-content">Slide type: {infos?.contentType}</span>
                                </div>
                            </Fragment>

                        </div>
                    )}

                    <Popup show={addCategory} title='Edit Category' cssClass="free-frames" style={{ 'maxWidth': '38.85rem' }}
                        onClose={onCloseCategoryDetails}>
                        <AddCategory row={list} triggerClose={onCloseCategoryDetails} />
                    </Popup>

                    <TemplatePopup show={viewCategory} title='Edit Category' cssClass="info-desk" style={{ 'maxWidth': '79.14rem' }}
                        onClose={onCloseViewCategoryDetails}>
                        <div className="p-8">
                            <div className="flex justify-end p-1 pb-2">
                                <button className="btn btn-closes" onClick={onCloseViewCategoryDetails}></button>
                            </div>
                            <div className="view flex flex-col w-full">
                                <ContentUpload disableDragging width="300px" height="300px" style={{ 'minWidth': '20rem' }} className="view-video preview-section" metaInfo={contentData?.metaInfo} src={contentData?.imageUrl} videoUrl={contentData?.videoUrl} />
                                <span className="pt-4">{contentData?.title}</span>
                            </div>
                        </div>
                    </TemplatePopup>

                    <ConfirmPopup show={showDelete} content={`Are you sure want to delete this ${showData.contentType} content?`} buttonYes="Delete" buttonCancel="Cancel"
                        style={{ 'maxWidth': '39rem' }}
                        title={`Delete ${showData?.contentType} `} cssClass="remove-category" onClose={onCloseDelete}>
                        <div className="flex flex-col">
                            <span className="info-title">{showData?.title}</span>
                            <div className="info-desk-image pt-4">
                                <ContentUpload disableDragging className="delete-video" metaInfo={showData?.metaInfo} src={showData?.imageUrl} videoUrl={showData?.videoUrl} />
                            </div>

                        </div>
                    </ConfirmPopup>
                </div>
            </div>
        </div>
    )
}

export default CategoryDetails
import Button from "App/Components/UI/Button";
import Image from "App/Components/UI/Image";
import TemplatePopup from "App/Components/UI/TemplatePopup";
import { convertJsonToArray, convertPriceToAda } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import Image9 from "Assets/Images/image 9.png";
import { useCallback, useEffect, useState } from "react";
import "./ViewFrames.scss";
const ViewFrames = ({ show, free, onClose, row, onEdit, isNft = false, cssClass = '' }) => {
    const dispatch = useStore(false)[1];
    const [list, setList] = useState();
    const [framePrice, setFramePrice] = useState(0);
    const { triggerAPI } = useHttp();
    const onClosePopup = () => {
        onClose(false)
    }
    const getFreeFrameResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data;
        const pricelist = list?.project?.pricelist;
        let price = 0;
        if (pricelist) {
            const priceData = convertJsonToArray(pricelist);
            price = priceData?.length ? (priceData[0]?.priceInLovelace ? `ADA ${convertPriceToAda(priceData[0]?.priceInLovelace)}` : `${priceData[0]?.currency?.toUpperCase()} ${priceData[0]?.price}`) : 0;
        }
        setFramePrice(price);
        setList(list)
    }, [dispatch])

    const getFreeFrames = useCallback(() => {
        if (row) {
            dispatch('showSpinner');

            triggerAPI({
                url: `frame-asset/get/${row}`, method: 'get'
            }, getFreeFrameResult);
        }
    }, [dispatch, getFreeFrameResult, row, triggerAPI])

    const onEditHandler = (e) => {
        onEdit(e)
    }
    useEffect(() => {
        getFreeFrames()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row])
    return (
        <div>
            <TemplatePopup show={show} cssClass={`free-frames view-frames-popup ${cssClass}`} style={{ 'maxWidth': '79em' }}>
                <div className="flex">
                    <div className="justify-center items-center flex view-image">
                        <Image src={list?.s3ImageUrl ?? Image9} />
                    </div>
                    <div className="view-details gap-10">
                        <div className="flex justify-between">
                            <span>{list?.name} </span>
                            <div className="flex gap-10 items-center">
                                {!isNft && <Button className="transaction-cancel" label="Edit" row={row} onClick={onEditHandler} />}
                                <button className="btn btn-close" onClick={onClosePopup}></button></div>
                        </div>
                        <p>Asset ID: {list?.id}</p>
                        {!free && (<div className="view-price">{`${framePrice}`}</div>)}

                        <div className="flex flex-wrap gap-3 py-4 justify-between">
                            <div className="view-card">
                                <p>Frame Type </p>
                                <span>{list?.age?.name}</span>
                            </div>
                            <div className="view-card">
                                <p>Style</p>
                                <span>{list?.style?.name}</span>
                            </div>
                            <div className="view-card">
                                <p>Color </p>
                                <span>{list?.color?.name}</span>
                            </div>
                            <div className="view-card">
                                <p>Materials </p>
                                <span>{list?.material?.name}</span>
                            </div>
                            <div className="view-card">
                                <p>Shape </p>
                                <span>{list?.shape?.name}</span>
                            </div>
                            <div className="view-card">
                                <p>Complexity </p>
                                <span>{list?.complexity?.name}</span>
                            </div>
                            <div className="view-card">
                                <p>Add-ons</p>
                                <span>{list?.addOn?.name}</span>
                            </div>
                        </div>

                        <p>Description</p>
                        <div className="view-desc pt-4">{list?.description}</div>
                    </div>
                </div>
            </TemplatePopup>
        </div>
    )
}
export default ViewFrames

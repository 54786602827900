import Button from "../Button"
import Popup from "../Popup"
import "./ConfirmPopup.scss"

const ConfirmPopup = ({ show = { mode: false }, style = {}, cssClass = '', title, content = '', children, buttonCancel = '', buttonYes = '', onClose }) => {

    const onClickHandler = (e) => {
        if (onClose) {
            onClose(e?.status)
        }

    }
    const onClosePopup = () => {
        onClose(false)
    }
    return (
        <div>
            <Popup show={show}
                style={style}
                title={title} cssClass={cssClass} onClose={onClosePopup}>
                <div className={cssClass}>
                    <div className="content">{content}</div>
                    {children && (<div className="content-card mt-6">
                        {children}
                    </div>)}
                    <div className="flex justify-center gap-4 pt-7">
                        <Button label={buttonCancel} row={{ status: false }} onClick={onClickHandler} className='transaction-cancel' />
                        <Button className='transaction-ok' row={{ status: true }} onClick={onClickHandler} label={buttonYes} />
                    </div>
                </div>
            </Popup>

        </div>
    )
}

export default ConfirmPopup
import Button from "App/Components/UI/Button";
import InputBoxLogin from "App/Components/UI/Forms/InputBoxLogin";
import LinkButton from "App/Components/UI/LinkButton";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";

const ForgotPassword = React.memo(() => {
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        email: {
            value: undefined,
            isRequired: true
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    let navigate = useNavigate();
    const dispatch = useStore(false)[1];
    const goToLogin = useCallback(() => {
        navigate("/", { replace: true });
    }, [navigate])
    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        goToLogin();
    }
    const { triggerAPI: forgotPasswordAPI } = useHttp();
    const forgotPasswordHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            dispatch('showSpinner');
            forgotPasswordAPI({
                url: 'admin/forget-password', data: formValues, method: 'post'
            }, apiResult);
        } else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    return (
        <div className="login-layout-wrapper">
            <h1 className="login-layout-title mb-12"> Forgot Password</h1>
            <div className="login-layout-row login-layout-description">
                <p>Enter your registered email address and we'll send you a link to get back into your account. </p>
            </div>
            <div className="login-layout-row mt-8">
                <InputBoxLogin label="Email Address" validateOnBlur type="text" controlName="email" onChange={inputOnChange} config={{ hasEyeIcon: false }} />
                <Validators type="Required" value={formValues.email} message="Required field" controlName="email" onErrors={collectErrors} />
                <Validators type="Email" value={formValues.email} message="Invalid Email" controlName="email" onErrors={collectErrors} />
            </div>
            <div className="login-layout-row mt-6">
                <Button className="btn-login" label="Send Link" onClick={forgotPasswordHandler} />
            </div>
            <div className="login-layout-row login-layout-font mt-10 flex">
                <span className="btn-link-color mr-2">Back to</span>
                <LinkButton label="Login" route="/" />
            </div>
        </div>
    )
})
export default ForgotPassword;
import Image from "../Image";

const ImageReplace = (props) => {
    const imageUpload = (e) => {
        props.onChange(e.target.files[0])
    }

    const src = props.src ?? ''
    return (
        <div className={`flex ${props.className}`}>
            <div className="upload-image">
                <Image src={src} dummyImage={props.dummyImage} style={{ 'minWidth': '100px', 'minHeight': '100px' }} />
            </div>
            {props.upload &&
                <div className="flex justify-center items-center">
                    <div className="column">
                        <div className="upload justify-center items-center pt-3 cursor-pointer">
                            <div className="image-label">
                                <input type="file" onChange={imageUpload} />
                            </div>
                            {props.upload}
                        </div>
                        <p className="sub">{props?.content}</p>
                    </div>
                </div>
            }



        </div>
    )
}

export default ImageReplace
import PageTab from "App/Components/UI/PageTab";
import { Outlet } from "react-router-dom";

const Subscription = () => {
  const tabs = [
    {
      link: `plans`,
      label: "Collectors",
    },
  ];

  return (
    <div>
      <PageTab tabs={tabs} />
      <div className="py-4">
        <Outlet />
      </div>
    </div>
  );
};
export default Subscription;

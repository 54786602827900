const Button = ({ onClick, row = {}, label = '', className = '', disabled = false }) => {
    const onClickHandler = () => {
        if (onClick) {
            onClick(row)
        }
    }
    return (
        <button type="button" disabled={disabled} onClick={onClickHandler} className={`btn btn-full btn-primary btn-full ${className}`}>{label}</button>
    )
}
export default Button
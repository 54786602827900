import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import SelectBox from "App/Components/UI/SelectBox";
import TextArea from "App/Components/UI/TextArea";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { convertJsonToArray, convertPriceToAda, extractFormFieldKeys } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import "./AddFrameForm.scss"
import FrameImage from "Assets/Images/frame-upload.png";
import FramePreview from "App/Components/UI/FramePreview";
import Popup from "App/Components/UI/Popup";
import FrameUploadPopup from "../FrameUploadPopup";
import Image from "App/Components/UI/Image";

const AddFrameForm = ({ onChange, nft, touched = false, free }) => {
    const [ShowConfirmPopup, setShowConfirmPopup] = useState({ mode: false });
    const statuses=[{value: "active", name: "active" },{ value: "inactive", name: "inactive" }]
    const formGroup = useMemo(() => ({
        displayname: {
            value: undefined,
            isRequired: true
        },
        ageId: {
            value: undefined,
            isRequired: true
        },
        styleId: {
            value: undefined,
            isRequired: true
        },
        colorId: {
            value: undefined,
            isRequired: true
        },
        materialId: {
            value: undefined,
            isRequired: true
        },
        shapeId: {
            value: undefined,
            isRequired: true
        },
        complexityId: {
            value: undefined,
            isRequired: true
        },
        addOnId: {
            value: undefined,
            isRequired: true
        },
        price: {
            value: undefined,
            isRequired: true
        },
        totalEdition: {
            value: undefined,
            isRequired: true
        },
        description: {
            value: undefined,
            isRequired: false
        },
        s3ImageUrl: {
            value: undefined,
            isRequired: true
        },
        s3ImageUrlLandscape: {
            value: undefined,
            isRequired: true
        },
        portraitFile: {
            value: undefined,
            isRequired: false
        },
        landscapeFile: {
            value: undefined,
            isRequired: false
        },
        metaInfo: {
            value: undefined,
            isRequired: false
        },
        uid: {
            value: undefined,
            isRequired: true
        },
        groupId: {
            value: undefined,
            isRequired: true
        },
        status: {
            value: undefined,
            isRequired: true
        },
    }), [])

    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [age, setAgeList] = useState();
    const [styleList, setStyleList] = useState();
    const [materialList, setMaterialList] = useState();
    const [shapeList, setShapeList] = useState();
    const [complexityList, setComplexityList] = useState();
    const [colorList, setColorList] = useState();
    const [addOnList, setAddOnList] = useState();
    const [groupsList, setGroupsList] = useState();
    const [statusList, setStatusList] = useState()
    const [projectList, setProjectList] = useState([]);
    const [currency, setCurrency] = useState("ADA");
    const { id } = useParams();
    const [formVaildState, setFormVaildState] = useState({});
    const [frameConfig, setFrameConfig] = useState(null);
    const [toggleChange, setToggleChange] = useState(true);
    const [toggleChangeRotate, setToggleChangeRotate] = useState(false);

    const inputOnChange = (e) => {
        if (e?.uid) {
            console.log("1")
            const projectData = projectList?.find(project => project?.id === (e?.uid));
            setFormvalues((prev) => {
                return {
                    ...prev,
                    totalEdition: projectData?.maxNftSupply ?? 0,
                    uid: e?.uid,
                    price: projectData?.price ?? 0,
                }
            })
            setCurrency(projectData?.currency);
        } else {
            console.log("1",e)

            setFormvalues((prev) => {
                return {
                    ...prev,
                    ...e,
                }
            })
        }

    }
    const framePositionHandler = useCallback((pos) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                metaInfo: pos
            }
        });
    }, [])
    const uploadHandler = (e) => {
        if (e) {
            const s3ImageUrl = URL.createObjectURL(e)
            setFormvalues((prev) => {
                return {
                    ...prev,
                    portraitFile: e,
                    s3ImageUrl,
                }
            });
        }
    }

    const uploadLandHandler = (e) => {
        if (e) {
            const s3ImageUrlLandscape = URL.createObjectURL(e)
            setFormvalues((prev) => {
                return {
                    ...prev,
                    landscapeFile: e,
                    s3ImageUrlLandscape,
                }
            });
        }
    }

    const openUploaderPopup = () => {
        setShowConfirmPopup({ mode: true })
    }
    const getAgeFrames = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/age/get`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }

    const getComplexityFrames = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/complexity/get`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }

    const getStyleFrames = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/style/get`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }

    const getMaterialFrames = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/material/get`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }


    const getShapeList = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/shape/get`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }

    const getColorFrames = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/color/get`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }
    const getGroups = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/groups`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }
    const getActiveProjects = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `nft-maker/project/active`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }

    const getAddOnFrames = () => {
        return new Promise((resolve) => {
            triggerAPI({
                url: `frame-asset/addOn/get`, method: 'get'
            }, (res => {
                resolve(res.data)
            }));
        })
    }

    const getEditFrameResult = useCallback((res) => {
        dispatch('hideSpinner')
        if (res.data?.name) {
            res.data.displayname = res.data?.name;
        }
        const list = extractFormFieldKeys({ ...formGroup, id: '' }, res.data);
        const newValues = markAsTouched(list);
console.log()
        setFormvalues({ ...newValues, portraitFile: '', landscapeFile: '' });
        setFrameConfig({ portrait: list?.metaInfo?.portrait ?? {}, landscape: list?.metaInfo?.landscape ?? {} });

    }, [dispatch, formGroup])

    const getEditFrames = useCallback(() => {
        if (id) {
            dispatch('showSpinner');
            triggerAPI({
                url: `frame-asset/get/${id}`, method: 'get'
            }, getEditFrameResult);
        }
    }, [dispatch, getEditFrameResult, id, triggerAPI])

    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    const getDropDown = () => {
        dispatch('showSpinner');
        Promise.all([
            getAgeFrames(),
            getStyleFrames(),
            getMaterialFrames(),
            getShapeList(),
            getComplexityFrames(),
            getColorFrames(),
            getAddOnFrames(),
            getActiveProjects(),
            getGroups()
        ]).then(res => {
            dispatch('hideSpinner');
            const [age, style, material, shape, complexity, color, addOn, project, groups] = res;
            setAddOnList(addOn);
            setAgeList(age);
            setColorList(color);
            setMaterialList(material);
            setComplexityList(complexity);
            setStyleList(style);
            setShapeList(shape);
            setGroupsList(groups);
            setStatusList(statuses)

            getEditFrames()
            const projectList = project?.list?.map((project, index) => {
                const priceData = convertJsonToArray(project?.pricelist);
                let price = 0, currency = 'ADA';
                if (priceData?.length) {
                    price = priceData[0].priceInLovelace ? convertPriceToAda(priceData[0].priceInLovelace) : priceData[0].price;
                    currency = priceData[0].priceInLovelace? currency : (priceData[0].currency)?.toUpperCase();
                }
                return {
                    id: project?.uid,
                    value: project?.uid,
                    name: project?.projectname,
                    maxNftSupply: project?.maxNftSupply,
                    price,
                    currency
                }
            })
            setProjectList(projectList);
        })
    }
    const onCloseCreateUser = (e) => {
        setShowConfirmPopup({ mode: false })
    }

    useEffect(() => {
        if (touched) {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [touched])

    useEffect(() => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        onChange(formValues, valid)
    }, [onChange, formValues, formVaildState, formGroup])

    useEffect(() => {
        getDropDown()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
            <div className="flex items-start justify-between gap-4">
                <div className="add-image p-6">
                    <div className="gap-4 flex flex-col">
                        {/* <FrameUpload row={formValues} setToggleChangeRotate={setToggleChangeRotate} toggleChangeRotate={toggleChangeRotate} setToggleChange={setToggleChange} toggleChange={toggleChange} upload className="add-image-frame" dummyImage={FrameImage} onFramePosUpdate={framePositionHandler} src={formValues.s3ImageUrl} metaInfo={frameConfig} onChange={uploadHandler} controlName="file" /> */}
                        {formValues?.metaInfo?.portrait && <FramePreview toggleChange={toggleChange} noPreview={true} noStyle={false} metaInfo={formValues?.metaInfo?.portrait} src={formValues?.s3ImageUrl} />
                        }
                        {formValues?.metaInfo?.landscape && <FramePreview noPreview={true} toggleChange={toggleChange} noStyle={false} metaInfo={formValues?.metaInfo?.landscape} src={formValues?.s3ImageUrlLandscape} />
                        }                    </div>
                    {(!formValues?.metaInfo?.landscape || !formValues?.metaInfo?.portrait) && <Image dummyImage={FrameImage} src={FrameImage} className='pt-[2rem]' />
                    }
                    <div className="frame-upload-text flex flex-col mt-5" onClick={openUploaderPopup}>
                        <div className="flex justify-between w-full">
                            <div className="flex flex-col cursor-pointer">
                                <h4>{id ? 'Edit Frame Dimensions' : 'Upload Frame'}</h4>
                            </div>

                        </div>
                    </div>
                    <div className="file-validate">
                        <Validators type="Required" value={formValues.s3ImageUrl} message="Vertical Image is required" controlName="portraitFile" onErrors={collectErrors} />
                    </div>
                </div>

                <div className="add-form px-8 py-9">
                    <div className="flex justify-between lg:flex-row flex-col gap-6 pt-6 create-form-field">
                        <div className="w-full">
                            <InputBox label="Name" type="text" validateOnBlur controlName="displayname" className="frame-form" onChange={inputOnChange} value={formValues.displayname} />
                            <Validators type="Required" value={formValues.displayname} message="Required field" controlName="displayname" onErrors={collectErrors} />
                        </div>
                        <div className="w-full">
                            <SelectBox validateOnBlur list={age} placeHolder="Select" label="Age" className="frame-form" controlName="ageId" onChange={inputOnChange} currentId={formValues.ageId} tabIndex={0} />
                            <Validators type="Required" value={formValues.ageId} message="Required field" controlName="ageId" onErrors={collectErrors} />
                        </div>
                    </div>
                    <div className="flex justify-between lg:flex-row flex-col  gap-6 pt-6 create-form-field">
                        <div className="w-full">
                            <SelectBox validateOnBlur list={styleList} placeHolder="Select" label="Style" className="frame-form" controlName="styleId" onChange={inputOnChange} currentId={formValues.styleId} tabIndex={0} />
                            <Validators type="Required" value={formValues.styleId} message="Required field" controlName="styleId" onErrors={collectErrors} />
                        </div>
                        <div className="w-full">
                            <SelectBox validateOnBlur list={colorList} placeHolder="Select" label="Color" className="frame-form" controlName="colorId" onChange={inputOnChange} currentId={formValues.colorId} tabIndex={0} />
                            <Validators type="Required" value={formValues.colorId} message="Required field" controlName="colorId" onErrors={collectErrors} />
                        </div>
                    </div>
                    <div className="flex justify-between lg:flex-row flex-col create-form-field gap-6 pt-6 access-plan-edit-form-field">
                        <div className="w-full">
                            <SelectBox validateOnBlur list={materialList} placeHolder="Select" label="Material" className="frame-form" controlName="materialId" onChange={inputOnChange} currentId={formValues.materialId} tabIndex={0} />
                            <Validators type="Required" value={formValues.materialId} message="Required field" controlName="materialId" onErrors={collectErrors} />
                        </div>
                        <div className="w-full">
                            <SelectBox validateOnBlur list={shapeList} placeHolder="Select" label="Shape" className="frame-form" controlName="shapeId" onChange={inputOnChange} currentId={formValues.shapeId} tabIndex={0} />
                            <Validators type="Required" value={formValues.shapeId} message="Required field" controlName="shapeId" onErrors={collectErrors} />
                        </div>
                    </div>
                    <div className="flex justify-between lg:flex-row flex-col create-form-field  gap-6 pt-6 create-form-field">
                        <div className="w-full">
                            <SelectBox validateOnBlur list={complexityList} placeHolder="Select" label="Complexity" className="frame-form" controlName="complexityId" onChange={inputOnChange} currentId={formValues.complexityId} tabIndex={0} />
                            <Validators type="Required" value={formValues.complexityId} message="Required field" controlName="complexityId" onErrors={collectErrors} />
                        </div>
                        <div className="w-full">
                            <SelectBox validateOnBlur list={addOnList} placeHolder="Select" label="Adds-on" className="frame-form" controlName="addOnId" onChange={inputOnChange} currentId={formValues.addOnId} tabIndex={0} />
                            <Validators type="Required" value={formValues.addOnId} message="Required field" controlName="addOnId" onErrors={collectErrors} />
                        </div>
                    </div>
                    {nft && (<div className="flex justify-between create-form-field lg:flex-row flex-col  gap-6 pt-6 ">
                        <div className="w-full">
                            <InputBox label="Price" pre={currency} validateOnBlur type="text" disabled={true} className="frame-form" controlName="price" onChange={inputOnChange} value={formValues.price} />
                        </div>
                        <div className="w-full">
                            <InputBox label="Total Supply" disabled={true} type="number" validateOnBlur className="frame-form" controlName="totalEdition" onChange={inputOnChange} value={formValues.totalEdition} />
                        </div>
                    </div>)}
                    {nft && (<div className="flex justify-between create-form-field lg:flex-row flex-col  gap-6 pt-6 ">
                        <div className="w-full">
                            <SelectBox validateOnBlur list={projectList} placeHolder="Select" label="Project" className="frame-form" controlName="uid" onChange={inputOnChange} currentId={formValues.uid} tabIndex={0} />
                            <Validators type="Required" value={formValues.uid} message="Required field" controlName="uid" onErrors={collectErrors} />
                        </div>
                    </div>)}
                    {free && 
                        <div className="flex justify-between lg:flex-row flex-col create-form-field  gap-6 pt-6 create-form-field">
                            <div className="w-full">
                                <SelectBox validateOnBlur list={groupsList} placeHolder="Select" label="Group" className="frame-form" controlName="groupId" onChange={inputOnChange} currentId={formValues.groupId} tabIndex={0} />
                                <Validators type="Required" value={formValues.groupId} message="Required field" controlName="groupId" onErrors={collectErrors} />
                            </div>
                        </div>
                    }
                 
                     <div className="flex justify-between lg:flex-row flex-col create-form-field  gap-6 pt-6 create-form-field">
                            <div className="w-full">
                                <SelectBox validateOnBlur list={statusList} placeHolder="Select" label="Status" keyName="value" className="frame-form" controlName="status" onChange={inputOnChange} currentId={formValues.status} tabIndex={0} />
                                <Validators type="Required" value={formValues.status} message="Required field" controlName="status" onErrors={collectErrors} />
                            </div>
                        </div>
                        
                    <div className="flex justify-between gap-6 pt-6">
                        <div className="w-full">
                            <TextArea showTextCount={true} maxLength={300} label="Description" className="frame-form-desc" controlName="description" onChange={inputOnChange} value={formValues.description} />
                        </div>
                    </div>
                </div>

            </div >
            <Popup show={ShowConfirmPopup}
                style={{ 'width': '95vw', 'height': '95vh' }}
                cssClass="frame-upload-popup" onClose={onCloseCreateUser}>
                <FrameUploadPopup
                    triggerClose={onCloseCreateUser}
                    metaPortInfo={frameConfig?.portrait}
                    metaLandInfo={frameConfig?.landscape}
                    srcPort={formValues.s3ImageUrl}
                    srcLand={formValues.s3ImageUrlLandscape}
                    formValues={formValues}
                    metaInfoNew={formValues?.metaInfo?.portrait}
                    metaInfoNewLand={formValues?.metaInfo?.landscape}
                    setToggleChangeRotate={setToggleChangeRotate}
                    toggleChangeRotate={toggleChangeRotate}
                    setToggleChange={setToggleChange}
                    toggleChange={toggleChange}
                    uploadPortHandler={uploadHandler}
                    uploadLandHandler={uploadLandHandler}
                    framePositionHandler={framePositionHandler}
                    onClose={onCloseCreateUser}
                    isEdit={id ? true : false}
                />
            </Popup>
        </Fragment>
    )
}
export default AddFrameForm
import { Fragment, useEffect, useState } from "react";
const SidePanel = ({ title, show = { mode: false }, position = 'right', children, onClose }) => {
    const [visibility, setVisibility] = useState(false);
    const [activeClass, setActiveClass] = useState(false);
    const closePanelHendler = () => {
        setActiveClass(false)
        setTimeout(() => {
            setVisibility(false);
            onCloseHandler(false);
        }, 500);//time based on transition time
    }
    const onCloseHandler = (arg) => {
        if (onClose) {
            onClose(arg)
        }
    }
    useEffect(() => {
        setVisibility(show.mode);
        setTimeout(() => {
            setActiveClass(show.mode);
        }, 100)
    }, [setVisibility, show])
    return (
        <Fragment>
            {visibility && (<div className={`sidebar ${activeClass ? 'sidepanelActive' : ''}`}>
                <div className="sidebar-overlay" onClick={closePanelHendler}></div>
                <div className={`sidebar-wrapper ${position === 'left' ? 'leftSide' : 'rightSide'}`}>
                    <div className="sidebar-header flex justify-between items-center">
                        <p className="sidebar-title">{title}</p>
                        <button className="btn btn-close" onClick={closePanelHendler}></button>
                    </div>
                    <div className="sidebar-body">
                        {children}
                    </div>
                </div>
            </div>)}
        </Fragment>
    )
}
export default SidePanel;
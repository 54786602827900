
const ViewSecurityPopup = ({ rowData = {} }) => {
    return (
        <div>
            <iframe src={rowData?.fileUrl ?? rowData?.link} width="100%" title="Terms and Conditions" height="500px">
            </iframe>
        </div>
    )
}

export default ViewSecurityPopup
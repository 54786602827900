import FrameUpload from "App/Components/UI/FrameUpload";
import FrameImage from "Assets/Images/frame-upload.png";
import React, { useEffect, useState } from "react";
const FrameRND = React.memo(({ onFramePositionChange, metaInfo, toggleChange, src, onFileUpload, formValues, metaInfoNew, tabIndex = 0, isEdit = false }) => {
    const [frameConfig, setFrameConfig] = useState(null);
    const [imageData, setImageData] = useState({});
    const uploadHandler = (e) => {
        if (e) {
            const s3ImageUrl = URL.createObjectURL(e)
            setImageData((prev) => {
                return {
                    file: e,
                    s3ImageUrl,
                }
            });
            onFileUpload({
                file: e,
                s3ImageUrl,
            })
        }
    }
    useEffect(() => {
        if (metaInfo) {
            const parsedMetaInfo = JSON.parse(metaInfo);
            setFrameConfig(parsedMetaInfo)
        }
    }, [metaInfo])


    useEffect(() => {
        setImageData({ s3ImageUrl: src })
    }, [src])
    return (
        <>
            <div>
                <FrameUpload
                    upload className="frame-top"
                    dummyImage={FrameImage}
                    onFramePosUpdate={onFramePositionChange}
                    src={imageData.s3ImageUrl}
                    metaInfo={frameConfig}
                    onChange={uploadHandler}
                    controlName="file"
                    formValues={formValues}
                    toggleChange={toggleChange}
                    metaInfoNew={metaInfoNew} tabIndex={tabIndex} isEdit={isEdit} />
            </div>
        </>
    )
})
export default FrameRND
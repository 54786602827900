import FrameCard from "../../../Components/FrameCard"

const FreeFramesCard = ({ row = {}, onClickHandler }) => {
    return (
        <div>
            <FrameCard src={row.s3ImageUrl256} row={row} onClickHandler={onClickHandler} />
            <div className="pt-2 frames p-3">
                <ul>
                    {row.name}
                    <li>ID: {row.id}</li>
                    <li>Status: {row.status}</li>
                </ul>
            </div>

        </div>
    )
}

export default FreeFramesCard
function SubscriptionDetailItem({
  galleryType3D,
  availableGalleries,
  devices,
  anywallDisplayCount,
  discoverProfile,
  availablePremiumFrame,
  walletLimit,
  availableCollections,
  mintRebate,
  isAvailablePremiumWall,
  mediaStorage,
  title="Features for Collectors",
  type
}) {
  return (
    <div className="col-span-1 p-4 font-thin leading-loose">
      <div>
      <span className="font-bold text-gray-400">
      { title}
          </span>
        {galleryType3D&&<p>
          <span className="font-bold">{galleryType3D}</span> 3D{" "}
          {galleryType3D.toLowerCase() === "all" ? "Galleries" : "Gallery"}
        </p>}
        {availableGalleries&&<p>
          <span className="font-bold">{availableGalleries}</span>{" "}
          {Number(availableGalleries) > 1 ? "Galleries" : "Gallery"}
        </p>}
        {devices && <p>
          Use on <span className="font-bold">{devices}</span>{" "}
          {devices > 1 ? "devices" : "device"} at once
        </p>}
        {availablePremiumFrame &&<p>
          <span className="font-bold">{availablePremiumFrame}{" "}</span>
          Premium{" "}{+availablePremiumFrame > 1 ? 'Frames' : 'Frame'} </p>}
          {!!(type==='collectors') && <p>
          <span className="font-bold">
            {isAvailablePremiumWall ? "Premium" : "Basic"}
          </span>{" "}
          Walls
        </p>}
          { anywallDisplayCount && anywallDisplayCount > 0 ? (
          <p>
            Display on <span className="font-bold">{anywallDisplayCount}</span>{" "}
            Anywall display
          </p>
        ) : (type==='collectors' &&
          <p>
            <span className="font-bold">{"No"}</span> Anywall display
          </p>
        )}
          {walletLimit&&<p>
          <span className="font-bold">
            {walletLimit||0}
          </span>{" "}
          {`Viewable Wallet${(Number(walletLimit) > 1)?"s":""}`}
        </p>}
        {discoverProfile&&<p>
          <span className="font-bold">
            {discoverProfile ? "Discoverable" : "Undiscoverable"}
          </span>{" "}
          Profile
        </p>}

      { <p>
          <span className="font-bold">
          {Number(mediaStorage) > 0 ? `${mediaStorage} ` : ""}
            </span>
            <span>
          {Number(mediaStorage) > 0 ? `MB` : ""}
            </span>
        </p>}

          <br></br>
          <span className="font-bold text-gray-400">
          Features for Creators
          </span>
        {(availableCollections || availableCollections === 0) && <p>
          <span className="font-bold">{availableCollections}</span>{" "}
          {Number(availableCollections) > 1 ? "Collections" : "Collection"}
        </p>}
        {(mintRebate ||mintRebate===0) &&<p>
          <span className="font-bold">{mintRebate}{"%"}</span>
           {" Mint rebate"}
        </p>}
      </div>
    </div>
  );
}

export default SubscriptionDetailItem;

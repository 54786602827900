/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
import "../../../Pages/RoleAndPermissions/Components/ToggleAccordion/ToggleAccordion.scss"

const Toggle = ({ status, onToggle }) => {
  const onToggleChange = (id, checked) => {
    onToggle(id, checked);
  };
  return (
    <div className="wrap toggle-accordion">
      <div className="container">
        <div className="flex">
          <div className="">
            <div className="nav column nav-pills">
              <div className={"nav-link active"}>{"Enable Registration"}</div>
            </div>
          </div>
          <div className="">
            <div className="tab-content">
              <div className={"tab-pane fade show active"}>
                <ToggleSwitch
                  className="role-switch"
                  check={status}
                  row={{ id: 1 }}
                  onChange={onToggleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Toggle;

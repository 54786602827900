

import sortUp from "Assets/Images/sortUp.svg";
import sortDown from "Assets/Images/sortDown.svg";
import "./TableHeader.scss"
import React, { useEffect, useState } from "react";

const TableHeader = React.memo(({ label, sortKey, sort, onSort }) => {
    const [direction, setDirection] = useState('');
    const sortHandler = () => {
        onSort(sortKey)
    }
    useEffect(() => {
        if(sort?.sortKey === sortKey){
            setDirection(sort?.direction)
        }else{
            setDirection('')
        }
    }, [sort, sortKey])
    return (
        <div className="flex items-center">
            <span>
                {label}
            </span>
            {!!sortKey && (<span className={`sort-icon ${(direction === 'ASC') ? 'showUP' : (direction === 'DESC') ? 'showDown' : ''}`} onClick={sortHandler}>
                <img src={sortUp} alt="sort up" className="sort-icon-up" width="12px" height="4px"></img>
                <img src={sortDown} alt="sort down" className="sort-icon-down" width="12px" height="4px"></img>
            </span>)}
        </div>
    )
})
export default TableHeader;
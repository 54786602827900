import ConfirmPopup from "App/Components/UI/ConfirmPopup"
import User from "App/Components/UI/User"

const DeleteGallery = ({ show, onClose, row }) => {

    return (
        <ConfirmPopup show={show} content='Are you sure want to delete this gallery?' buttonYes="Delete" buttonCancel="Cancel"
            style={{ 'maxWidth': '39rem' }}
            title="Delete Gallery" cssClass="delete-user" onClose={onClose}>
            <div className="flex block-user-card">
                <User src={row?.previewImageUrl} label={row?.name} email={`Creator: ${row?.user?.name}`} className="nft-user" />
            </div>
        </ConfirmPopup>
    )
}

export default DeleteGallery
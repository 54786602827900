import styles from './CheckBox.module.scss'
import selectedCheck from 'Assets/Images/selectedCheck.svg'
import unselectedCheck from 'Assets/Images/unselectedCheck.svg'
import greySelectedCheck from 'Assets/Images/greySelectedCheck.svg'
import greyUnselectedCheck from 'Assets/Images/greyUnselectedCheck.svg'
import selectedCheckBlue from 'Assets/Images/checked_blue.svg'
import unselectedCheckBlue from 'Assets/Images/unchecked_blue.svg'
import { Fragment, useEffect, useState } from 'react'
import CaptionImage from "Assets/Images/Caption.svg"

const CheckBox = props => {
    const [toggle, setToggle] = useState(false)
    useEffect(() => {
        if (props.checked) setToggle(true)
        else setToggle(false)
    }, [props.checked])
    const toggleOnHandler = () => {
        if (!props?.disabled) {
            setToggle(true)
            if (!!props.multiple)
                props.onClick({ option: props.children, checked: true })
            else
                props.onClick(true)
        }
    }

    const toggleHandlerCheck = () => {
        if (!props?.disabled) {
            setToggle(!toggle)
            if (!!props.multiple)
                props.onClick({ option: props.children, checked: !toggle })
            else
                props.onClick(!toggle)
        }
    }
    const toggleOffHandler = () => {
        if (!props?.disabled) {
            setToggle(false)
            if (!!props.multiple)
                props.onClick({ option: props.children, checked: false })
            else
                props.onClick(false)
        }
    }
    const toggleHandler = (params) => {
        if (toggle)
            toggleOffHandler()
        else
            toggleOnHandler()
    }

    return (<div className={`${styles.checkbox} ${props.className}`}>
        {!!props.checkIcnAfter && <label onClick={toggleHandler}>{props.children}</label>}
        <div className={`${styles['input-box']}`}>
            {!props?.previewCheck &&
                <Fragment>
                     <img alt='No Img' data-option={props.children} onClick={toggle?toggleOffHandler:toggleOnHandler} src={toggle?props.blueCheckbox ? selectedCheckBlue :props?.greyCheck?greySelectedCheck: selectedCheck:props.blueCheckbox ? unselectedCheckBlue :props?.greyCheck?greyUnselectedCheck: unselectedCheck} />
                   </Fragment>}

            {props?.previewCheck && <img src={props?.src ? props?.src : CaptionImage} alt='No Img' className={`${toggle ? 'caption-yes' : 'caption-no'}`} onClick={toggleHandlerCheck} />
            }
        </div>
        {!props.checkIcnAfter && <label onClick={toggleHandler}>{props.children}</label>}
    </div>)
}

export default CheckBox
const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        showSpinner: (current) => {
            return {
                spinnerMode: true
            }
        },
        hideSpinner: (current) => {
            return {
                spinnerMode: false
            }
        }
    }
    initStore(actions, {
        spinnerMode: false
    });
}
export default configureStore;
import { useEffect } from "react";

const Tabs = ({ tabs = [],selected,setSelected }) => {
  useEffect(() => {
    setSelected(tabs[0]?.label);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClickHandler = (e) => {
    const { dataset: { id } } = e.currentTarget;
    setSelected(id);
  };
  return (
    <div className="tab-page">
      <ul className="flex-wrap">
        {tabs?.map((tab) => (
          <li key={tab?.label}>
            <p
              className={`cursor-pointer capitalize ${selected?.toLowerCase() === tab?.label?.toLowerCase() ? "tab-page-active" : ""}`}
              data-id={tab?.label}
              onClick={onClickHandler}
            >
              {tab?.label}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Tabs;

import TableView from "../../../Security/Pages/Components/TableView";

const Terms = () => {
    return (
        <>
            <TableView type={1} label="Add Terms and Conditions" deleteWarning="Terms and Condition" />
        </>
    )
}

export default Terms;
import ButtonAction from "App/Components/UI/ButtonAction";
import FrameMenu from "Assets/Images/frame-menu.svg";
import EyeOn from "Assets/Images/EyeOn.svg";
import MenuPen from "Assets/Images/menu-pen.svg";
import { Fragment } from "react";
import Image from "App/Components/UI/Image";

const nftFrameOptionMenu = [{
    label: 'View',
    action: 'view',
    src: EyeOn
}
]
const actionMenu = [
    ...nftFrameOptionMenu, {
    label: 'Edit',
    action: 'edit',
    src: MenuPen
}]
const FrameCard = (props) => {
    const actionHandler = (e) => {
        switch (e.action) {
            case "view":
                props.onClickHandler({ mode: true, action: e.action }, props.row)
                break;
            case "edit":
                props.onClickHandler({ mode: true, action: e.action }, props.row)
                break;
            default:
                break;
        }
    }

    const imageHandler = () => {
        props.onClickHandler({ mode: true, action: "view" }, props.row)
    }
    return (
        <Fragment>
            <div className="frame-card">
                <div className="frame-menu">
                    <ButtonAction position={{ left: 5, top: 0 }} className="frame" layout="grid" src={FrameMenu} menu={props?.nftFrame ? nftFrameOptionMenu : actionMenu} onAction={actionHandler} />
                </div>
                <div className="frame-card-pic " onClick={imageHandler}>
                    <Image src={props.src} style={{ 'minHeight': '148px' }} />
                </div>
            </div>
        </Fragment>
    )
}

export default FrameCard 
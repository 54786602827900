import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { extractFormFieldKeys, objectToFormdata } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import FrameImage from "Assets/Images/frame-upload.png";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

const AddCategory = ({ row, triggerClose }) => {
    const [formVaildState, setFormVaildState] = useState({});
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();

    const formGroup = useMemo(() => ({
        name: {
            value: undefined,
            isRequired: true
        },
        imageUrl: {
            value: undefined,
            isRequired: true
        },
        file: {
            value: undefined,
            isRequired: true
        }
    }), [])
    const [formValues, setFormvalues] = useState(getInitial(formGroup));

    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        triggerClose({ mode: false })
    }
    const saveHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid && formValues?.id) {
            delete formValues.imageUrl;
            const list = { ...formValues, id: formValues.id }
            const formData = objectToFormdata(list)

            dispatch('showSpinner');
            triggerAPI({
                url: 'information-desk/category/update', data: formData, method: 'put'
            }, apiResult);
        } else if (valid) {
            delete formValues.imageUrl;
            const formData = objectToFormdata(formValues)
            dispatch('showSpinner');
            triggerAPI({
                url: 'information-desk/category/add', data: formData, method: 'post'
            }, apiResult);
        }
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    const imageUpload = (e) => {
        uploadHandler(e.target.files[0])
    }

    const uploadHandler = (e) => {
        if (e) {
            const reader = new FileReader()
            reader?.addEventListener('load', (res) => {
                const file = res.target.result
                setFormvalues((prev) => {
                    return {
                        ...prev,
                        file: e,
                        imageUrl: file
                    }
                });
            })
            reader.readAsDataURL(e)
        }
    }

    const onClose = ({ mode = false }) => {
        triggerClose(mode)
    }

    const getInformationDeskResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = extractFormFieldKeys({ ...formGroup, id: '' }, res.data);
        setFormvalues(list)

    }, [dispatch, formGroup])

    const getInformationDesk = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `information-desk/category/get/${row?.id}`, method: 'get'
        }, getInformationDeskResult);
    }, [dispatch, getInformationDeskResult, row?.id, triggerAPI])

    const onErrorHandler = (e) => {
        e.target.src = FrameImage;
    }

    useEffect(() => {
        if (row?.id) {
            getInformationDesk()
        }
        else {
            setFormvalues(getInitial(formGroup))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row?.id])

    return (
        <Fragment>
            <div>
                <div className="w-full category-add-image">
                    <img src={formValues?.imageUrl ? formValues?.imageUrl : FrameImage} alt="images" onError={onErrorHandler} />
                    <Validators type="Required" value={formValues.imageUrl} message="Required field" controlName="imageUrl" onErrors={collectErrors} />

                </div>
                <div className="upload flex mt-5 ">
                    <div className="image-label">
                        <input className="cursor-pointer" type="file" onChange={imageUpload} />
                    </div>
                    {`${row?.id ? 'Replace Image' : 'Upload Thumbnail Image'}`}
                </div>
                <div className="svg">PNG, JPEG Only</div>
            </div>
            <div className="w-full pt-3">
                <InputBox maxLength={25} label="Category Name" validateOnBlur type="text" controlName="name" value={formValues?.name} onChange={inputOnChange} />
                <Validators type="Required" value={formValues.name} message="Required field" controlName="name" onErrors={collectErrors} />
            </div>
            <div className="flex gap-2 pt-8 justify-center items-center">
                <Button label="Cancel" className="transaction-cancel" onClick={onClose} />
                <Button label={`${row?.id ? 'Update' : 'Add'}`} className="transaction-ok" onClick={saveHandler} />

            </div>
        </Fragment>
    )
}

export default AddCategory
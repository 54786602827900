/* eslint-disable react-hooks/exhaustive-deps */
import "./TableSelectBox.scss";
import { useState, useEffect, useCallback } from "react";
const TableSelectBox = ({ 
    list = [], 
    controlName = '', 
    keyName, 
    required = true, 
    validateOnBlur, 
    onChange = () => {}, 
    currentId = '', 
    label = '', 
    placeHolder = '', 
    className = '', 
    row = '', 
    tabIndex = -1, 
    disabled = false 
}) => {
    const [selectedValue, setSelectedValue] = useState({ name: '', value: '' });
    const selectValues = (value) => {
        const found = list?.find(item => 
            (item?.id === +value || item?.name.toString() === value.toString())
        );
        setSelectedValue(found || { name: '', value: '' });
        let values = {};
        if (row) {
            values = {
                [controlName]: keyName ? found[keyName] : found?.id,
            };
            if (onChange) onChange(values, row);
        } else {
            values = {
                [controlName]: keyName ? found[keyName] : found?.id
            };
            if (onChange) onChange(values);
        }
    };
    useEffect(() => {
        if (currentId) {
            const found = list?.find(val => {
                let content = keyName ? val[keyName] : val?.id;
                content = typeof content === "string" ? content?.toLowerCase() : content;
                let check = typeof currentId === "string" ? currentId?.toLowerCase() : currentId;
                return content === check;
            });
            setSelectedValue(found || { name: '', value: '' });
        }
    }, [currentId, list, keyName]);
    const handleSelectChange = (e) => {
        selectValues(e.target.value);
    };
    return (
        <div className={`relative selectbox ${className}`} tabIndex={tabIndex}>
            <label className="form-label">
                {label} {validateOnBlur && required ? '*' : ''}
            </label>
            <select 
                className="selectbox-select form-box" 
                value={selectedValue?.id || ''} 
                onChange={handleSelectChange} 
                onBlur={validateOnBlur ? () => onChange({ [controlName]: '' }) : null}
                disabled={disabled}
            >
                <option value="" disabled>
                    {placeHolder || 'Select an option'}
                </option>
                              

                {list?.map(item => (
                    <option key={item.id} value={item.id} >
                        {item.name}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default TableSelectBox;
import { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import Button from "../Button";
import TemplatePopup from "../TemplatePopup";
import "./DatePickerFilter.scss"

const DatePickerFilter = (props) => {
    const applyHandler = () => {
        const [from, to] = selectedDateRange
        const startDate = new Date(`${from?.year}-${from.month?.number}-${from?.day} GMT`);
        const endDate = new Date(`${to?.year}-${to.month?.number}-${to?.day} GMT`);
        endDate.setUTCHours(23, 59, 59, 0)
        props.onFilterData({
            ...props.data,
            from: startDate.toISOString()
            , to: endDate.toISOString()
        })
        props.setDates(selectedDateRange)
    }

    const defaultFrom = {
        year: 0,
        month: 0,
        day: 0,
    };

    const defaultTo = {
        year: 0,
        month: 0,
        day: 0,
    };

    const defaultRange = {
        from: defaultFrom,
        to: defaultTo,
    };
    const [selectedDateRange, setSelectedDateRange] = useState(defaultRange);

    const onCloseHandler = (data) => {
        props.onClose(data)
        setSelectedDateRange([])
    }

    useEffect(() => {
        if (props.dates?.length) {
            setSelectedDateRange(props.dates)
        }
    }, [props.dates])
    return (
        <TemplatePopup show={props.show} cssClass={`date-desk ${props.className}`} onClose={props.onCloseHandler}>
            <div className="date-picker">
                <Calendar className="date"
                    value={selectedDateRange}
                    range
                    onChange={setSelectedDateRange}
                    numberOfMonths={2}
                />
                <div className="flex justify-center gap-4 pb-8 pt-2">
                    <Button label="Cancel" className="transaction-cancel" row={{ action: 'cancel' }} onClick={onCloseHandler} />
                    <Button label="Apply" disabled={selectedDateRange[0]?.year && selectedDateRange[1]?.year ? false : true} className="transaction-ok" onClick={applyHandler} />
                </div>
            </div>
        </TemplatePopup>
    )
}


export default DatePickerFilter
import { useStore } from "App/hooks-store/store";
import { useEffect, useState } from "react";
import "./AnalyticsDashboard.scss";
import userAnalytics from "Assets/Images/userAnalytics.svg";
import Graph from "./Components/Graph";
import AnalyticsHeader from "./Components/AnalyticsHeader";
import AnalyticsRevenue from "Assets/Images/AnalyticsRevenue.svg";
import AnalyticsSales from "Assets/Images/AnalyticsSales.svg";
import AnalyticsFrame from "Assets/Images/AnalyticsFrame.svg";
import DownloadImage from "Assets/Images/DownloadImage.svg";
import Calender from "Assets/Images/Calender.svg";
import FormatField from "App/Components/UI/FormatField";
import DatePickerFilter from "App/Components/UI/DatePickerFilter";
import { useCallback } from "react";
import useHttp from "App/hooks/use-http";
import storage from "App/helpers/storage";

const dateRange = [
  { id: "month", name: "Monthly" },
  { id: "year", name: "Yearly" },
  { id: "day", name: "Days" },
];

const AnalyticsDashboard = () => {
  const dispatch = useStore(false)[1];
  const [dates, setDates] = useState([]);
  const [list, setList] = useState();
  const [year, setYear] = useState();
  // eslint-disable-next-line no-unused-vars
  const [policyList, setPolicyList] = useState();
  const [userState, setUserState] = useState({ range: "", year: "" });
  const [policyState, setPolicyState] = useState({ range: "", year: "" });
  const [analyticsInfo, setAnalyticsInfo] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [salesList, setSalesList] = useState();
  // eslint-disable-next-line no-unused-vars
  const [visitorsList, setVisitorsList] = useState();
  const [salesState, setsalesState] = useState({ range: "", year: "" });
  const [visitorsState, setVisitorsState] = useState({ range: "", year: "" });

  var years = [];
  var yearsNew = [];
  const { triggerAPI } = useHttp();
  var oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [calender, setCalender] = useState({
    from: oneWeekAgo.toISOString(),
    to: new Date().toISOString(),
  });

  const [calenderToggle, setCalenderToggle] = useState(false);
  const onCalenderToggle = () => {
    setCalenderToggle(!calenderToggle);
  };
  const onCloseHandler = (e) => {
    setCalenderToggle(false);
  };
  const onCloseHandlerPop = (e) => {
    setCalenderToggle(false);
    setCalender({
      from: oneWeekAgo.toISOString(),
      to: new Date().toISOString(),
    });
    setDates([]);
  };
  const onApply = (date) => {
    const value = new Date(date.to);
    value?.setUTCHours(0, 0, 0, 0);
    setCalender({ from: date?.from, to: value?.toISOString() });
    onCloseHandler();
  };
  const getUserListResult = useCallback(
    (res) => {
      dispatch("hideSpinner");

      const { list } = res.data;
      setList(list);
    },
    [dispatch]
  );

  const getUserList = useCallback(() => {
    let filterType = userState.range?.user ?? "month";
    let startYear;
    const value = new Date(calender.to);
    value?.setUTCHours(23, 59, 59, 0);
    dispatch("showSpinner");
    startYear = userState.year?.userSelectedYear ?? new Date().getFullYear();
    const params = new URLSearchParams({
      filterType,
      startYear,
        from: calender.from,
      to: value?.toISOString(),
    });
    triggerAPI(
      {
        url: `admin/users/chart?${params}`,
        method: "get",
      },
      getUserListResult
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    getUserListResult,
    triggerAPI,
    userState.range?.user,
    userState.year?.userSelectedYear,
  ]);

  const getPolicyListResult = useCallback(
    (res) => {
      dispatch("hideSpinner");

      const { list } = res.data;
      setPolicyList(list);
    },
    [dispatch]
  );

  const onSelectedRange = (e) => {
    const type = Object.keys(e)[0];
    if (type === "user") {
      setUserState({ ...userState, range: e });
    } else if (type === "sales") {
      setsalesState({ ...salesState, range: e });
    } else if (type === "visitors") {
      setVisitorsState({ ...visitorsState, range: e });
    } else {
      setPolicyState({ ...policyState, range: e });
    }
  };
  const onSelectedYear = (e) => {
    const type = Object.keys(e)[0];
    if (type === "userSelectedYear") {
      setUserState({ ...userState, year: e });
    } else if (type === "salesSelectedYear") {
      setsalesState({ ...salesState, year: e });
    } else if (type === "visitorsSelectedYear") {
      setVisitorsState({ ...visitorsState, year: e });
    } else {
      setPolicyState({ ...policyState, year: e });
    }
  };
  const getSiteVisitorListResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const list = res.data?.data?.["newUsers"]?.res;
      setVisitorsList(list);
    },
    [dispatch]
  );

  const getSiteVisitorsList = useCallback(() => {
    var currentYear = new Date().getFullYear();

    var startYear = currentYear - 2;
    for (var i = startYear; i <= currentYear; i++) {
      yearsNew.push({ id: i, name: startYear++ });
    }
    dispatch("showSpinner");
    let metric = "newUsers";
    let filterType = visitorsState.range?.visitors ?? "month";
    let startDate =
      visitorsState.range?.visitors === "year"
        ? yearsNew[0]?.id + "-01-01"
        : visitorsState.year?.visitorsSelectedYear
        ? visitorsState.year?.visitorsSelectedYear + "-01-01"
        : new Date().getFullYear() + "-01-01";

    triggerAPI(
      {
        url: `admin/interaction/analytics`,
        data: {
          metric,
          filterType,
          startDate,
        },
        method: "post",
      },
      getSiteVisitorListResult
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visitorsState.range,
    visitorsState.year?.visitorsSelectedYear,
    dispatch,
    triggerAPI,
    getSiteVisitorListResult,
  ]);

  const getPolicyList = useCallback(() => {
    dispatch("showSpinner");
    let filterType = policyState.range?.policy ?? "month";
    let startYear =
      policyState.year?.policySelectedYear ?? new Date().getFullYear();
    const params = new URLSearchParams({
      filterType,
      startYear,
    });
    triggerAPI(
      {
        url: `admin/nft-policies/chart?${params}`,
        method: "get",
      },
      getPolicyListResult
    );
  }, [
    dispatch,
    getPolicyListResult,
    policyState.range?.policy,
    policyState.year?.policySelectedYear,
    triggerAPI,
  ]);

  useEffect(() => {
    dispatch("setPageTitle", "Dashboard");
  }, [dispatch]);

  const getYear = () => {
    var currentYear = new Date().getFullYear();

    var startYear = currentYear - 2;
    for (var i = startYear; i <= currentYear; i++) {
      years.push({ id: i, name: startYear++ });
    }
    setYear(years);
    return years;
  };

  useEffect(() => {
    getYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userState.range || userState.year) {
      getUserList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);
  useEffect(() => {
    if (visitorsState.range || visitorsState.year) {
      getSiteVisitorsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorsState]);

  useEffect(() => {
    if (policyState.range || policyState.year) {
      getPolicyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyState]);

  useEffect(() => {
    getAnalyticsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calender]);

  useEffect(() => {
    if (salesState.range || salesState.year) {
      getSalesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesState]);

  const getSalesListResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { list } = res.data;

      setSalesList(list);
    },
    [dispatch]
  );

  const getSalesList = useCallback(() => {
    let filterType = salesState.range?.sales ?? "month";
    let startYear;
    dispatch("showSpinner");

    startYear = salesState.year?.salesSelectedYear ?? new Date().getFullYear();
    const params = new URLSearchParams({
      filterType,
      startYear,
    });
    triggerAPI(
      {
        url: `admin/get/sales/analytics?${params}`,
        method: "get",
      },
      getSalesListResult
    );
  }, [
    dispatch,
    getSalesListResult,
    triggerAPI,
    salesState.range?.sales,
    salesState.year?.salesSelectedYear,
  ]);

  const onDownloadHandler = () => {
    const filterType = userState.range.user ?? "year";
    const startYear =
      userState.year?.userSelectedYear ?? new Date().getFullYear();
    const baseURL = process.env.REACT_APP_BASE_URL;
    const params = new URLSearchParams({
      filterType,
      startYear,
      token: storage().get("token"),
    });
    const apiUrl = baseURL + `/admin/users/chart/download?${params}`;
    window.open(apiUrl, "_blank");
  };
  // eslint-disable-next-line no-unused-vars
  const onSiteVisitorsDownloadHandler = () => {
    let metric = "newUsers";
    let filterType = visitorsState.range?.visitors ?? "year";
    let startDate = visitorsState.year?.visitorsSelectedYear
      ? visitorsState.year?.visitorsSelectedYear + "-01-01"
      : new Date().getFullYear() + "-01-01";

    const baseURL = process.env.REACT_APP_BASE_URL;
    const params = new URLSearchParams({
      filterType,
      startDate,
      metric,
      token: storage().get("token"),
    });
    const apiUrl = baseURL + `/admin/interaction/download?${params}`;
    window.open(apiUrl, "_blank");
  };

  // eslint-disable-next-line no-unused-vars
  const onSalesDownloadHandler = () => {
    const filterType = salesState.range.sales ?? "year";
    const startYear =
      salesState.year?.salesSelectedYear ?? new Date().getFullYear();
    const baseURL = process.env.REACT_APP_BASE_URL;
    const params = new URLSearchParams({
      filterType,
      startYear,
      token: storage().get("token"),
    });
    const apiUrl = baseURL + `/admin/sales/chart/download?${params}`;
    window.open(apiUrl, "_blank");
  };

  const getAnalyticsInfoResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setAnalyticsInfo(res.data);
    },
    [dispatch]
  );

  const getAnalyticsInfo = useCallback(() => {
    dispatch("showSpinner");
    const value = new Date(calender.to);
    value?.setUTCHours(23, 59, 59, 0);
    const params = new URLSearchParams({
      from: calender.from,
      to: value?.toISOString(),
    });
    triggerAPI(
      {
        url: `admin/get/overall/analytics?${params}`,
        method: "get",
      },
      getAnalyticsInfoResult
    );
  }, [dispatch, getAnalyticsInfoResult, calender, triggerAPI]);

  useEffect(() => {
    getUserList();
    getPolicyList();
    getSalesList();
    getSiteVisitorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex justify-end py-4">
        <div className="flex cursor-pointer" onClick={onCalenderToggle}>
          <img src={Calender} alt="calender" className="mr-2" />
          <div className="flex analytics-date">
            <FormatField type="date" value={calender?.from} />
            {`  -  `}
            <FormatField type="date" value={calender?.to} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-3 gap-5">
        <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={userAnalytics}
            label="Users"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.users?.count}
            totalCount={analyticsInfo?.users?.totalCount}
            percent={`${analyticsInfo?.users?.growthPercentage}%`}
          />
        </div>
        {/* <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={AnalyticsFrame}
            label="Total Frames"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.frames?.count}
            totalCount={analyticsInfo?.users?.totalCount}
            percent={`${analyticsInfo?.frames?.growthPercentage}%`}
          />
        </div> */}

        {/* <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={AnalyticsRevenue}
            symbol="₳"
            label="Total Revenue"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.revenue?.count}
            totalCount={analyticsInfo?.revenue?.totalAmount}
            percent={`${analyticsInfo?.revenue?.growthPercentage}%`}
          />
        </div> */}
        <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={AnalyticsFrame}
            label="Creators"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.creators?.count}
            totalCount={analyticsInfo?.creators?.totalCount}
            percent={`${analyticsInfo?.creators?.growthPercentage}%`}
          />
        </div>
        <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={userAnalytics}
            label="Collections"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.collection?.count}
            totalCount={analyticsInfo?.collection?.totalCount}
            percent={`${analyticsInfo?.collection?.growthPercentage}%`}
          />
        </div>

        <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={AnalyticsSales}
            label="Premium Curators"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.premiumCur8orSubscriptions?.count}
            totalCount={analyticsInfo?.premiumCur8orSubscriptions?.totalCount}
            percent={`${analyticsInfo?.premiumCur8orSubscriptions?.growthPercentage}%`}
          />
        </div>
        <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={AnalyticsRevenue}
            label="Curators"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.cur8orSubscriptions?.count}
            totalCount={analyticsInfo?.cur8orSubscriptions?.totalCount}
            percent={`${analyticsInfo?.cur8orSubscriptions?.growthPercentage}%`}
          />
        </div>
        {/* <div className="analytics p-6 flex justify-between">
          <AnalyticsHeader
            src={AnalyticsSales}
            label="Sales"
            labelTwo="In Date Range"
            days={analyticsInfo.days}
            count={analyticsInfo?.sales?.count}
            totalCount={analyticsInfo?.sales?.totalAmount}
            percent={`${analyticsInfo?.sales?.growthPercentage}%`}
          />
        </div> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 2xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 gap-5 py-5">
        <div className="analytics-graph">
          <Graph
            label="Users"
            selectedYear="userSelectedYear"
            chosenYear={
              userState?.year?.userSelectedYear ?? new Date().getFullYear()
            }
            controlName="user"
            onSelectedRange={onSelectedRange}
            onSelectedYear={onSelectedYear}
            year={year}
            list={dateRange}
            data={list}
            download={DownloadImage}
            showX="true"
            onDownloadHandler={onDownloadHandler}
          />
        </div>
        {/* <div className="analytics-graph">
          <Graph
            graphType={true}
            label="Site Visitors"
            chosenYear={
              visitorsState?.year?.visitorsSelectedYear ??
              new Date().getFullYear()
            }
            selectedYear="visitorsSelectedYear"
            controlName="visitors"
            onSelectedRange={onSelectedRange}
            onSelectedYear={onSelectedYear}
            year={year}
            list={dateRange}
            data={visitorsList}
            download={DownloadImage}
            showX="true"
            onDownloadHandler={onSiteVisitorsDownloadHandler}
          />
        </div> */}
        {/* <div className="analytics-graph">
          <Graph
            label="Policies"
            selectedYear="policySelectedYear"
            chosenYear={
              policyState?.year?.policySelectedYear ?? new Date().getFullYear()
            }
            controlName="policy"
            onSelectedRange={onSelectedRange}
            onSelectedYear={onSelectedYear}
            year={year}
            data={policyList}
            list={dateRange}
          />
        </div>
        <div className="analytics-graph">
          <Graph
            label="Sales"
            selectedYear="salesSelectedYear"
            controlName="sales"
            onSelectedRange={onSelectedRange}
            onSelectedYear={onSelectedYear}
            year={year}
            list={dateRange}
            data={salesList}
            download={DownloadImage}
            showX="true"
            onDownloadHandler={onSalesDownloadHandler}
            chosenYear={
              salesState?.year?.salesSelectedYear ?? new Date().getFullYear()
            }
          />
        </div> */}
      </div>

      {calenderToggle && (
        <DatePickerFilter
          className="analytics-template"
          show={{ mode: true }}
          onClose={onCloseHandlerPop}
          onCloseHandler={onCloseHandler}
          onFilterData={onApply}
          data={calender}
          setDates={setDates}
          dates={dates}
        />
      )}
    </div>
  );
};
export default AnalyticsDashboard;

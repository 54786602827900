import Button from "App/Components/UI/Button";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
import React, { useState } from "react";
import { useEffect, useCallback } from "react";
import FrameRND from "./FrameRND";
import "./FrameUploadPopup.scss"
import ConfirmPopup from "App/Components/UI/ConfirmPopup";

const FrameUploadPopup = React.memo(({ srcPort, srcLand, metaPortInfo, metaInfoNewLand, onClose, framePositionHandler, uploadPortHandler, uploadLandHandler, setToggleChange, toggleChange, metaLandInfo, formValues = {}, metaInfoNew, isEdit = false }) => {
    const tabs = ['Vertical', 'Horizontal']
    const [tabIndex, setTabIndex] = useState(0);
    const [showCancelPop, setShowCancelPop] = useState(false);

    const [framePortConfig, setFramePortConfig] = useState(null);
    const [frameLandConfig, setFrameLandConfig] = useState(null);
    const [updatedFramePortConfig, setUpdatedFramePortConfig] = useState(null);
    const [updatedFrameLandConfig, setupdatedFrameLandConfig] = useState(null);

    const onPortFramePosHandler = useCallback((e) => {
        if (e) {
            setUpdatedFramePortConfig(JSON.stringify(e))
        }
    }, [])
    const onLandFramePosHandler = useCallback((e) => {
        if (e) {
            setupdatedFrameLandConfig(JSON.stringify(e))
        }
    }, [])
    const onPortUploadHandler = useCallback((e) => {
        if (e) {
            uploadPortHandler(e?.file)
            setToggleChange(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onLandUploadHandler = useCallback((e) => {
        if (e) {
            uploadLandHandler(e?.file)
            setToggleChange(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const saveDetails = (e) => {
        framePositionHandler({ portrait: JSON.parse(updatedFramePortConfig), landscape: JSON.parse(updatedFrameLandConfig) })
        onClose(e)
    }
    const changeTab = (e) => {
        if (e === 1) {
            if (updatedFrameLandConfig) {
                setFrameLandConfig(updatedFrameLandConfig)
            }
        } else {
            if (updatedFramePortConfig) {
                setFramePortConfig(updatedFramePortConfig)
            }
        }
        setTabIndex(e)
    }
    useEffect(() => {
        if (metaPortInfo) {
            setFramePortConfig(JSON.stringify(metaPortInfo))
        }
    }, [metaPortInfo, onPortFramePosHandler])
    useEffect(() => {
        if (metaLandInfo) {
            setFrameLandConfig(JSON.stringify(metaLandInfo))
        }
    }, [metaLandInfo, onLandFramePosHandler])
    const toggleHandler = (e, checked) => {
        setToggleChange(checked)
    }
    // const toggleHandlerRotate = (e, checked) => {
    //     setToggleChangeRotate(checked)
    // }
    const onClosed = (e) => {
        onClose(e)
    }

    useEffect(() => {
        if (metaInfoNew) {
            setFramePortConfig(JSON.stringify(metaInfoNew))
            setUpdatedFramePortConfig(JSON.stringify(metaInfoNew))
        }
    }, [metaInfoNew])

    useEffect(() => {
        if (metaInfoNewLand) {
            setFrameLandConfig(JSON.stringify(metaInfoNewLand))
            setupdatedFrameLandConfig(JSON.stringify(metaInfoNewLand))
        }
    }, [metaInfoNewLand])
    const actionHandler = (e) => {
        if(e) {
            onClosed();
        } else {
            handleCancelPop();
        }
    }
    const handleCancelPop = () => {
        setShowCancelPop(prev => !prev)
    }
    return (
        <div className={`min-h-[10rem] popup-frame-container ${tabIndex === 0 ? 'portrait' : 'landscape'}`}>
            <div className="frame-tabs tab-pages flex justify-between">
                <ul>
                    {
                        tabs?.map((tab, i) =>
                            <li key={tab} data-tab={tab} onClick={() => changeTab(i)} className={`${tabIndex === i ? 'tab-page-active' : ''} `}>
                                <div >
                                    {tab}
                                </div>
                            </li>
                        )
                    }
                </ul>
                <div className="top flex gap-[11px] items-center">
                    Show Image on Top / Draggable
                    <ToggleSwitch className="gallery-switch" check={toggleChange} row={formValues} onChange={toggleHandler} />
                </div>
            </div>
            {tabIndex === 0 && <FrameRND isEdit={isEdit} tabIndex={tabIndex} toggleChange={toggleChange} metaInfoNew={updatedFramePortConfig} formValues={formValues} onFramePositionChange={onPortFramePosHandler} onFileUpload={onPortUploadHandler} src={srcPort} metaInfo={framePortConfig} />}
            {tabIndex === 1 && <FrameRND isEdit={isEdit} tabIndex={tabIndex} toggleChange={toggleChange} metaInfoNew={updatedFrameLandConfig} formValues={formValues} onFramePositionChange={onLandFramePosHandler} onFileUpload={onLandUploadHandler} src={srcLand} metaInfo={frameLandConfig} />}
            <div className="brdr-tp"></div>
            <div className="flex justify-end gap-4 pt-4">
                <Button label="Cancel" className="transaction-cancel" row={{ action: 'cancel' }} onClick={handleCancelPop} />
                <Button label="Done" className={`${(tabIndex === 0 && srcPort) || (tabIndex === 1 && srcLand) ? 'transaction-ok' : 'cancel-disable'}`} onClick={saveDetails} />
            </div>
            <div>

            </div>
            <ConfirmPopup title={'Cancel Frame Creation'} show={{mode: showCancelPop}} cssClass='cancelFramPop'
            buttonCancel='No' buttonYes='Yes' onClose={actionHandler}>
                Changes have not been saved yet. Are you sure want to cancel this frame creation?
            </ConfirmPopup>
        </div>
    )
})
export default FrameUploadPopup;
import Spinner from "App/Components/UI/Spinner"
import RoutesMain from "App/RoutesMain"
import React, { Fragment } from "react"
import { useStore } from "App/hooks-store/store"
import Toaster from "App/Components/UI/Toaster"



const Main = React.memo(() => {
    const store = useStore()[0];
    const { spinnerMode, toast } = store;
    return (
        <Fragment>
            <RoutesMain />
            <Spinner show={!!spinnerMode} />
            <Toaster {...toast} />
        </Fragment>
    )
})
export default Main
import { useRef, useState } from "react";

const InputBoxLogin = (props) => {
    const inputRef = useRef()
    const [eyeStatus, setEyeStatus] = useState('on')
    const { label, type, config } = props;
    const { hasEyeIcon, hasUserIcon } = config;
    const onEycClickHandler = () => {
        const isPassword = inputRef.current.type === 'password'
        inputRef.current.type = isPassword ? 'text' : 'password';
        const statusClass = isPassword ? 'off' : 'on'
        setEyeStatus(statusClass);
    }
    let addOne = null;
    const onChangeHandler = (e) => {
        const label = props.controlName ?? 'value'
        const value = {
            [label]: e.target.value
        }
        if (props.onChange) {
            props.onChange(value)
        }
    }
    const onBlurHandler = (e) => {
        if (props.validateOnBlur) {
            e.target.value = e.target.value ?? '';
            onChangeHandler(e);
        }
    }
    if (hasEyeIcon) {
        addOne = <button type="button" className={`btn btn-eye btn-eye-${eyeStatus}`} onClick={onEycClickHandler}></button>
    }
    if (hasUserIcon) {
        addOne = <span className="icon-person"></span>
    }
    return (
        <div className="input-login">
            <div className="input-login-group">
                <label>{label}</label>
                <input type={type} ref={inputRef} onChange={onChangeHandler} onBlur={onBlurHandler} value={props.value} />
            </div>
            <div className="input-login-addon flex items-center">
                {addOne}
            </div>
        </div>
    )
}
export default InputBoxLogin;
import Button from "App/Components/UI/Button";
import SearchFilter from "App/Components/UI/SearchFilter";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
import { useStore } from "App/hooks-store/store";
import { Fragment, useCallback, useEffect, useState } from "react"
import "../InformationDesk.scss"
import ActionMenu from "Assets/Images/frame-menu.svg";
import useHttp from "App/hooks/use-http";
import MenuDel from "Assets/Images/menu-del.svg";
import MenuPen from "Assets/Images/menu-pen.svg";
import { useNavigate } from "react-router-dom";
import AddCategory from "../Components/CategoryDetails/AddCategory";
import Popup from "App/Components/UI/Popup";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import BackgroundImage from "App/Components/UI/BackgroundImage";

const actionMenu = [{
    label: 'Edit',
    action: 'edit',
    src: MenuPen
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
},
]
const InformationCategory = (props) => {
    const [dateMenu, setDateMenu] = useState([{
        label: 'Date Range',
        action: 'dateRange',
        selected: false,
        popup: true
    }
    ])
    const [list, setList] = useState();
    const [addCategory, setAddCategory] = useState({ mode: false });
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const navigate = useNavigate();
    const [editData, setEdit] = useState();
    const [ShowRemovePopup, setShowRemovePopup] = useState({ mode: false });
    const [search, setSearch] = useState('')
    const [selectedDate, setSelectedDate] = useState('')

    const actionHandler = (e) => {
        setEdit(e.row)
        if (e.action === 'edit') {
            setAddCategory({ mode: true })
        }
        else {
            setShowRemovePopup({ mode: true })

        }

    }

    const categoryDetailsHandler = (e) => {
        const id = e.currentTarget?.dataset?.infoid
        navigate(`${id}`)
    }
    const onCloseRemovePopup = (data) => {
        if (data) {
            deleteHandler()
        }
        else {
            setShowRemovePopup({ mode: false })
        }

    }
    const onFilterData = (e) => {
        setSelectedDate(e)
    }
    const searchHandler = (e) => {
        setSearch(e)
    }
    const getInformationDeskResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data;
        setList(list)
    }, [dispatch])

    const getInformationDesk = useCallback(() => {
        dispatch('showSpinner');
        let filter;
        if (selectedDate.from) {
            filter = {
                from: selectedDate.from,
                to: selectedDate.to
            }
        }
        else {
            filter = ''
        }

        const filterStringify = JSON.stringify(filter)
        triggerAPI({
            url: `information-desk/category/get?&filter=${filterStringify}&search=${search}`, method: 'get'
        }, getInformationDeskResult);
    }, [dispatch, getInformationDeskResult, search, selectedDate, triggerAPI])

    const apiResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner');
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        setShowRemovePopup({ mode: false })
        getInformationDesk()
    }, [dispatch, getInformationDesk])

    const deleteHandler = () => {
        dispatch('showSpinner');
        const data = {
            id: editData?.id,
        }
        triggerAPI({
            url: `information-desk/category/delete`, data, method: 'delete'
        }, apiResult);
    }

    const addCategoryHandler = () => {
        setAddCategory({ mode: true })
        setEdit({})
    }

    const toggleSwitch = (id, checked) => {
        getshowOnInfoDesk(id, checked);
    }

    const getshowOnInfoDeskResult = (res) => {
        getInformationDesk()
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const getshowOnInfoDesk = (id, checked) => {
        dispatch('showSpinner');
        const data = {
            id: id,
            showOnInfoDesk: checked
        }
        triggerAPI({
            url: `information-desk/category/status/update`, data, method: 'patch'
        }, getshowOnInfoDeskResult);
    }

    const onClosecategoryDetails = (data) => {
        setAddCategory({ mode: false })
        if (data) {
            getInformationDesk()
        }
    }

    useEffect(() => {
        getInformationDesk()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, selectedDate])
    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setDateMenu} actionMenu={dateMenu} onFilterData={onFilterData} />
                </div>
                <div>
                    <Button label="Add Topic" onClick={addCategoryHandler} />
                </div>
            </div>
            <div className="pb-7 pt-5 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-4 gap-4">
                {list?.map((info) =>

                    <div className="flex flex-col info" key={info.id}>
                        <Fragment>
                            <div className="flex justify-center items-center cursor-pointer"
                                onClick={categoryDetailsHandler} data-infoid={info?.id}>
                                <BackgroundImage row={info} newStyle={{ backgroundImage: `url(${info?.imageUrl})` }} src={info?.imageUrl} alt="images" icon={ActionMenu} menu={actionMenu} style={{ 'minHeight': '10.42rem' }} className="info-card background" onAction={actionHandler} />
                            </div>
                            <div className="p-3 flex flex-col">
                                <span className="info-title">{info?.name}</span>
                                <span className="pt-1 info-content">Slides: {info?.contentCount}</span>
                                <div className="flex justify-between pt-2">
                                    <div className="info-desk">Active</div>
                                    <ToggleSwitch className="gallery-switch" check={info?.showOnInfoDesk} row={info} onChange={toggleSwitch} />
                                </div>
                            </div>
                        </Fragment>
                    </div>
                )}
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>
                }
                <Popup show={addCategory} title={`${editData?.id ? 'Edit Category' : 'Add Category'}`} cssClass="free-frames" style={{ 'maxWidth': '38.85rem' }}
                    onClose={onClosecategoryDetails}>
                    <AddCategory row={editData} triggerClose={onClosecategoryDetails} />
                </Popup>

                <ConfirmPopup show={ShowRemovePopup} content="Are you sure want to delete this category?" buttonYes="Delete" buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title="Delete Category" cssClass="remove-category" onClose={onCloseRemovePopup}>
                    <div className="flex flex-col">
                        <span className="info-title">{editData?.name}</span>
                        <span className="pt-1 info-content">Content: {editData?.contentCount}</span>
                        <div className="category-image pt-4">
                            <img src={editData?.imageUrl} alt="images" /></div>
                    </div>
                </ConfirmPopup>
            </div>

        </div>
    )
}

export default InformationCategory
import Button from "App/Components/UI/Button"
import ButtonAction from "App/Components/UI/ButtonAction"
import Popup from "App/Components/UI/Popup";
import Status from "App/Components/UI/Status";
import Table from "App/Components/UI/Table"
import TableHeader from "App/Components/UI/Table/Components/TableHeader"
import ActionButton from "Assets/Images/ActionButton.svg";
import { useState } from "react";
import ViewSecurityPopup from "../ViewSecurityPopup";
import MenuDel from "Assets/Images/menu-del.svg";
import MenuGallery from "Assets/Images/menu-gallery.svg";
import Tick from "Assets/Images/tick.png";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import { useCallback } from "react";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import Pagination from "App/Components/UI/Pagination";
import { useEffect } from "react";
import AddSecurityPopup from "../AddSecurityPopup";
import { LIST_LIMIT } from "App/constants";

const menuAction = [{
    label: 'View',
    action: 'view',
    src: MenuGallery
}, {
    label: 'Set as default',
    action: 'default',
    src: Tick
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]
const actionMenu = [{
    label: 'View',
    action: 'view',
    src: MenuGallery
},
{
    label: 'Delete',
    action: 'delete',
    src: MenuDel
}]
const paginationConfig = {
    perPage: LIST_LIMIT
}
const TableView = ({ type = 1, label = '', deleteWarning = '' }) => {
    const [showViewPopup, setShowViewPopup] = useState({ mode: false })
    const [showDeletePopup, setShowDeletePopup] = useState({ mode: false })
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [rowData, setRowData] = useState({})
    const [list, setList] = useState([])
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [showAddPopup, setShowAddPopup] = useState({ mode: false })

    const onAddTerms = () => {
        setShowAddPopup({ mode: true })
    }
    const onCloseAddpopup = (e) => {
        if (e?.status) {
            getTermsList()
        }
        setShowAddPopup({ mode: false })

    }
    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const actionHandler = (e) => {
        setRowData(e?.row)
        if (e?.action === 'view') {
            setShowViewPopup({ mode: true })
        }
        else if (e?.action === 'delete') {
            setShowDeletePopup({ mode: true })
        }
        else if (e?.action === 'default') {
            onSetActiveHandler(e?.row?.id)
        }
    }
    const onCloseViewPopup = (e) => {
        setShowViewPopup({ mode: false })

    }
    const onCloseDeleteUser = (e) => {
        setShowDeletePopup({ mode: false })
        if (e) {
            onDeleteHandler()
        }

    }

    const apiResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        getTermsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const onDeleteHandler = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `terms-conditions/delete/${rowData?.id}`, method: 'delete'
        }, apiResult);
    }, [dispatch, triggerAPI, rowData?.id, apiResult])

    const activeResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        getTermsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const onSetActiveHandler = useCallback((id) => {
        dispatch('showSpinner');
        const data = {
            id: id,
            type: type
        }
        triggerAPI({
            url: `terms-conditions/set-active`, data: data, method: 'put'
        }, activeResult);
    }, [dispatch, type, triggerAPI, activeResult])

    const getTermsListResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { data, pageMeta } = res.data
        setList(data)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getTermsList = useCallback(() => {
        dispatch('showSpinner');
        const filter = {
            "type": type
        }

        triggerAPI({
            url: `terms-conditions/get-all`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: "", filter: filter


            }, method: 'post'
        }, getTermsListResult);
    }, [dispatch, getTermsListResult, page, triggerAPI, type])

    useEffect(() => {
        getTermsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        < div >
            <div className="flex justify-end items-center mb-4">
                <div>
                    <Button label={label} onClick={onAddTerms} />
                </div>
            </div>
            <div className="mb-40">
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th>
                                <TableHeader label="Title" />
                            </th>
                            <th>
                                <TableHeader label="Status" />
                            </th>
                            <th className="flex justify-end items-center">
                                <TableHeader label="Actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((list) => <tr key={list.id}>
                            <td className="security-name">
                                {list?.title}
                            </td>

                            <td className="security-action">
                                <Status label={list?.isActive ? 'Is Active' : 'Inactive'} />
                            </td>
                            <td>
                                <div className="flex justify-end items-center">
                                    <ButtonAction src={ActionButton} menu={list?.isActive ? actionMenu : menuAction} row={list} onAction={actionHandler} />
                                </div>

                            </td>
                        </tr>)}
                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list?.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>
                <Popup show={showViewPopup}
                    style={{ 'maxWidth': '50rem' }}
                    title={rowData?.title} cssClass="user-create-pop security-popup" onClose={onCloseViewPopup}>
                    <ViewSecurityPopup rowData={rowData} />
                </Popup>

                <Popup show={showAddPopup}
                    style={{ 'maxWidth': '30rem' }}
                    title={label} cssClass="user-create-pop security-add-popup" onClose={onCloseAddpopup}>
                    <AddSecurityPopup triggerClose={onCloseAddpopup} type={type} />
                </Popup>

                <ConfirmPopup show={showDeletePopup} content={`Are you sure want to delete this ${deleteWarning}?`} buttonYes="Delete" buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title={deleteWarning} cssClass="delete-user" onClose={onCloseDeleteUser}>
                </ConfirmPopup>
            </div>
        </div >
    )
}


export default TableView

import Button from "App/Components/UI/Button"
const DuplicatePopUp = ({onClose, onCreateDuplicate, data}) => {
    const handleCreate = () => {
        onCreateDuplicate();
    }
    return (
        <div className="">
            <div className="duplicate-container">
                <div className="duplicate-head-text">
                    <p>Are you sure want to duplicate this project?</p>
                </div>
                <div className="duplicate-item mt-[1.5rem] p-[1.5rem]">
                    <div className="duplicate-content">
                        <p className="label">Project Name</p>
                        <p className="value">{data?.projectname}</p>
                    </div>
                    <div className="duplicate-content mt-[1.5rem]">
                        <p className="label">NFT Supply </p>
                        <p className="value">{data?.maxNftSupply}</p>
                    </div>
                </div>
            </div>
            <div className="flex gap-[1rem] justify-center mt-[2rem]">
                <Button label="No" onClick={onClose} className='transaction-cancel' />
                <Button className='transaction-ok' label={`Yes`} onClick={handleCreate} />
            </div>
        </div>
    )
}

export default DuplicatePopUp;
const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setProfile: (current, profile) => {
            return {
                profile
            }
        }
    }
    initStore(actions, {
        profile: ''
    });
}
export default configureStore;
import Button from "App/Components/UI/Button";
import Validators from "App/Components/UI/Forms/Validators";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import React, { useCallback, useState, useRef } from "react";
import "./Upload.scss";
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
} from "App/helpers/file";
import FileImg from "Assets/Images/file.svg";
import userAvatarHover from "Assets/Icons/avatar_hover.svg";
import { objectToFormdata } from "App/helpers/utilities";

const Upload = React.memo((props) => {
  const [formVaildState, setFormVaildState] = useState({});
  const dispatch = useStore(false)[1];
  const fileRef = useRef(null);
  const dragFileRef = useRef(null);
  const formGroup = {
    document: {
      value: undefined,
      isRequired: true,
    },
  };
  const [formValues, setFormvalues] = useState(getInitial(formGroup));

  const onClose = ({ mode = false }) => {
    props.triggerClose(mode);
  };

  const submitHandler = () => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    if (valid) {
      dispatch("showSpinner");
      const formData = objectToFormdata(formValues);
      props.submit(formData);
    } else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
  };
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };
  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const fileHandle = (data) => {
    if (data) {
      const file = data?.document;
      const extension = file?.name.split(".").pop();
      const fileExtension = extension.toLowerCase();
      if (fileExtension === "csv") {
        inputOnChange({ document: file });
      } else {
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: "File format should be csv",
          },
        });
      }
    }
  };

  return (
    <div className="file-upload-wrapper">
      <div
        className="upload-file-section"
        ref={dragFileRef}
        id="file-upload"
        onDrop={(e) =>
          handleDrop(e, dragFileRef, fileHandle, "document", "file-upload")
        }
        onDragOver={handleDragOver}
        onDragEnter={(e) => handleDragEnter(e, dragFileRef, "file-upload")}
        onDragLeave={(e) => handleDragLeave(e, dragFileRef, "file-upload")}
      >
        <div
          className={"relative flex h-full flex-col justify-center"}
        >
          <label className="cursor-pointer" htmlFor="upload-button">
            <img
              alt="No Img"
              src={userAvatarHover}
              className={`upload-icon`}
            ></img>
            <img alt="No Img" src={FileImg} className={`file-uploaded`} />

            <p className={`file-desc ${formValues?.document ? "active" : ""}`}>
              {formValues?.document
                ? formValues?.document?.name?.length < 25
                  ? formValues?.document?.name
                  : formValues?.document?.name?.slice(0, 10) +
                    "..." +
                    formValues?.document?.name?.slice(-10)
                : "Click here or drag and drop your .csv file to upload"}
            </p>
          </label>
          <input
            type="file"
            ref={fileRef}
            id="upload-button"
            name={"document"}
            className={"hidden"}
            onChange={(e) => fileHandle({ document: e.target.files[0] })}
          />
        </div>
      </div>
      <Validators
        type="Required"
        value={formValues.document}
        message="Required field"
        controlName="document"
        onErrors={collectErrors}
      />
      <div className="flex transaction-button justify-center pt-8">
        <Button
          label="Cancel"
          onClick={onClose}
          className="transaction-cancel"
        />
        <Button
          className="transaction-ok"
          label={`Upload`}
          onClick={submitHandler}
        />
      </div>
    </div>
  );
});
export default Upload;

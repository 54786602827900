/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from "App/Components/UI/TabWithoutLink";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useMemo, useState, useEffect } from "react";
import ToggleAccordion from "./Components/ToggleAccordion";

const RoleAndPermissions = () => {
  const dispatch = useStore(false)[1];
  const [tabs, setTabs] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectedSub, setSelectedSub] = useState("");
  const { triggerAPI } = useHttp();
  useEffect(() => {
    dispatch("setPageTitle", "Choose Role & Edit Permissions");
  }, [dispatch]);
  const getRoleResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      let role = res?.data?.map((val) => {
        return { ...val, label: val?.role };
      });
      setTabs(role);
    },
    [dispatch]
  );

  const getRoleList = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `role`,
        method: "get",
      },
      getRoleResponse
    );
  }, [dispatch, getRoleResponse, triggerAPI]);
  useEffect(() => {
    getRoleList();
  }, []);

  const updateToggleResponse = useCallback(
    (res) => {
      const { message } = res;
      dispatch("hideSpinner");
      dispatch("showToast", { toast: { toastMode: "success", message } });
      getRoleList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const updateToggle = useCallback(
    (id,checked) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: `permission/${id}/${checked?"enable":"disable"}`,
          data:{},
          method: "patch",
        },
        updateToggleResponse,     (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: err?.response?.data?.error?.message||"Permission updation failed",
            },
          });
        }
      );
    },
    [dispatch, updateToggleResponse, triggerAPI]
  );
  const onToggleChange = (id, checked) => {
    updateToggle(id,checked);
  };
  const subTabs = useMemo(() => {
    return tabs
      ?.filter((val) => val?.label === selected)[0]
      ?.permissions?.map((e) => {
        return { ...e, label: e?.permissions?.permission };
      });
  }, [selected,tabs]);
  return (
    <div>
      {!!tabs?.length&&<Tabs tabs={tabs} setSelected={setSelected} selected={selected} />}
      <div className="py-4">
        {subTabs?.length ? (
          <ToggleAccordion
            onToggle={onToggleChange}
            list={subTabs}
            selected={selectedSub}
            setSelected={setSelectedSub}
          />
        ) : (
          <div className="flex justify-center p-20 nodata">
            <p>No Permissions Found</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default RoleAndPermissions;

import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import SelectBox from "App/Components/UI/SelectBox";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { extractFormFieldKeys } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import "./CreateSubAdmin.scss";

const CreateSubAdmin = React.memo((props) => {
  const [formVaildState, setFormVaildState] = useState({});
  const [formValues, setFormvalues] = useState({});
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const formGroup = useMemo(() => {
    let list= {
      name: {
        value: undefined,
        isRequired: true,
      },
      email: {
        value: undefined,
        isRequired: true,
      },
      password: {
        value: undefined,
        isRequired: !props?.row?.id,
      },
      role: {
        value: undefined,
        isRequired: true,
      },
    };
    setFormvalues(getInitial(list));
    return list
  }, [props?.row]);

  const onClose = ({ mode = false }) => {
    props.triggerClose(mode);
  };

  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    onClose({ mode: true });
  };
  const createSubAdminHandler = () => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    if (valid) {
      if (props.row?.id) {
        dispatch("showSpinner");
        let data ={name:formValues?.name,roleId:formValues?.role}
        triggerAPI(
          {
            url: `admin/sub-admin/${props?.row?.id}/update`,
            data,
            method: "patch",
          },
          apiResult,
          (err) => {
            dispatch("hideSpinner");
            dispatch("showToast", {
              toast: {
                toastMode: "error",
                message:
                  err?.response?.data?.error?.message ||
                  "Sub admin updation failed",
              },
            });
          }
        );
      } else {
        dispatch("showSpinner");
        triggerAPI(
          {
            url: "admin/sub-admin/create",
            data: formValues,
            method: "post",
          },
          apiResult,
          (err) => {
            dispatch("hideSpinner");
            dispatch("showToast", {
              toast: {
                toastMode: "error",
                message:
                  err?.response?.data?.error?.message ||
                  "Sub admin creation failed",
              },
            });
          }
        );
      }
    } else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
  };
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };
  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);

  useEffect(() => {
    if (props.row?.id) {
      const result = extractFormFieldKeys(
        { ...formGroup, id: "" },
        { ...props.row, role: props.row?.roleId }
      );
      setFormvalues(result);
    } else {
      setFormvalues(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row]);

  return (
    <div className="sub-admin-create">
      <div className="w-full">
        <div className="flex gap-2 pt-1 column">
          <div className="sub-admin-form">
            <InputBox
              label="Name"
              validateOnBlur
              type="text"
              autoComplete="new-password"
              controlName="name"
              onChange={inputOnChange}
              value={formValues.name}
            />
            <Validators
              type="Required"
              value={formValues.name}
              message="Required field"
              controlName="name"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <SelectBox
              validateOnBlur
              list={props?.list}
              placeHolder="Select"
              label={`Role`}
              className="frame-form"
              controlName={"role"}
              onChange={inputOnChange}
              currentId={formValues?.role}
              tabIndex={0}
            />
            <Validators
              type="Required"
              value={formValues.role}
              message="Required field"
              controlName="role"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              label="Email Address"
              validateOnBlur
              autoComplete="new-password"
              type="text"
              value={formValues.email}
              controlName="email"
              onChange={inputOnChange}
              disabled={`${props.row?.id ? "true" : ""}`}
            />
            <Validators
              type="Required"
              value={formValues.email}
              message="Required field"
              controlName="email"
              onErrors={collectErrors}
            />
            <Validators
              type="Email"
              value={formValues.email}
              message="Invalid Email"
              controlName="email"
              onErrors={collectErrors}
            />
          </div>
          {!props?.row?.id && (
            <div className="sub-admin-form">
              <InputBox
                label="Password"
                validateOnBlur
                autoComplete="new-password"
                type="password"
                value={formValues.password}
                controlName="password"
                onChange={inputOnChange}
                config={{ hasEyeIcon: true }}
                disabled={`${props.row?.id ? "true" : ""}`}
              />
              <Validators
                type="Required"
                value={formValues.password}
                message="Required field"
                controlName="password"
                onErrors={collectErrors}
              />
              <Validators
                type="Password"
                value={formValues.password}
                message="Invalid Password"
                controlName="password"
                onErrors={collectErrors}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex transaction-button justify-center pt-12">
        <Button
          label="Cancel"
          onClick={onClose}
          className="transaction-cancel"
        />
        <Button
          className="transaction-ok"
          label={`${props?.row?.id ? "Update" : "Create"}`}
          onClick={createSubAdminHandler}
        />
      </div>
    </div>
  );
});
export default CreateSubAdmin;

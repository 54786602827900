import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import Button from "App/Components/UI/Button"
import { objectToFormdata } from "App/helpers/utilities"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import AddFrameForm from "../../../Components/AddFrameForm"

const breadCrumbs = [
    {
        link: '/dashboard/frames/free-frames',
        label: 'Frame Management'
    },
    {
        link: null,
        label: 'Edit Basic Frame'
    }
]

const FreeFramesEdit = () => {
    const [list, setList] = useState();
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [isValid, setIsValid] = useState(false)
    const [touched, setTouched] = useState(false)
    const navigate = useNavigate()
    const onChange = (formValues, valid) => {
        setList(formValues)
        setIsValid(valid)
    }
    const goToFreeFrames = useCallback(() => {
        navigate("/dashboard/frames/free-frames")
    }, [navigate])

    const apiResult = (res) => {
        const { message} = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        goToFreeFrames();
    }
 
    const updateHandler = (data) => {
        if (isValid) {
            const list = { ...data }
            list.name = list.displayname;
            delete list.displayname;
            delete list.uid;
            delete list.price;
            delete list.totalEdition;
            delete list.s3ImageUrl;
            delete list.s3ImageUrlLandscape;
            if(!list.portraitFile)
                delete list.portraitFile;
            if(!list.landscapeFile)
                delete list.landscapeFile;
            const formData = objectToFormdata(list)
            dispatch('showSpinner');
            triggerAPI({
                url: 'frame-asset/free/update', data: formData, method: 'patch'
            }, apiResult);
        }

        else {
            setTouched(true)
        }
    }

    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={breadCrumbs} title="Edit Free Frame" className="frame" />
                </div>
                <div className="flex gap-3">
                    <Button label="Update" row={list} onClick={updateHandler} />
                </div>
            </div>
            <div className="pt-6">
                <AddFrameForm free onChange={onChange} touched={touched} />
            </div>
        </div>
    )
}
export default FreeFramesEdit
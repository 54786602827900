import Button from "App/Components/UI/Button"
import InputBox from "App/Components/UI/Forms/InputBox"
import Validators from "App/Components/UI/Forms/Validators"
import PdfUploader from "App/Components/UI/PdfUploader"
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { useCallback } from "react"
import { useState } from "react"
import { objectToFormdata } from "App/helpers/utilities";

const AddSecurityPopup = ({ triggerClose, type, list = [], onCallApi }) => {
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        title: {
            value: undefined,
            isRequired: true
        },
        type: {
            value: type,
            isRequired: true
        },
        file: {
            value: undefined,
            isRequired: false
        },
        link: {
            value: undefined,
            isRequired: false
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const uploadHandler = (e) => {
        const reader = new FileReader()
        reader.addEventListener('load', (res) => {
            setFormvalues((prev) => {
                return {
                    ...prev,
                    file: e
                }
            });
        })
        reader.readAsDataURL(e)
    }
    const onClose = ({ mode = false }) => {
        triggerClose({ mode: false, status: false })

    }
    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        triggerClose({ mode: false, status: true });
    }
    const onSaveHandler = () => {
        if (!formValues?.file) {
            delete formValues?.file
        }
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });

        if (((formValues?.file || formValues?.link) && formValues?.title) || valid) {
            delete formValues?.pageMeta
            delete formValues?.data
            const formData = objectToFormdata(formValues)
            dispatch('showSpinner');
            triggerAPI({
                url: `terms-conditions/add`, data: formData, method: 'post'
            }, apiResult);
        }
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    return (
        <div>
            <div className="w-full">
                <div className="flex-col gap-4 pt-2 justify-between">
                    <div className="user-form">
                        <InputBox label="Title" validateOnBlur type="text" controlName="title" onChange={inputOnChange} value={formValues.title} />
                        <Validators type="Required" value={formValues.title} message="Required field" controlName="title" onErrors={collectErrors} />
                    </div>
                    <div className="user-form ">
                        {!formValues?.file && <InputBox label="Link" validateOnBlur type="text" controlName="link" onChange={inputOnChange} value={formValues.link} />
                        }
                        {formValues?.link && <iframe className="iframe-cls mt-2" src={formValues?.link} title="page" width="100%" height="16rem"></iframe>
                        }
                        <Validators type="RequiredAny" matchValue={formValues?.file} value={formValues?.link} message="Required field" controlName="link" onErrors={collectErrors} />
                    </div>
                    {!formValues?.link && <div className="user-form">
                        <PdfUploader onChange={uploadHandler} upload="upload" className="profile-edit-image" />
                    </div>}
                </div>
            </div>

            <div className="flex transaction-button justify-center pt-12">
                <Button label="Cancel" onClick={onClose} row={{ status: false }} className='transaction-cancel' />
                <Button className='transaction-ok' label='Save' row={{ status: true }} onClick={onSaveHandler} />
            </div>
        </div>
    )
}

export default AddSecurityPopup
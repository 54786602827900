import {  useEffect, useMemo, useState } from "react"
import Button from "../Button"
import MultiSelectFilter from "../MultiSelectFilter"
import TemplatePopup from "../TemplatePopup"

const WallType = (props) => {
    const list = useMemo(() => (["basic","premium"]), [])
    const [wallType, setWallType] = useState([])


    const handleChange = (e) => {
        if (e.target.checked) {
            setWallType([...wallType, e.target.name])
        }
        else {
            setWallType(wallType.filter(val => val !== e.target.name))
        }
    }

    const applyHandler = () => {
        props.onFilterData({
            ...props.data,
            types: wallType
        })
        props.setWallType(wallType)
    }

    const onCloseHandler = (data) => {
        props.onClose(data)
        setWallType([])
    }

    useEffect(() => {
        setWallType(props.wallType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <TemplatePopup show={props.show} cssClass="date-desk" onClose={props.onCloseHandler}>
            <div className="date-picker p-9 flex flex-col">
                <div className="status-filter-head pb-4 ">Select wall Type</div>
                <div>
                    <MultiSelectFilter list={list} checked={wallType} onChange={handleChange} />
                </div>
                <div className="flex justify-center gap-4 pb-5 pt-9">
                    <Button label="Reset" className="transaction-cancel" row={{ action: 'wallType' }} onClick={onCloseHandler} />
                    <Button label="Apply" className="transaction-ok" disabled={wallType ? false : true} onClick={applyHandler} />
                </div>
            </div>
        </TemplatePopup>
    )
}

export default WallType
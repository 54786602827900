import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import Pagination from "App/Components/UI/Pagination"
import SearchFilter from "App/Components/UI/SearchFilter"
import Status from "App/Components/UI/Status"
import Table from "App/Components/UI/Table"
import TableHeader from "App/Components/UI/Table/Components/TableHeader"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom";
import tableSort from "App/helpers/tableSort";
import FormatField from "App/Components/UI/FormatField"
import { LIST_LIMIT } from "App/constants"

const breadCrumbs = [
    {
        link: '/dashboard/users/list',
        label: 'Users'
    },
    {
        link: null,
        label: 'User\'s Transactions'
    }
]
const breadCrumbsTrial = [
    {
        link: '/dashboard/users/trial',
        label: 'Trial Users'
    },
    {
        link: null,
        label: 'Trial User\'s Transactions'
    }
]

const paginationConfig = {
    perPage: LIST_LIMIT
}
const UserTransactionDetails = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const { id } = useParams();
    const [list, setList] = useState();
    const [sort, setSort] = useState({ direction: '', sortKey: 'paymentId' });
    const [initial, setInitial] = useState(true);
    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort])
    const { state } = useLocation();
    const { from } = state;
    const [selectedDate, setSelectedDate] = useState('')
    const [search, setSearch] = useState('')

    const [dateMenu, setDateMenu] = useState([{
        label: 'Date Range',
        action: 'dateRange',
        selected: false,
        popup: true
    }
    ])
    const onFilterData = (e) => {
        setSelectedDate(e)
    }
    const searchHandler = (e) => {
        setSearch(e)
    }
    const getTransactionResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getUserTransactions = useCallback(() => {

        let filter;

        if (sort.direction || selectedDate) {
            filter = {
                sortBy: sort.sortKey ?? '',
                orderBy: sort.direction ?? '',
                ...selectedDate
            }
        }
        else {
            filter = {}
        }


        dispatch('showSpinner');
        let postData = {
            filter: filter,
            search: search,
            page: page,
            items: paginationConfig.perPage
        }
        if(from === 'trial') {
            postData.createdByTemp = id;
        } else {
            postData.userId = id;
        }
        triggerAPI({
            url: `frame-asset/admin/get/transaction-status/by/userId`,
            method: 'post',
            data: postData
        }, getTransactionResult);
    }, [page, id, sort, dispatch, triggerAPI, search, getTransactionResult, selectedDate, from])

    const onPageChangeHandler = (page) => {
        setPage(page)
        const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getUserTransactions();
        }
        dispatch('setPageTitle', 'User Transactions')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, selectedDate])

    useEffect(() => {
        if (!initial) {
            getUserTransactions();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className="back">
                    <BreadCrumbs links={from === 'user' ? breadCrumbs : breadCrumbsTrial} title="Transactions" className={from === 'user' ? '' : 'user-transaction-breadcrumbs'} />
                </div>
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setDateMenu} actionMenu={dateMenu} onFilterData={onFilterData} />
                </div>

            </div>
            <div>
                <Table>
                    <thead className="text-left">
                    <tr>
                            <th>
                                <TableHeader label="ID" sort={sort} sortKey="id" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Date" sort={sort} sortKey="createdAt" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Sending Address" />
                            </th>

                            <th>
                                <TableHeader label="Receiving Address" />
                            </th>
                            <th>
                                <TableHeader label="Amount" />
                            </th>
                            <th>
                                <TableHeader label="Asset" />
                            </th>
                            <th>
                                <div className="flex justify-end items-center">
                                    <TableHeader label="Status" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {list?.map((transaction) =>
                            <tr key={transaction.id}>
                                <td>
                                    {transaction?.id}
                                </td>
                                <td>
                                    <FormatField type="dateTime" value={transaction?.createdAt} />
                                </td>
                                <td>
                                    <FormatField type="address" maxWidth="26rem" value={transaction?.sendingAddress} />

                                </td>
                                <td>
                                    <FormatField type="address" maxWidth="26rem" value={transaction?.receivingAddress} />
                                </td>
                                <td>
                                    ₳<FormatField type="adaConvert" value={transaction?.priceInLovelace} />
                                </td>
                                <td>
                                    {transaction?.asset?.name}
                                </td>
                                <td>
                                    <div className="flex justify-end items-center">
                                        <Status label={(transaction?.state)} />
                                    </div>
                                </td>

                            </tr>
                        )}
                    </tbody>

                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No Transactions done!</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} rows={list?.length} total={totalPage} onPageChange={onPageChangeHandler} selected={page} />
                </div>
            </div>
        </div>
    )
}

export default UserTransactionDetails
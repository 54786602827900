import html2canvas from "html2canvas";
export const Html2Canvas = ({input = null, callback, backgroundColor = null, scale = 1, cloneId = ''}) => {
    try {
        if(input) {
            html2canvas(input, {
                logging: true,
                allowTaint: false,
                useCORS: true,
                backgroundColor,
                scale,
                onclone: !!cloneId && ((clonedDoc) => {
                    if(cloneId)
                    clonedDoc.getElementById(cloneId).style.visibility = 'visible';
                })
            }).then((canvas) => {
                canvas.toBlob(async blob => {
                    callback(blob);
                });
            }).catch(error => {
                console.error('error on taking screen shot: inside', error);
            })
        }
    } catch (error) {
        console.error('error on taking screen shot', error);
    }    
}
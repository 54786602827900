import "./ImageUpload.scss";
import Person from "Assets/Images/Person.svg";

const ImageUpload = (props) => {
    const imageUpload = (e) => {
        props.onChange(e.target.files[0])
    }

    const src = props.src ?? ''

    return (
        <div className={`py-8 ${props.className}`}>
            <div className="upload-image" style={{ backgroundImage: `url(${src ?? Person})` }}>
            </div>
            {props.upload && <div className="upload cursor-pointer flex justify-center items-center pt-3">
                <div className="image-label">
                    <input type="file" onChange={imageUpload} />
                </div>
                Upload
            </div>}
        </div>
    )
}

export default ImageUpload
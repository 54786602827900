import Card from "App/Components/UI/Card";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useState } from "react";
import Button from "App/Components/UI/Button";
import "./AccessPlan.scss";
import useHttp from "App/hooks/use-http";
import Popup from "App/Components/UI/Popup";
import AccessPlanEdit from "./AccessPlanEdit";
import Image from "App/Components/UI/Image";
import AccessPlanImg from "Assets/Images/Access.jpeg";
import StatusButton from "App/Components/UI/StatusButton";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";

const AccessPlan = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [list, setList] = useState();
    const [selectedAccessPlan, setSelectedAccessPlan] = useState(null);
    const [showDelConfPlan, setShowDelConfPlan] = useState(false);
    const [selectedData, setSelectedData] = useState({})
    const [ShowAccessPlanPopup, setShowAccessPlanPopup] = useState({ mode: false });
    const [addEdit, setAddEdit] = useState('');

    const AccessPlanHandler = (e) => {
        setSelectedData(e.type === 'edit' ? e.accessPlan : {})
        setAddEdit(e.type);
        setShowAccessPlanPopup({ mode: true })
    }

    const onCloseAccessPlan = (e) => {
        setShowAccessPlanPopup({ mode: false })
        if (e) {
            getAccessPlanDetails()
        }
    }

    const getAccessPlanResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data
        setList(list)
    }, [dispatch])

    const getAccessPlanDetails = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `access-plan/get`, method: 'get'
        }, getAccessPlanResult);
    }, [dispatch, getAccessPlanResult, triggerAPI])

    const deleteAccessPlanResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner');
        dispatch('showToast', { toast: { toastMode: 'success', message } });
        getAccessPlanDetails();
    }, [dispatch, getAccessPlanDetails])

    const deleteAccessPlan = useCallback(() => {
        const { id } = selectedAccessPlan;
        dispatch('showSpinner');
        triggerAPI({
            url: `access-plan/delete`, method: 'delete', data: { id }
        }, deleteAccessPlanResult);
    }, [selectedAccessPlan, dispatch, triggerAPI, deleteAccessPlanResult])

    const confirmDelAccessPlan = (row) => {
        setSelectedAccessPlan(row);
        setShowDelConfPlan(true);
    }
    const onCloseAccessPlanConf = (mode) => {
        if (mode) {
            deleteAccessPlan();
        }
        setShowDelConfPlan(false);
    }
    useEffect(() => {
        dispatch('setPageTitle', 'Access Plan')
        getAccessPlanDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="mb-6">
            <div className="access-plan-add ">
                <Button label="Add New" className="access-plan-button" row={{ type: 'add' }} onClick={AccessPlanHandler} />
            </div>

            {list?.map((accessPlan) => (
                <div className="pt-4" key={accessPlan.id}>
                    <Card>
                        <div className="access-plan-card flex items-start">
                            <div className="access-plan-image">
                                <Image src={accessPlan.imageUrl} dummyImage={AccessPlanImg} style={{ 'minHeight': '9.28rem' }} />
                            </div>
                            <div className="access-plan-text px-4 pr-12 ">
                                <div className="access-plan-title">{accessPlan.name}</div>
                                <div className="access-plan-sub-head pt-2">Minimum ADA Staked: {accessPlan.minimumStake}</div>
                                <div className="access-plan-content pt-2">{accessPlan.details}
                                </div>
                            </div>
                            <div className="flex justify-start gap-3">
                                <StatusButton row={accessPlan} onClick={confirmDelAccessPlan} label="Delete" />
                                <Button label="Edit" className="transaction-cancel" row={{ type: 'edit', accessPlan: accessPlan }} onClick={AccessPlanHandler} />
                            </div>
                        </div>
                    </Card>
                </div>
            ))}
            {!list?.length &&
                <div className="text-center no-rows pt-4">
                    <p>No data</p>
                </div>
            }
            <Popup show={ShowAccessPlanPopup}
                style={{ 'maxWidth': '52.35rem' }}
                title={addEdit === 'add' ? 'Add Access Plan' : 'Edit Access Plan'} onClose={onCloseAccessPlan}>
                <AccessPlanEdit row={selectedData} triggerClose={onCloseAccessPlan} />
            </Popup>
            <ConfirmPopup show={{ mode: showDelConfPlan }} content={`Are you sure want to delete ${selectedAccessPlan ? selectedAccessPlan.name : ''}`} buttonYes="Yes" buttonCancel="No"
                style={{ 'maxWidth': '39rem' }}
                title="Delete Access plan" cssClass="no-content" onClose={onCloseAccessPlanConf}>
            </ConfirmPopup>
        </div>
    )
}

export default AccessPlan
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./CollectionCard.scss";
import userAvatar from "Assets/Icons/default-profile-cur8.svg";
import userBanner from "Assets/Icons/default-banner-cur8.svg";
import { useStore } from "App/hooks-store/store";
import useScreenSize from "App/hooks/use-screen-size";
import FormatField from "../UI/FormatField";
const CollectionCard = (props) => {
  const screenSize = useScreenSize()?.width;
  const { leftMenuCollapse } = useStore(false)[0];
  const [bannerHover,setBannerHover] = useState(false)
  const targetRef = useRef(null);
  const updateHeight = () => {
    const root = document.documentElement;
    if (targetRef.current) {
      if (targetRef.current) {
        root?.style.setProperty(
          "--collection-card-height",
          `${targetRef.current.offsetWidth}px`
        );
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 1500);
  }, [props?.row, screenSize, leftMenuCollapse]);

  const showBannerHandler = ()=>{
    let bannerImg= props?.row?.community ? props?.row?.filePath256 : props?.row?.showcase ?? userBanner;
    return bannerImg
  }
  
  const blurHandler = () => {
    setBannerHover(false);
}

const onMouseBlur = useCallback(() => {
  setBannerHover(false);
}, []);

const onMouseFocus = useCallback(() => {
  setBannerHover(()=>true);
}, []);

useLayoutEffect(() => {
  const image = new Image();
  image.src = props?.row?.collage
}, [props?.row?.collage]);

const onClickHandler = (e)=>{
  props?.cardClick&&props?.cardClick(e)
}
  return (

    <div
      ref={targetRef}
      className={`image-item collection-sq-card flex flex-col ${props?.cardClick&&"cursor-pointer"} relative ${props?.mainClass}`}
      data-id={props?.row?.id}
      key={props?.row?.id}
      onClick={onClickHandler}
    >
      
      <div
        className="image-container relative"
        style={{backgroundImage: `url(${bannerHover? props?.row?.collage ?? showBannerHandler():showBannerHandler()})`}} 
            onMouseEnter={onMouseFocus}
            onMouseLeave={onMouseBlur}
            onBlur={blurHandler}
            onMouseOverCapture={onMouseFocus}
      ></div>
      <div className="card-details flex justify-between items-center px-[0.375rem] py-[0.625rem]">
        <div
          className={`${props?.className} flex gap-3 justify-start ${
            props?.isHome ? "items-start" : "items-center"
          }`}
        >
          {props?.showData?.profileIcon && (
            <div className="community-pic">
              <img
                src={props?.row?.community?.displayPicture256 ?? userAvatar}
                alt=""
              />
            </div>
          )}
          <div className="flex flex-col gap-1 items-start justify-start">
            {props?.showData?.collectionName && (
              <div
                className="collection-name text-left"
                title={props?.row?.name}
              >
                <FormatField
                  type="sub"
                  maxWidth={50}
                  value={props?.row?.name ?? ""}
                />
              </div>
            )}
              <div className="creator-name" title={props?.row?.community?.name}>
                <FormatField
                  type="sub"
                  maxWidth={25}
                  value={props?.row?.community?.name ?? "No community"}
                />
              </div>
            {props?.showData?.tag && (
          <div className={`content-start count-holdr`}>
            <div
              className={`nft-gallery-head tag-name flex gap-x-3 gap-y-2 flex-wrap items-center  ml-auto`}
            >
              {props?.row?.collectionTag?.slice(0,3)?.map((val)=><span key={val?.id} className="text-xs">#{val?.tags?.name}</span>)}
            </div>
          </div>
        )}
          </div>
        </div>
        {props?.showData?.count && (
          <div className={`content-start count-holdr`}>
            <div
              className={`nft-gallery-head nft-gallery-count flex gap-1 items-center  ml-auto`}
            >
              <span>{props?.row?.assetsCount || 0}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;

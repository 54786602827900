const ButtonLink = ({ label, row = {}, onClick }) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick({ row })
    }
  }
  return (
    <button onClick={onClickHandler} className="btn-buttonlink">
      {label}
    </button>
  )
}
export default ButtonLink;
import getDate from "App/helpers/date";
import getDateTime from "App/helpers/dateTime";
import { convertPriceToAda, getHexToStr } from "App/helpers/utilities";
import "./FormatField.scss"
import getDayMonthYearDate from "App/helpers/dayMonthYearDate";

const FormatField = ({ type, value, maxWidth = '',maxLength=0,startLength=0 ,endLength=0}) => {
    switch (type) {
        case 'date':
            return getDate(value)
        case 'dateTime':
            return getDateTime(value)
        case 'dayMonthYearDate':
            return getDayMonthYearDate(value)
        case 'hexToString':
            return getHexToStr(value)
        case 'adaConvert':
            return convertPriceToAda(value)
        case 'substring':
            return value?.length > maxLength ? value?.toString()?.substring(0, startLength) + '...' + value?.toString()?.substring(value?.length - endLength)
                : value?.toString()
        case 'sub':
            return value?.substring(0, maxWidth) + (value?.length > maxWidth ? "..." : "");
        case 'address':
            return <div className="address" style={{ maxWidth: maxWidth }}>{value}</div>
        default:
            break;
    }

}

export default FormatField
import useHttp from "App/hooks/use-http"
import { useCallback, useEffect, useState } from "react"
import Button from "../Button"
import MultiSelectFilter from "../MultiSelectFilter"
import TemplatePopup from "../TemplatePopup"

const WallStyle = (props) => {
    const { triggerAPI } = useHttp();
    const [list, setList] = useState([]);
    const [wallStyle, setWallStyle] = useState([])

    const apiResult = useCallback((res) => {
        const list = res.data;
        const arr = list.map((item) => item.style)
        setList(arr)
    }, [])

    const getWallStyle = useCallback(() => {
        triggerAPI({
            url: `background-thumbnail/style/get`, method: 'get'
        }, apiResult);
    }, [apiResult, triggerAPI])

    const handleChange = (e) => {
        if (e.target.checked) {
            setWallStyle([...wallStyle, e.target.name])
        }
        else {
            setWallStyle(wallStyle.filter(val => val !== e.target.name))
        }
    }

    const applyHandler = () => {
        props.onFilterData({
            ...props.data,
            style: wallStyle
        })
        props.setWallStyle(wallStyle)
    }

    const onCloseHandler = (data) => {
        props.onClose(data)
        setWallStyle('')
    }

    useEffect(() => {
        getWallStyle()
        setWallStyle(props.wallStyle)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <TemplatePopup show={props.show} cssClass="date-desk" onClose={props.onCloseHandler}>
            <div className="date-picker p-9 flex flex-col">
                <div className="status-filter-head pb-4 ">Select wall Style</div>
                <div>
                    <MultiSelectFilter list={list} checked={wallStyle} onChange={handleChange} />
                </div>
                <div className="flex justify-center gap-4 pb-5 pt-9">
                    <Button label="Reset" className="transaction-cancel" row={{ action: 'style' }} onClick={onCloseHandler} />
                    <Button label="Apply" className="transaction-ok" disabled={wallStyle ? false : true} onClick={applyHandler} />
                </div>
            </div>
        </TemplatePopup>
    )
}

export default WallStyle
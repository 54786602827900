import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import {
  getInitial,  
  validateFormGroup,
} from "App/helpers/formValidation";
import { extractFormFieldKeys } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useMemo, useState } from "react";

const  RewardForm = (props) =>{
  const [formVaildState, setFormVaildState] = useState({});
  const [formValues, setFormvalues] = useState({});
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];

  const formGroup = useMemo(() => {
    let list = {
    minDays: {
        value: undefined,
        isRequired: true,
    },
    nftCount: {
        value: undefined,
        isRequired: true,
    },
    rewardAmount: {
        value: undefined,
        isRequired: true,
    },
    };
    setFormvalues(getInitial(list));
    return list;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.row]);

  const onClose = ({ mode = false }) => {
    props.triggerClose(mode);
  };

  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    onClose({ mode: true });
  };

const formHandler = () => {
        const { valid } = validateFormGroup({
            errors: formVaildState,
            formGroup,
            values: formValues,
        });
        if (valid) {
            if (props?.row.id) {
            dispatch("showSpinner");
                let data = {
                minDays: formValues.minDays,
                nftCount: formValues.nftCount,
                rewardAmount: formValues.rewardAmount,
                id: props.row.id
                };
                triggerAPI(
                    {
                      url: "admin/rewards/monet/update",
                      data: data,
                      method: "put",
                    },
                    apiResult,
                    (err) => {
                      dispatch("hideSpinner");
                      dispatch("showToast", {
                        toast: {
                          toastMode: "error",
                          message:
                            err?.response?.data?.error?.message ||
                            "Failed to update monet reward",
                        },
                      });
                    }
                  );
        
            } 
        }
    };

  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);

  useEffect(() => {
    if (props.row?.id) {
      const result = extractFormFieldKeys(
        { ...formGroup, id: "" },
        { ...props.row }
      );
      setFormvalues(result);
    } else {
      setFormvalues(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row]);

  return (
    <div className="sub-admin-create">
      <div className="w-full">
        <div className="flex gap-2 pt-1 column">
          <div className="sub-admin-form">
            <InputBox
              label="Wallet Opening Days"
              validateOnBlur
              type="number"
              autoComplete="new-password"
              controlName="minDays"
              onChange={inputOnChange}
              value={formValues.minDays}
            />
            <Validators
              type="Required"
              value={formValues.minDays}
              message="Required field"
              controlName="minDays"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              label="NFT Count"
              validateOnBlur
              type="number"
              autoComplete="new-password"
              controlName="nftCount"
              onChange={inputOnChange}
              value={formValues.nftCount}
            />
            <Validators
              type="Required"
              value={formValues.nftCount}
              message="Required field"
              controlName="nftCount"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              label="Monet Rewards"
              validateOnBlur
              type="number"
              autoComplete="new-password"
              controlName="rewardAmount"
              onChange={inputOnChange}
              value={formValues.rewardAmount}
            />
            <Validators
              type="Required"
              value={formValues.monet}
              message="Required field"
              controlName="rewardAmount"
              onErrors={collectErrors}
            />
          </div>
        </div>
      </div>

      <div className="flex transaction-button justify-center pt-12">
        <Button
          label="Cancel"
          onClick={onClose}
          className="transaction-cancel"
        />
        <Button
          className="transaction-ok"
          label={`${props?.row?.id ? "Update" : "Create"}`}
          onClick={formHandler}
        />
      </div>
    </div>
  );
}

export default RewardForm;

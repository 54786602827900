import Button from "App/Components/UI/Button";
import Card from "App/Components/UI/Card";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import Popup from "App/Components/UI/Popup";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react"
import "./ArtBank.scss"
import AddWallet from "./Components/AddWallet";
const ArtBank = () => {
    const dispatch = useStore(false)[1];
    const [ShowConfirmPopup, setShowConfirmPopup] = useState({ mode: false });
    const [ShowRemovePopup, setShowRemovePopup] = useState({ mode: false });
    const [selectedWallet, setSelectedWallet] = useState({})
    const [list, setList] = useState([]);
    const { triggerAPI } = useHttp()

    const addWalletHandler = () => {
        setShowConfirmPopup({ mode: true })
    }

    const onCloseCreateUser = (data) => {
        setShowConfirmPopup({ mode: false })
        if (data) {
            getWalletDetails()
        }
    }

    const onCloseRemovePopup = (data) => {
        setShowRemovePopup({ mode: false })
        if (data) {
            goToRemove(selectedWallet)
        }

    }

    const removeWalletHandler = (e) => {
        setShowRemovePopup({ mode: true })
        setSelectedWallet(e)

    }

    const getWalletResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data
        setList(list)
    }, [dispatch])

    const getWalletDetails = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `art-bank/get`, method: 'get'
        }, getWalletResult);
    }, [dispatch, getWalletResult, triggerAPI])

    const getRemoveResult = useCallback((res) => {
        dispatch('hideSpinner');
        getWalletDetails()
    }, [dispatch, getWalletDetails])

    const goToRemove = useCallback((e) => {
        const data = {
            id: e.id
        }
        dispatch('showSpinner');
        triggerAPI({
            url: `art-bank/delete`, data, method: 'delete'
        }, getRemoveResult);
    }, [dispatch, getRemoveResult, triggerAPI])

    useEffect(() => {
        dispatch('setPageTitle', 'Art Bank')
        getWalletDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="text-right mb-4">
                <Button label="Add Wallet Address" className="access-plan-button" onClick={addWalletHandler} />
            </div>

            <div className="flex flex-wrap gap-3 justify-between">
                {list?.map((artbank) => (
                    <div className="flex artbank-card-full" key={artbank.id}>
                        <Card>
                            <div className="artbank-card">
                                <div className="flex-col justify-between">
                                    <div className="artbank-card-border ">
                                        <div className="artbank-name">{artbank.name}</div>
                                        <div className="artbank-label">Wallet Address</div>
                                        <div className="artbank-value mt-2 mr-2">{artbank.walletAddress}
                                        </div>
                                    </div>
                                    <div className="flex pt-4 justify-between">
                                        <div className="artbank-total gap-1">
                                            <div>Total NFTs: </div>
                                            <div className="artbank-total-value"> {artbank.nftCount}</div>
                                        </div>
                                        <Button className="artbank-remove" row={artbank} label="Remove Wallet" onClick={removeWalletHandler} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                ))}
                {!list?.length &&
                    <div className="text-center justify-center items-center no-rows pt-4">
                        <p>No data</p>
                    </div>
                }
            </div>

            <Popup show={ShowConfirmPopup}
                style={{ 'maxWidth': '39rem' }}
                title="Add Wallet Address" cssClass="add-wallet" onClose={onCloseCreateUser}>
                <AddWallet triggerClose={onCloseCreateUser} />
            </Popup>

            <ConfirmPopup show={ShowRemovePopup} content="Are you sure want to remove this wallet address?" buttonYes="Remove" buttonCancel="Cancel"
                style={{ 'maxWidth': '39rem' }}
                title="Remove Wallet Address" cssClass="remove-wallet" onClose={onCloseRemovePopup}>
                <div className="flex p-3">
                    <div className="remove-wallet-input">
                        <div className="policy-popup-label">{selectedWallet.walletAddress}</div>
                    </div>
                </div>
            </ConfirmPopup>
        </div>
    )

}

export default ArtBank
import PageTab from "App/Components/UI/PageTab";
import { Outlet } from "react-router-dom";



const AccessPlanAndArtBank = () => {
    const tabs = [{
        link: `art-bank`,
        label: 'Art Bank'
    }, {
        link: `access-plan`,
        label: 'Access Plan'
    }]

    return (
        <div>
            <PageTab tabs={tabs} />
            <div className="py-4">
                <Outlet />
            </div>
        </div>
    )
}
export default AccessPlanAndArtBank;
import { Fragment, useEffect, useState } from "react";

const TemplatePopup = ({ title, show = { mode: false }, children, style = {}, cssClass = '', onClose }) => {
    const [visibility, setVisibility] = useState(false);
    const [activeClass, setActiveClass] = useState(false);
    const closePanelHendler = () => {
        setActiveClass(false)
        setTimeout(() => {
            setVisibility(false);
            onCloseHandler(false);
        }, 500);//time based on transition time
    }
    const onCloseHandler = (arg) => {
        if (onClose) {
            onClose(arg)
        }
    }
    useEffect(() => {
        const { mode } = show;
        if (mode) {
            setVisibility(mode);
            setTimeout(() => {
                setActiveClass(mode);
            }, 100)
        }
        else {
            setActiveClass(mode);
            setTimeout(() => {
                setVisibility(mode);
            }, 500)
        }
    }, [setVisibility, show])
    return (
        <Fragment>
            {visibility && (<div className={`popup ${cssClass} ${activeClass ? 'popupActive' : ''}`}>
                <div className="popup-overlay" onClick={closePanelHendler}></div>
                 <div className="popup-template" style={style}>
                       <div className="popup-template-body">
                        {children}
                    </div> 
                </div> 
            </div>)}
        </Fragment>
    )
}
export default TemplatePopup;
import { objectToFormdata } from "App/helpers/utilities";
import useHttp from "./use-http";

const useCreatorScreenshot = () => {    
    const { triggerAPI } = useHttp();

    const saveCreatorScreenShot = async ({data, callBack = null}) => {   
        const formData = objectToFormdata(data);                                
        triggerAPI(
            {
                url: `community/update-thumbnail`,
                data: formData,
                method: "post",
            }, (res) => {
                callBack && callBack(res);
            }
        );                    
    }   
    return {
        saveCreatorScreenShot        
    }
}
export default useCreatorScreenshot
import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import Button from "App/Components/UI/Button"
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { extractFormFieldKeys, objectToFormdata } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import FrameImage from "Assets/Images/frame-upload.png";
import { useMemo } from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentUpload from "../../ContentUpload";

const AddContent = () => {
    const { subid, id } = useParams()
    const [list, setList] = useState()
    const [frameConfig, setFrameConfig] = useState(null);

    const navigate = useNavigate()
    useEffect(() => {
        if (id) {
            setList(id)
        }
    }, [id])

    const breadCrumbs = [
        {
            link: '/dashboard/information-desk/category',
            label: 'Categories'
        },
        {
            link: `/dashboard/information-desk/category/${list}`,
            label: 'Category Details'
        },
        {
            link: null,
            label: 'Create content'
        }
    ]
    const breadCrumbsEdit = [
        {
            link: '/dashboard/information-desk/category',
            label: 'Categories'
        },
        {
            link: `/dashboard/information-desk/category/${id}`,
            label: 'Category Details'
        },
        {
            link: null,
            label: 'Edit content'
        }
    ]
    const [formVaildState, setFormVaildState] = useState({});
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();

    const formGroup = useMemo(() => ({
        infoDeskCategoryId: {
            value: undefined,
            isRequired: true
        },
        title: {
            value: undefined,
            isRequired: true
        },
        videoUrl: {
            value: undefined,
            isRequired: true
        },
        file: {
            value: undefined,
            isRequired: true
        }
        ,
        imageUrl: {
            value: undefined,
            isRequired: true
        },
        metaInfo: {
            value: undefined,
            isRequired: true
        }
    }), [])
    const [formValues, setFormvalues] = useState(getInitial(formGroup));

    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }

    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    const cancelHandler = () => {
        navigate(`/dashboard/information-desk/category/${id}`)
    }
    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        cancelHandler()
    }
    const saveHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid && formValues?.id) {
            delete formValues.infoDeskCategoryId;
            delete formValues.profilePicUrl;
            if (!formValues.imageUrl?.name) {
                delete formValues.imageUrl;
            }
            formValues.videoUrl = formValues.videoUrl ? formValues.videoUrl : null;
            const list = { ...formValues, id: formValues.id }
            const formData = objectToFormdata(list)
            dispatch('showSpinner');
            triggerAPI({
                url: 'information-desk/category/content/update', data: formData, method: 'put'
            }, apiResult);

        } else if (valid) {

            formValues.infoDeskCategoryId = id;
            formValues.file = formValues.imageUrl
            delete formValues.profilePicUrl
            delete formValues.imageUrl
            formValues.videoUrl = formValues.videoUrl ? formValues.videoUrl : null;

            const formData = objectToFormdata(formValues)
            dispatch('showSpinner');
            triggerAPI({
                url: 'information-desk/category/content/add', data: formData, method: 'post'
            }, apiResult);
        }
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }

    const getContentDetailsResult = useCallback((res) => {
        dispatch('hideSpinner')
        const list = extractFormFieldKeys({ ...formGroup, id: '' }, res.data);
        setFrameConfig(list.metaInfo);
        setFormvalues(list)
    }, [dispatch, formGroup])

    const getContentDetails = useCallback((e) => {
        dispatch('showSpinner');
        triggerAPI({
            url: `information-desk/category/content/get/${subid}`, method: 'get'
        }, getContentDetailsResult);
    }, [dispatch, getContentDetailsResult, subid, triggerAPI])

    useEffect(() => {
        if (formValues.imageUrl?.name) {
            const reader = new FileReader()
            reader.addEventListener('load', (res) => {
                const profilePicUrl = res.target.result
                setFormvalues((prev) => {
                    return {
                        ...prev,
                        profilePicUrl

                    }
                });
            })
            reader.readAsDataURL(formValues.imageUrl)
        }

    }, [formValues.imageUrl])
    const framePositionHandler = useCallback((pos) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                metaInfo: pos
            }
        });
    }, [])
    useEffect(() => {
        if (subid) {
            getContentDetails()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={subid ? breadCrumbsEdit : breadCrumbs} title={subid ? "Edit content" : "Create content"} className="add-breadcrumbs" />
                </div>
                <div className="flex gap-2">
                    <Button label="Cancel" className="transaction-cancel" onClick={cancelHandler} />
                    <Button label={subid ? "Update" : "Save"} className="transaction-ok" onClick={saveHandler} />
                </div>
            </div>

            <div className="flex flex-col gap-4 pt-[2rem]">
                <div className="flex justify-evenly gap-4">
                    <div className="w-full">
                        <InputBox label="Title" type="text" validateOnBlur controlName="title" value={formValues?.title} onChange={inputOnChange} />
                        <Validators type="Required" value={formValues.title} message="Required field" controlName="title" onErrors={collectErrors} />
                    </div>
                    <div className="w-full">
                        <InputBox file label="Upload Background Image" className="content-file cursor-pointer" type="text" validateOnBlur controlName="imageUrl" value={formValues?.imageUrl} onChange={inputOnChange} />
                        <Validators type="Required" value={formValues.imageUrl} message="Required Field" controlName="imageUrl" onErrors={collectErrors} />

                        <div className="content-image-value pt-1">PNG, JPEG Only</div>
                    </div>
                    <div className="w-full">
                        <InputBox label="Video URL" type="text" controlName="videoUrl" value={formValues?.videoUrl ? formValues?.videoUrl : null} onChange={inputOnChange} />
                        <div className="content-desc pt-1">
                            Use Youtube video URL link above for video content
                        </div>
                    </div>
                </div>
                <div className="flex w-full pb-8">
                    <ContentUpload upload className="" dummyImage={FrameImage} onFramePosUpdate={framePositionHandler} src={formValues.imageUrl?.name ? formValues.profilePicUrl : formValues.imageUrl ?? ''} metaInfo={frameConfig} controlName="file" videoUrl={formValues.videoUrl} />
                </div>
            </div>
        </div>
    )
}

export default AddContent
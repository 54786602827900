import Logo from 'App/Components/Logo';
import ButtonHamburger from 'App/Components/UI/ButtonHamburger';
import useAuth from 'App/hooks/use-auth';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Header from './Header';
import Sidebar from './Sidebar';
const DashboardLayout = () => {
    const token = useAuth()[0];
    const location = useLocation();
    const [navActive, setNavActive] = useState(false);
    const toggle = () => {
        setNavActive((prev) => !prev);
    }
    useEffect(() => {
        setNavActive(false);
    }, [location]);
    return (
        <div className='dashboard-layout'>
            <div className='dashboard-layout-header'>
                <div className='dashboard-layout-left dashboard-layout-logo'>
                    <Logo />
                </div>
                <div className='dashboard-layout-right dashboard-layout-headerArea flex items-center'>
                    <div className='dashboard-layout-mobileMenu pr-2'>
                        <ButtonHamburger onClick={toggle} />
                    </div>
                    <Header />
                </div>
            </div>
            <div className='dashboard-layout-main'>
                <div className={`dashboard-layout-overlay ${navActive ? 'navOpen' : ''}`} onClick={toggle}></div>
                <div className={`dashboard-layout-left  dashboard-layout-sidebar ${navActive ? 'navOpen' : ''}`}>
                    <div className='dashboard-layout-logo-mobile  items-center justify-center'>
                        <Logo />
                    </div>
                    <Sidebar />
                </div>
                <div id="content" className='dashboard-layout-right dashboard-layout-content-area'>
                    {!!token ? <Outlet /> : <Navigate to="/" />}
                </div>
            </div>
        </div>
    )
}
export default DashboardLayout;
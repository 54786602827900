import { Fragment, useEffect, useRef, useState } from "react";
import "./InputBox.scss";
const InputBox = ({ file = '',autoComplete="off", controlName,accept, label,isFile,fieldRef, type,required=true, onChange, className, config, disabled = false, validateOnBlur, value = '', pre, post, hidden = false, maxLength, placeholder = ''}) => {
    const [eyeStatus, setEyeStatus] = useState('on')
    const [close, setClose] = useState(false)
    const inputRef = useRef()

    let addOne = null;
    let preValue = null;
    let postValue = null;

    const closeHanlder = () => {
        setClose(true);
        const label = controlName ?? 'value'
        const value = {
            [label]: ''
        }
        if (onChange) {
            onChange(value)
        }
    }

    const onChangeHandler = (e) => {
        const label = controlName ?? 'value'
        let value = {};
        if (e.target?.files) {
            value = {
                [label]: e.target.files[0]
            }
        }
        else {
            value = {
                [label]: e.target.value
            }
        }


        if (onChange) {
            onChange(value)
        }
    }

    const onBlurHandler = (e) => {
        if (validateOnBlur) {
            e.target.value = e.target.value ?? '';
            onChangeHandler(e);
        }
    }
    const onEyeClickHandler = () => {
        const isPassword = inputRef.current.type === 'password'
        inputRef.current.type = isPassword ? 'text' : 'password';
        const statusClass = isPassword ? 'off' : 'on'
        setEyeStatus(statusClass);
    }
    if (config) {
        addOne = <button type="button" className={`btn btn-eye btn-eye-${eyeStatus}`} onClick={onEyeClickHandler}></button>
    }
    else if (pre) {
        preValue = <div className="form-value form-value-pre">{pre}</div>
    }
    else if (file) {
        file =
            <div className="flex gap-3 w-full">
                {(value && !close) ?
                    <Fragment>
                        <div className="flex w-full justify-between px-2">
                            <div className="file-value">
                                {value && value?.name ? `${value?.name.substring(0, 20)} ${value?.name.length > 20 ? '...' : ''}` : `${value.substring(0, 20)} ${value.length > 20 ? '...' : ''}`
                                }
                            </div>
                            <button className="btn btn-close" onClick={closeHanlder}></button>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <label htmlFor="files" className="btn flex file ml-2 cursor-pointer" >Browse</label>
                        <input id="files" type="file" onChange={onChangeHandler} hidden />
                    </Fragment>
                }
            </div>
    }
    else {
        postValue = <div className="form-value form-value-post">{post}</div>
    }
    useEffect(() => {
        if (inputRef?.current?.disabled) {
            inputRef.current.disabled = disabled;
        }
    }, [disabled])

    return (
        <div className={className}>
            <label className="form-label">{label} {(validateOnBlur && !disabled && required) ? '*' : ''}</label>
            <div className="flex justify-between form-box items-center mt-1">
                {pre &&
                    <div className="items-center flex">
                        {preValue}
                    </div>
                }
                {file &&
                    <div className="items-center flex w-full p-1">
                        {file}
                    </div>
                }
                 {isFile && <input accept={accept} disabled={disabled} className="p-4 outline-none formControl aa" type={type} ref={fieldRef} onChange={onChangeHandler} onBlur={onBlurHandler} 
                />}
                {!file && !isFile && <input placeholder={placeholder} disabled={disabled} autoComplete={autoComplete} className="p-4 outline-none formControl" type={type} ref={inputRef} onChange={onChangeHandler} onBlur={onBlurHandler} value={value} maxLength={maxLength}
                />}

                {config &&
                    <div className="mr-5 items-center flex">
                        {addOne}
                    </div>
                }
                {post &&
                    <div className="items-center flex">
                        {postValue}
                    </div>
                }
            </div>
        </div>

    )
}

export default InputBox
import "./color.scss";
const ColorField = ({
  controlName,
  keyName = "id",
  label,
  required = true,
  colors,
  value,
  onChange,
  className,
}) => {
  const onColorSelect = (e) => {
    onChange({[controlName]:e.currentTarget.dataset.id})
  };
  return (
    <div className={`${className} color-field`}>
      <label className="form-label">
        {label} {required ? "*" : ""}
      </label>
      <div className=" color-box mt-1">
        {colors?.map((color) => (
          <div
            onClick={onColorSelect}
            key={color.id}
            data-id={color[keyName]}
            className={`color-circle ${
                value === color[keyName] ? "selected" : ""
            } ${color.name === "multi-color" ? "multi" : ""}`}
            style={{ backgroundColor: color.name }}
          >
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorField;

import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import "../GalleryDetails.scss"
import GalleryDetailsHeader from "../GalleryDetailsHeader";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const breadCrumbs = [
    {
        link: '',
        label: 'Galleries',
        back: true
    },
    {
        link: null,
        label: 'Gallery Details'
    }
]

const GalleryDetails = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [list, setList] = useState();
    const { id } = useParams();

    const getGalleryDetailsResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data
        setList(list)
    }, [dispatch])

    const getGalleryDetailsList = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `gallery/get/${id}`, method: 'get'
        }, getGalleryDetailsResult);
    }, [dispatch, getGalleryDetailsResult, id, triggerAPI])

    useEffect(() => {
        getGalleryDetailsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={breadCrumbs} title="Gallery Details" />
                </div>
            </div>
            <div className="pt-7">
                <GalleryDetailsHeader row={list} />
            </div>
        </div>
    )
}

export default GalleryDetails
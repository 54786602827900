import Button from "App/Components/UI/Button";
import InputBoxLogin from "App/Components/UI/Forms/InputBoxLogin";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "App/hooks-store/store";
import React, { useCallback, useState } from "react";
import useHttp from "App/hooks/use-http";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import Validators from "App/Components/UI/Forms/Validators";

const ResetPassword = React.memo(() => {
    const searchParams = useSearchParams()[0];
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        confirmPassword: {
            value: undefined,
            isRequired: true
        },
        password: {
            value: undefined,
            isRequired: true
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup));
    let navigate = useNavigate();
    const dispatch = useStore(false)[1];
    const goToLogin = useCallback(() => {
        navigate("/", { replace: true });
    }, [navigate])
    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        goToLogin();
    }
    const { triggerAPI: resetPasswordAPI } = useHttp();
    const resetPasswordHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            const data = {
                password: formValues.password,
                token: searchParams.get("token")
            }
            dispatch('showSpinner');
            resetPasswordAPI({
                url: 'admin/reset-password', data, method: 'post'
            }, apiResult);
        } else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    return (
        <div className="login-layout-wrapper">
            <h1 className="login-layout-title mb-12"> Reset Password</h1>
            <div className="login-layout-row login-layout-description login-layout-description-max">
                <p>Please set a new password and then enter confirm password</p>  </div>
            <div className="login-layout-row mt-6">
                <InputBoxLogin label="New Password" validateOnBlur type="password" controlName="password" onChange={inputOnChange} config={{ hasEyeIcon: true }} />
                <Validators type="Required"  value={formValues.password} message="Required field" controlName="password" onErrors={collectErrors} />
            </div>
            <div className="login-layout-row mt-6">
                <InputBoxLogin label="Confirm Password" validateOnBlur controlName="confirmPassword" type="password" onChange={inputOnChange} config={{ hasEyeIcon: true }} />
                <Validators type="Required" value={formValues.confirmPassword} message="Required field" controlName="confirmPassword" onErrors={collectErrors} />
                <Validators type="Match" value={formValues.confirmPassword} matchValue={formValues.password} message="Password and confirm password should be same" controlName="confirmPassword" onErrors={collectErrors} />
            </div>
            <div className="login-layout-row mt-6">
                <Button className="btn-login" label="Reset Password" onClick={resetPasswordHandler} />
            </div>
        </div>
    )
})
export default ResetPassword;
import "./MultiSelectBox.scss";
import React, { useRef, useState, useEffect, useCallback, Fragment } from "react";
import MultiSelectBoxOption from "./MultiSelectBoxOption";
const MultiSelectBox = React.memo(({ list, onChange, validateOnBlur, value, className, label, placeHolder, tabIndex = -1 }, props) => {
    const select = useRef();
    const [show, setShow] = useState(false);
    const [newLists, setNewList] = useState([]);
    const [placeHolderName, setPlaceHolderName] = useState('');
    const [selectCount, setSelectCount] = useState(0);

    const applyToggle = useCallback(() => {
        const parent = select.current;
        const list = parent.querySelector('div.selectbox-list');
        if (show) {
            parent.classList.remove('selectbox-list-closed');
            parent.classList.add('selectbox-list-open');
            list.style.maxHeight = `${list.scrollHeight}px`;
        } else {
            parent.classList.remove('selectbox-list-open');
            parent.classList.add('selectbox-list-closed');
            list.style.maxHeight = `0px`;
        }
    }, [show])

    const getPlaceHolder = useCallback((list) => {
        let result = placeHolder;
        let selectedData = [];
        if (list) {
            selectedData = list.filter(x => x.checked)
            if (selectedData.length > 1) {
                result = `${selectedData[0].name} and ${selectedData.length - 1} others`
            }
            else if (selectedData.length === 1) {
                result = `${selectedData[0].name}`

            }
            else {
                result = placeHolder
            }
        }
        return {
            text: result, selectedData
        }

    }, [placeHolder])

    const updateData = useCallback(() => {
        const { text } = getPlaceHolder(newLists)
        setPlaceHolderName(text)
    }, [getPlaceHolder, newLists])

    const handleCheck = useCallback((event) => {
        setNewList((prev) => {
            let found = prev.find((x) => x.id === event.id)
            if (found) {
                found.checked = event.checked
            }
            updateData()
            return prev
        })
    }, [updateData])

    const onBlur = (e) => {
        setShow(false);
        setSelectCountWithTimer(0);
        applyToggle();
        const { selectedData } = getPlaceHolder(newLists)
        const selectedID = selectedData.map((x) => x.id);
        if (onChange) {
            onChange({ artBanks: selectedID })
        }
    }

    const setSelectCountWithTimer = (value) => {
        setTimeout(() => {
            setSelectCount(value);
        }, 500);
    }
    const focusHandler = () => {
        setShow(true);
        //escape initial
        setSelectCountWithTimer(1)
    }

    const clickHandler = () => {
        if (selectCount === 1) {
            select.current.blur();
        }
    }

    useEffect(() => {
        const values = list?.map((x => {
            return {
                ...x,
                checked: value?.includes(x?.id)
            }
        }))

        setNewList(values)
    }, [list, value])

    useEffect(() => {
        applyToggle();
    }, [applyToggle])

    useEffect(() => {
        updateData()
    }, [getPlaceHolder, updateData])

    return (
        <div className={`relative selectbox ${className} `} ref={select} onFocus={focusHandler} onBlur={onBlur} tabIndex={tabIndex}>
            <div className="selectbox-label" onClick={clickHandler}>

                <div>
                    <label className="form-label">{label} {(validateOnBlur) ? '*' : ''}</label>
                    <span className="selectbox-placeHolder form-box flex justify-start 
                    items-center mt-1 px-2">  {
                            placeHolderName}</span></div>
            </div>
            <div className="absolute selectbox-list">
                <ul>
                    {newLists?.map((item) =>
                        <Fragment key={item.id} >
                            <MultiSelectBoxOption {...item} onChange={handleCheck} />
                        </Fragment>
                    )}
                </ul>
            </div>
        </div>
    )
})
export default MultiSelectBox
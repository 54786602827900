import { useEffect } from "react";
import { useState } from "react";
import Button from "../Button";
import Range from "../Range";
import TemplatePopup from "../TemplatePopup"
import "./RangeFilter.scss"

const RangeFilter = (props) => {
    const [value, setValue] = useState({minVal: 0, maxVal: 0});
    const handleChange = (e) => {
        props?.onChange(e)
        setValue(e)
    }
    useEffect(() => {
        setValue(props?.value);
    },[props?.value])
    const onCloseHandler = (data) => {
        props?.onClose(data)
    }
    const applyHandler = () => {
        props.onApply({
            ...props.data,
            [props?.keyName]: value
        })
    }
    return (
        <TemplatePopup show={props.show} cssClass="date-desk" onClose={props.onCloseHandler}>
            <div className="select-filter date-picker p-9 flex flex-col">
                <div className="status-filter-head pb-4 ">{props?.rangeName}</div>
                <div className="flex justify-center mb-[1rem] mt-[3rem] flex-col gap-[1rem]">
                    <Range placeHolder="Select" label={`Select ${props?.selectName}`} className="frame-form" controlName="year" onChange={handleChange} currentId={value} tabIndex={0} />
                </div>
                <div className="flex justify-center gap-4 pb-5 pt-9 custom-filter-button">
                    <Button label="Reset" className="transaction-cancel" row={{ action: 'resetRange' }} onClick={onCloseHandler} />
                    <Button label="Apply" className="transaction-ok"  onClick={applyHandler} />
                </div>
            </div>
        </TemplatePopup>
    )
}

export default RangeFilter;
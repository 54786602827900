import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import Button from "App/Components/UI/Button";
import Card from "App/Components/UI/Card"
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import ImageUpload from "App/Components/UI/ImageUpload"
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddNewRow from "./Components/AddNewRow";

const breadCrumbs = [
   {
      link: '/dashboard/settings/profile',
      label: 'View Profile'
   },
   {
      link: null,
      label: 'Edit Profile'
   }
]

const ProfileEdit = () => {
   const [formVaildState, setFormVaildState] = useState({});
   const [file, setFile] = useState()
   const formGroup = {
      name: {
         value: undefined,
         isRequired: true
      }
   }
   const [formValues, setFormvalues] = useState(getInitial(formGroup))
   const { triggerAPI } = useHttp();
   const dispatch = useStore(false)[1];
   const navigate = useNavigate();

   const apiResult = (res) => {
      const { message } = res;
      dispatch('hideSpinner')
      dispatch('showToast', { toast: { toastMode: 'success', message } })
      dispatch('setProfile', { profile: res.data.profilePicUrl })
   }

   const getProfileResult = useCallback((res) => {
      dispatch('hideSpinner');
      const list = res.data;
      setFormvalues(list)
   }, [dispatch])

   const getProfileDetails = useCallback(() => {
      dispatch('showSpinner');
      triggerAPI({
         url: `admin/profile/get`, method: 'get'
      }, getProfileResult);
   }, [dispatch, getProfileResult, triggerAPI])

   const cancelhandler = () => {
      navigate("/dashboard/settings/profile")
   }

   const inputOnChange = (e) => {
      setFormvalues((prev) => {
         return {
            ...prev,
            ...e
         }
      })
   }
   const collectErrors = useCallback((error) => {
      setFormVaildState((prev) => {
         return {
            ...prev,
            ...error
         }
      })
   }, [])

   const uploadHandler = (e) => {
      const reader = new FileReader()
      reader.addEventListener('load', (res) => {
         const profilePicUrl = res.target.result
         setFile(e);
         setFormvalues((prev) => {
            return {
               ...prev,
               profilePicUrl
            }
         });
      })
      reader.readAsDataURL(e)
   }

   const profileEditHandler = () => {

      const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
      if (valid) {
         const formData = new FormData()
         if (file) {
            formData.append('file', file)
         }
         formData.append('name', formValues.name)
         dispatch('showSpinner');
         triggerAPI({
            url: `admin/profile/update`, data: formData, method: 'post'
         }, apiResult);
      }
      else {
         const newValues = markAsTouched(formValues);
         setFormvalues(newValues);
      }

   }

   useEffect(() => {
      getProfileDetails();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <div>
         <div className="flex justify-between">
            <div className="back">
               <BreadCrumbs links={breadCrumbs} title="Profile" />
            </div>
            <div className="flex gap-3">
               <Button label="Cancel" className="transaction-cancel" onClick={cancelhandler} />
               <Button label="Save" onClick={profileEditHandler} />
            </div>
         </div>

         <div className="pt-4">
            <Card>
               <div className="settings-profile-card">
                  <div className="settings-profile-image">
                     <ImageUpload upload src={formValues.profilePicUrl} onChange={uploadHandler} className="profile-edit-image" />
                  </div>
                  <div className="flex  justify-between mt-6">
                     <div className="flex justify-between w-full gap-12 flex-wrap">
                        <div className="gap-1 profile-edit-input profile-edit-form-field">
                           <InputBox label="Full Name" value={formValues.name} validateOnBlur type="text" controlName="name" onChange={inputOnChange} />
                           <Validators type="Required" value={formValues.name} message="Required field" controlName="name" onErrors={collectErrors} />
                        </div>
                        <div className="gap-1 profile-edit-input profile-edit-form-field">
                           <InputBox label="Email" validateOnBlur type="text" value={formValues.email} controlName="email" onChange={inputOnChange} disabled="true" />
                        </div>
                     </div>

                  </div>
               </div>
            </Card>

         </div>
         <div className="py-5 profile-edit-head">
            Internal Payment Wallet Addresses
         </div>
         <div>
            <Card>
               <AddNewRow />
               <div className="pt-2">
                  {/* <Button label="+Add More" className="add-more" onClick={addFormFields}/> */}
               </div>
            </Card>

         </div>
      </div>
   )
}

export default ProfileEdit
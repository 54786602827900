import { useEffect, useState } from "react";
import "./Status.scss";
const Status = ({ label }) => {

    const [className, setClassName] = useState('');

    useEffect(() => {
        let name = '';
        switch (label.slice(0, 1).toUpperCase() + label.slice(1)) {
            case 'Blocked':
            case "Inactive":
            case "Unreviewed":
                name = 'status-blocked';
                break;
            case 'Reviewed':
            case 'Active':
            case 'Verified':
                name = 'status-active';
                break;
            case 'Ownership Requested':
                name = 'status-blocked';
                break;
            case 'Success':
            case 'Finished':
            case 'Confirming':
            case 'Confirmed':
            case 'Partially_paid':
            case 'Refunded':
            case 'Is Active':
            case 'Uploaded':

                name = 'status-success status-active';
                break;
            case 'Failed':
            case 'Expired':
            case 'Prepared':
            case 'Waiting':
            case 'Not Uploaded':
            case 'Canceled':
            case 'Rejected':
            case 'Inactive':
            case 'Error':

                name = 'status-success status-blocked';
                break;
            default:
                break;
        }
        setClassName(name);
    }, [label])
    return (
        <div>
            <div className={`status ${className}`}>
                {label}</div>
        </div>
    )
}
export default Status;
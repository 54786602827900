import TemplatePopup from "App/Components/UI/TemplatePopup"
import Youtube from "Assets/Images/Youtube.svg";
import Twitter from "Assets/Images/Twitter.svg";
import Instagram from "Assets/Images/Instagram.svg";
import { Fragment } from "react";
import User from "App/Components/UI/User";
import FormatField from "App/Components/UI/FormatField";

const ViewDetails = ({ show, free, onClose, row, onEdit, page }) => {
    const onClosePopup = () => {
        onClose(false)
    }
    return (
        <div>
            <TemplatePopup show={show} cssClass="gallery-view-popup" style={{ 'maxWidth': '65.9rem' }}>
                <div className="flex">
                    <div className="gallery-views-image">
                        <div className="justify-center items-center flex">
                            <User src={row?.user?.profilePicUrl} className="gallery-wallet-profile" />
                        </div>
                        <div className="pt-2 gallery-link">
                            <ul>Gallery By
                                <li className="name">
                                    {row?.user?.name}
                                </li>
                                <li>
                                    <a href={row?.user?.website} target="_blank" rel="noopener noreferrer">{row?.user?.website}</a>
                                </li>
                                <ul>
                                    Joined in
                                    <li> {new Date(row?.user?.createdAt).getFullYear()}</li>
                                </ul>

                                <ul>
                                    Place
                                    <li>
                                        {row?.user?.nationality}
                                    </li>
                                </ul>

                            </ul></div>
                    </div>
                    <div className="gallery-views gap-10">
                        <div className="flex justify-between">
                            <span>{row?.name} </span>
                            <div className="flex gap-10 items-center">
                                <button className="btn btn-close" onClick={onClosePopup}></button></div>
                        </div>
                        <div className="gallery-views-border cursor-pointer gap-3 flex py-4">
                            <div className="flex gap-2 pt-2">

                                <a href={row?.user?.instagram} target="_blank" rel="noopener noreferrer">  <img src={Instagram} alt="images" /></a>
                            </div>
                            <div className="flex gap-2 pt-2">

                                <a href={row?.user?.twitter} target="_blank" rel="noopener noreferrer"> <img src={Twitter} alt="images" /></a>
                            </div>
                            <div className="flex gap-2 pt-2">

                                <a href={row?.user?.youtube} target="_blank" rel="noopener noreferrer"> <img src={Youtube} alt="images" /></a>
                            </div>
                        </div>
                        <div className="gallery-views-full-card">
                            <div className="flex flex-wrap gap-5 py-4 justify-between">
                                <div className="gallery-views-card">
                                    <p>NFTs </p>
                                    <span>{row?.assetsCount}</span>
                                </div>
                                <div className="gallery-views-card">
                                    <p>Gallery style </p>
                                    <span>{row?.nftStyle?.name}</span>
                                </div>
                                <div className="gallery-views-card">
                                    <p>Gallery Type </p>
                                    <span>{row?.dropType?.name}</span>
                                </div>

                                {page &&
                                    <Fragment>
                                        <div className="gallery-views-card">
                                            <p>Start Date</p>
                                            <span>
                                                <FormatField type="date" value={row?.nftDropStartedAt} />
                                            </span>
                                        </div>
                                        <div className="gallery-views-card">
                                            <p>Type of Drop</p>
                                            <span>{row?.dropType?.name}</span>
                                        </div>
                                        <div className="gallery-views-card">
                                            <p>Price </p>
                                            <span> ₳{row?.price} </span>
                                        </div>
                                        <div className="gallery-views-card desc">
                                            <p>Mining Link</p>
                                            <span> www.mininglinik.com/mining/user/martin/12345678864</span>
                                        </div></Fragment>
                                }
                                <div className="gallery-views-card desc">
                                    <p>Hashtags </p>
                                    <span>
                                        {row?.hashtags?.map(((name, index) => <span key={index}>{(index ? ', ' : '') + `#` + name}</span>))}
                                    </span>
                                </div>
                            </div>

                            <p className="gallery-views-value">Description</p>
                            <div className="gallery-views-desc pt-1">{row?.description}</div>

                            </div>
                        </div>
                </div>
            </TemplatePopup>
        </div >
    )
}

export default ViewDetails
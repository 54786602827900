import Button from "App/Components/UI/Button";
import InputBoxLogin from "App/Components/UI/Forms/InputBoxLogin";
import LinkButton from "App/Components/UI/LinkButton";
import { useStore } from "App/hooks-store/store";
import useAuth from "App/hooks/use-auth";
import useHttp from "App/hooks/use-http";
import storage from "App/helpers/storage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";

const Login = React.memo(() => {
    const validatorsList = useMemo(() => ([{
        type: 'Required',
        message: 'Required field'
    }, {
        type: 'Email',
        message: 'Invalid Email'
    }]), [])
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        email: {
            value: undefined,
            isRequired: true
        },
        password: {
            value: undefined,
            isRequired: true
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup));
    let navigate = useNavigate();
    const dispatch = useStore(false)[1];
    const token = useAuth()[0];
    const goToDashboard = useCallback(() => {
        navigate("/dashboard", { replace: true });
    }, [navigate])
    const apiResult = (res) => {
        const { message, data } = res;
        const tokenStore = storage();
        tokenStore.set('token', data.token);
        tokenStore.set("menu",data?.permissions)
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        goToDashboard();
    }
    const { triggerAPI: loginAPI } = useHttp();
    const loginHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            dispatch('showSpinner');
            loginAPI({
                url: 'admin/login', data: formValues, method: 'post'
            }, apiResult);
        } else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    useEffect(() => {
        if (token) {
            goToDashboard();
        }
    }, [goToDashboard, token])
    return (
        <div className="login-layout-wrapper">
            <h1 className="login-layout-title mb-12">Login</h1>
            <div className="login-layout-row">
                <InputBoxLogin label="Username" type="text" validateOnBlur controlName="email" onChange={inputOnChange} config={{ hasUserIcon: true }} />
                <Validators value={formValues.email} controlName="email" onErrors={collectErrors} validators={validatorsList} />
            </div>
            <div className="login-layout-row mt-6">
                <InputBoxLogin label="Password" type="password" validateOnBlur controlName="password" onChange={inputOnChange} config={{ hasEyeIcon: true }} />
                <Validators type="Required" value={formValues.password} message="Required field" controlName="password" onErrors={collectErrors} />
            </div>
            <div className="login-layout-row mt-3 text-right">
                <LinkButton label="Forgot Password?" route="/forgot-password" />
            </div>
            <div className="login-layout-row mt-6">
                <Button className="btn-login" label="Login" onClick={loginHandler} />
            </div>
        </div>
    )
})
export default Login;
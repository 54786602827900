import React, { Fragment } from "react";
import Public from "./Public";
import Private from "./Private";
const RoutesMain = React.memo(() => {
    return (
        <Fragment>
            <Public />
            <Private />
        </Fragment>
    )
})
export default RoutesMain;
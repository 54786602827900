import { NavLink } from "react-router-dom";

const PageTab = ({ tabs = []}) => {
    return (
        <div className="tab-page">
            <ul>
                {
                    tabs.map(tab =>
                        <li key={tab.label}>
                            <NavLink className={({ isActive }) => (isActive ? 'tab-page-active' : '')} to={tab.link}>
                                {tab.label}
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}
export default PageTab;
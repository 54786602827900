import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userAvatarHover from "Assets/Icons/avatar_hover.svg";
import "./EditCollection.scss";
import FormatField from "App/Components/UI/FormatField";
import Button from "App/Components/UI/Button";
import ReloadIcon from 'Assets/Icons/ReloadIcon.svg';
import SkeletonLoader from "App/Components/UI/SkeletonLoader";

import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { categorySort, copyClipHandler, objectToFormdata } from "App/helpers/utilities";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import TextArea from "App/Components/UI/TextArea";
import SelectBox from "App/Components/UI/SelectBox";
import TermsTable from "../../Components/TermsTable";
import TermsConditions from "../../Components/TermsConditions";
import dummyImage from "Assets/Images/dummypic.svg";
import copyIcon from "Assets/Icons/copy_icon.svg";
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
} from "App/helpers/file";
import useShapes from "App/hooks/use-shapes";
import MetricsData from "./SubComponents/MetricsData";
import closeIcon from "Assets/Icons/close.svg";
import PiecesList from "./SubComponents/PiecesList";
import PieceDetailsView from "./SubComponents/PieceDetailsView";
import { getBlockchainIcon } from "App/helpers/blockchain";
import CreatorCard from "App/Components/UI/CreatorCard";

const EditCollection = (props) => {
  const formGroup = useMemo(() => {
    return {
      name: {
        value: undefined,
        isRequired: false,
      },
      blockchainTypeId: {
        value: undefined,
        isRequired: true,
      },
      collectionId: {
        value: undefined,
        isRequired: true,
      },
      description: {
        value: undefined,
        isRequired: false,
      },
      categoryId: {
        value: undefined,
        isRequired: false,
      },
      firstSubcategoryId: {
        value: undefined,
        isRequired: true,
      },
      // secondSubcategoryId: {
      //   value: undefined,
      //   isRequired: false,
      // },
      communityId: {
        value: undefined,
        isRequired: false,
      },
      marketplace: {
        value: undefined,
        isRequired: false,
      },
      instagram: {
        value: undefined,
        isRequired: false,
      },
      twitter: {
        value: undefined,
        isRequired: false,
      },
      discord: {
        value: undefined,
        isRequired: false,
      },
      email: {
        value: undefined,
        isRequired: false,
      },
      youtube: {
        value: undefined,
        isRequired: false,
      },
      website: {
        value: undefined,
        isRequired: false,
      },
      termsId: {
        value: undefined,
        isRequired: false,
      },
      category: {
        value: undefined,
        isRequired: false,
      },
      firstSubcategory: {
        value: undefined,
        isRequired: false,
      },
      secondSubcategory: {
        value: undefined,
        isRequired: false,
      },
      file: {
        value: undefined,
        isRequired: false,
      },
      collectionStorageId: {
        value: undefined,
        isRequired: true,
      },
      collectionEditionsId: {
        value: undefined,
        isRequired: true,
      },
      collectionFormatIds: {
        value: [],
        isRequired: true,
      },
      contractAddress: {
        value: [],
        isRequired: true,
      },
      collectionTypeIds: {
        value: [],
        isRequired: true,
      },
      collectionTagIds: {
        value: [],
        isRequired: false,
      },
      primaryMintUrl: {
        value: undefined,
        isRequired: false,
      },
    }
  }, []);
  const { state, search } = useLocation()
  const id = search?.split('?')[1];
  const isEditPage = !!id;
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const [details, setDetails] = useState({});
  const [blockchainTypeList, setBlockchainTypeList] = useState([]);
  const [formValues, setFormvalues] = useState(getInitial(formGroup));
  const [formVaildState, setFormVaildState] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [collectionImageLoading, setCollectionImageLoading] = useState(true);
  const [displayPicture, setDisplayPicture] = useState(null);
  const [collagePictureLoading, setCollagePictureLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [collagePicture, setCollagePicture] = useState(null);
  const [category, setCategory] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [firstSubCategory, setFirstSubCategory] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [sampleAssetData, setSampleAssetData] = useState({});
  const [collectionStorageList, setCollectionStorageList] = useState([]);
  const [collectionEditionsList, setCollectionEditionsList] = useState([]);
  const [collectionFormatList, setCollectionFormatList] = useState([]);
  const [collectionTypeList, setCollectionTypeList] = useState([]);
  const [collectionTagList, setCollectionTagList] = useState([]);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [pieceDetails, setPieceDetails] = useState(null);
  // const isVerfifyPage = pathname?.includes('verification');
  const isVerfifyPage = props?.isVerifyCollection;
  const isTemporary = state?.temporary;
  const [metricsData, setMetricsData] = useState(null);
  const [shapes, setShapes] = useState(null);
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [creatorScreenshotInfo, setCreatorScreenshotInfo] = useState(null);
  const [screenshotTaken, setScreenshotTaken] = useState(false);
  const [dataFetched, setDataFetched] = useState({
    category: false,
    data: false,
    communityList: false
  });
  const [nsfwValue, setNsfwValue] = useState('no');
  const [blockchainData, setBlockchainData] = useState(null);
  const [tab, setTab] = useState('details');
  const profileWrapRef = useRef(null);
  const navigate = useNavigate();
  const { getShapes } = useShapes();

  const getCategoryResponse = useCallback(
    (res) => {
      let updatedList=categorySort(res?.data?.list);
      setCategory(updatedList);
      setDataFetched((prev) => {
        return { ...prev, category: true };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getCategoryResponse, triggerAPI]);

  const getTermsResponse = useCallback(
    (res) => {
      setTermsList(res?.data?.list.sort((a, b) => a.id - b.id));
    },
    []
  );

  const getTermsList = useCallback(() => {
    triggerAPI(
      {
        url: `community/collection-terms/get`,
        data: {
          page: 1,
          items: 100,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getTermsResponse
    );
  }, [getTermsResponse, triggerAPI]);

  const getCollectionStorageResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionStorageList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionStorage = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_storage/get`,
        method: "get",
      },
      getCollectionStorageResponse
    );
  }, [dispatch, getCollectionStorageResponse, triggerAPI]);

  const getCollectionTypeResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionTypeList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionType = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_type/get`,
        method: "get",
      },
      getCollectionTypeResponse
    );
  }, [dispatch, getCollectionTypeResponse, triggerAPI]);

  const getCollectionTagResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionTagList(res?.data?.list.sort((a,b) => a.name > b.name ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionTag = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_tag/get`,
        method: "get",
      },
      getCollectionTagResponse
    );
  }, [dispatch, getCollectionTagResponse, triggerAPI]);

  const getCollectionFormatResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");      
      setCollectionFormatList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionFormat = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_format/get`,
        method: "get",
      },
      getCollectionFormatResponse
    );
  }, [dispatch, getCollectionFormatResponse, triggerAPI]);

  const getCollectionEditionsResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      setCollectionEditionsList(res?.data?.list.sort((a,b) => a.displayOrder > b.displayOrder ? 1:-1));
    },
    [dispatch]
  );
  
  const getCollectionEditions = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection_editions/get`,
        method: "get",
      },
      getCollectionEditionsResponse
    );
  }, [dispatch, getCollectionEditionsResponse, triggerAPI]);

  const getBlockChainTypeResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const list = res?.data?.rows?.map((item) => {
        return { name: item?.name, id: item?.id, value: item?.id };
      });
      setBlockchainTypeList(list);
    },
    [dispatch]
  );

  const getBlockChainType = useCallback(() => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/blockchain-types/get`,
        method: "get",
      },
      getBlockChainTypeResponse
    );
  }, [dispatch, getBlockChainTypeResponse, triggerAPI]);

  useEffect(() => {
    getCategoryList();
    getTermsList();
    getCollectionStorage();
    getCollectionEditions();
    getCollectionFormat();
    getCollectionType();
    getCollectionTag();
    getBlockChainType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subCategoryHandler = useCallback( async (cId) => {
      let categoryTemp = category;
      const subCatgry = (await categoryTemp?.find((val) => val?.id === cId)?.subcategories) || [];
      setFirstSubCategory(subCatgry);
      return subCatgry;
  }, [category] );

  const secondSubCategoryHandler = useCallback(
    async (cId, keyName = "id", subCat) => {
      let subArr = subCat ?? firstSubCategory;
      const secondSub =
        (await subArr?.find((val) => val[keyName] === cId)
          ?.subcategories) || [];
      return secondSub;
    },
    [firstSubCategory]
  );
  useEffect(() => {
    if (dataFetched?.data && dataFetched?.category) {
      updateHandler(formValues);
    }      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);
  
  const updateHandler = useCallback(async (val) => {
    let data = val ?? formValues;
    const subCategory = await subCategoryHandler(data?.categoryId);
      const categoryVal = category?.find(
        (val) => val?.id === data?.categoryId
      )?.name;
      const firstSubcategoryVal = subCategory?.find(
        (val) => val?.id === data?.firstSubcategoryId
      )?.name;
      
      const cateData = {
        category: categoryVal,
        firstSubcategory: firstSubcategoryVal,
        // secondSubcategory: secondSubcategoryVal,
      };
    setFormvalues((prev) => {
      return { ...prev, ...cateData };
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    category,
      formValues?.categoryId,
      formValues?.firstSubcategoryId,
      // formValues?.secondSubcategoryId,
  ]);

  // useEffect(() => {
  //   if (
  //     dataFetched?.category &&
  //     dataFetched?.data &&
  //     category &&
  //     formValues?.categoryId
  //   ) {
  //     updateHandler();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   dataFetched,
  //   category,
  //   formValues?.categoryId,
  //   formValues?.firstSubcategoryId,
  //   formValues?.secondSubcategoryId,
  //   formValues?.category,
  //   formValues?.firstSubcategory,
  //   formValues?.secondSubcategory,
  // ]);

  useEffect(() => {
    if (touched) {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    setIsValid(valid);
  }, [formValues, formVaildState, formGroup]);

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);
  const makeFirstSubcategoryIdEmpty = () => {
    setTimeout(()=>{
      setFormvalues((prev) => {
        return {
          ...prev,
          firstSubcategoryId: '',
        };
      });
    },200)
  }
  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
    if (Object.keys(e)[0] === "categoryId") {
      subCategoryHandler(e?.categoryId);
      makeFirstSubcategoryIdEmpty()
    } else if (Object.keys(e)[0] === "firstSubcategoryId") {
      secondSubCategoryHandler(e?.firstSubcategoryId);
    }
  };
  useEffect(() => {
    if(screenshotTaken)
      sucessResponse({message: 'Community collection updated'})
    return () => {
      setScreenshotTaken(false)
      setCreatorScreenshotInfo(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenshotTaken])
  const sucessResponse = (res) => {
    props.onRefetchList && props.onRefetchList();
    gotToBack();
    dispatch("hideSpinner");
    dispatch("showToast", {
      toast: { toastMode: "success", message: res?.message },
    });
  }
  const getUpdateResult = useCallback(
    (res) => {      
      
      const { data = null } = res;
      if(!!data?.id) //community id
        setCreatorScreenshotInfo({
          blockChains: data?.blockchain,
          coverImage: data?.bannerPicture,
          profileImage: data?.displayPicture256,
          collectionList: data?.collectionFilePath256,
          creatorName: data?.name,
          id: data?.id,
        })
      else 
        sucessResponse(res);
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, navigate]
  );

  const actionHandler = (list) => {
    const postUrl = isTemporary ? `community/collection/temp/update/${id}` : `community/collection/update/${id}`;
    const method = `put`;
    delete list.category;
    delete list.firstSubcategory;
    delete list.secondSubcategory;
    if (typeof list.file === "string") delete list.file;
    if (list.communityId === 0) { list.communityId = null}
    if (list.collectionId){ list.collectionId = [list.collectionId]}

    const formData = objectToFormdata(list);
    triggerAPI(
      {
        url: postUrl,
        method: method,
        data: formData,
      },
      getUpdateResult
    );
  };

  const actionHandlerNew = (list) => {
    const postUrl = isTemporary ? `community/collection/temp/update/${id}` : `community/collection/update/${id}`;
    const method = `put`;
    delete list.category;
    delete list.firstSubcategory;
    delete list.secondSubcategory;
    if (typeof list.file === "string") delete list.file;
    if (list.communityId === 0){ list.communityId = null}

    const formData = objectToFormdata(list);
    triggerAPI(
      {
        url: postUrl,
        method: method,
        data: formData,
      },
      updateStatusHandler,
    );
  }

  const updateStatusHandler = (collectionRes) => {
    triggerAPI(
      {
        url: isTemporary ? 'community/collection/temp/update-status' : 'community/collection/update-status',
        data: {
          id,
          status: 'active',
        },
        method: 'patch',
      },
      (res) => {
        const { message, data } = res;
        getUpdateResult({message, data: {...data?.community}})
      },
    );
  }

  const saveHandler = useCallback(() => {
    if (isValid) {
      dispatch("showSpinner");
      const list = {...formValues, collage: collagePicture, isNsfw: nsfwValue === 'yes' ? true : false}
      delete list.newtag;
      if(isVerfifyPage) {
        actionHandlerNew(list);
      } else {
        actionHandler(list);
      }
      // if (
      //   (list?.category && !list?.categoryId) ||
      //   (list?.firstSubcategory && !list?.firstSubcategoryId) ||
      //   (list?.secondSubcategory && !list?.secondSubcategoryId)
      // ) {
      //   if (list?.category && !list?.categoryId) {
      //     triggerAPI(
      //       {
      //         url: `category/create`,
      //         data: { name: list?.category },
      //         method: "post",
      //       },
      //       (res) => {
      //         list.categoryId = res?.data?.id;
      //         inputOnChange({ categoryId: res?.data?.id });
      //         createSubCategoryHandler(list);
      //       }
      //     );
      //   } else {
      //     createSubCategoryHandler(list);
      //   }
      // } else {
      //   actionHandler(list);
      // }
    } else {
      setTouched(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formValues, getUpdateResult, isValid, triggerAPI]);

  const imageHandle = (imgFile) => {
    if (imgFile) {
      const file = imgFile?.displayPicture;
      const fileSize = file.size / 1024 / 1024;
      const extension = file?.name.split(".").pop();
      const fileExtension = extension.toLowerCase();
      if (
        fileExtension === "png" ||
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "gif" ||
        fileExtension === "webp" ||
        fileExtension === "svg"
      ) {
        if (fileSize <= 2) {
          const imageUrl = URL.createObjectURL(file);
          if (imgFile?.displayPicture) {
            inputOnChange({ file: file });
            setDisplayPicture(imageUrl);
          }
        } else {
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: "The maximum size of an image should be 2 MB",
            },
          });
        }
      } else {
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message: "Image format should be png, jpeg, jpg, gif, svg or webp",
          },
        });
      }
    }
  };
  const handleCollectionStorage = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionStorageId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionStorageId: ids });
  };
  const handleCollectionEdition = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids;
    if (formValues?.collectionEditionsId === Number(id)) {
      ids = null;
    } else {
      ids = Number(id);
    }
    inputOnChange({ collectionEditionsId: ids });
  };
  const handleCollectionFormat = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids = [...formValues?.collectionFormatIds];
    if (ids.includes(Number(id))) {
      ids.splice(formValues?.collectionFormatIds.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }    
    inputOnChange({ collectionFormatIds: ids });
  };
  const handleCollectionType = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids = [...formValues?.collectionTypeIds];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }
    inputOnChange({ collectionTypeIds: ids });
  };
  const handleCollectionTag = (e) => {
    const id = e?.currentTarget?.dataset?.id;
    let ids = [...formValues?.collectionTagIds];
    if (ids.includes(Number(id))) {
      ids.splice(ids.indexOf(Number(id)),1);
    } else {
      ids.push(Number(id));      
    }
    inputOnChange({ collectionTagIds: ids });
  };
  
  const saveNewTagResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      getCollectionTag();
      inputOnChange({ newtag: "" });      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveNewTag = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/collection_tag/create",
          data,
          method: "post",
        },
        saveNewTagResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: "Tag creation failed" },
          });
        }
      );
    },
    [dispatch, saveNewTagResult, triggerAPI]
  );

  const handleNewCollectionTag = (e) => {    
    let data = { name: formValues.newtag }
    saveNewTag(data);
  };
  const handleTerms = (e) => {
    if(!isEditPage) {
      const id = e?.currentTarget?.dataset?.id;
      let value = parseInt(id);
      if (formValues?.termsId === value) {
        value = null;
      }
      inputOnChange({ termsId: value });
    }   
  };

  useEffect(() => {
    if (id) {
      getDetailedCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getDetailedCollectionResult = useCallback(
    (res) => {
      const { data } = res;
      let details = { ...data };
      let ratios = details?.shapes?.length ? details?.shapes.map(shape => Number(shape.aspectRatio)) : details?.aspectRatios?.split(','); 
      setShapes(getShapes(ratios));
      setBlockchainData(details?.blockchainType)
      setMetricsData({...data});
      let newDetails = {
        name: data?.name ?? "",
        description: data?.description ?? "",
        blockchainTypeId: data?.blockchainTypeId,
        collectionId: data?.collectionId,
        website: data?.website ?? "",
        twitter: data?.twitter ?? "",
        instagram: data?.instagram ?? "",
        discord: data?.discord ?? "",
        youtube: data?.youtube ?? "",
        email: data?.email ?? "",
        communityId: data?.communityId ?? 0,
        categoryId: data?.categoryId ?? "",
        marketplace: data?.marketplace ?? "",
        firstSubcategoryId: data?.firstSubcategoryId ?? "",
        // secondSubcategoryId: data?.secondSubcategoryId ?? "",
        collectionStorageId: data?.collectionStorageId,
        collectionEditionsId: data?.collectionEditionsId,
        collectionFormatIds: data?.formats?.map(f=>f.id),
        collectionTypeIds: data?.types?.map(t=>t.id),
        collectionTagIds: data?.tags?.map(t=>t.id),
        termsId: data?.termsId ?? "",
        file: data?.filePath ?? "",
        isNsfw : data?.isNsfw,
        contractAddress: [data?.contractAddress],
        primaryMintUrl: data?.primaryMintUrl
      }
      setFormvalues(newDetails);
      setNsfwValue(()=>data?.isNsfw ? 'yes': 'no')
      setDataFetched((prev) => {
        return { ...prev, data: true };
      });
      setDisplayPicture(data?.filePath);
      setCollagePicture(data?.collage);
      data.mintDate = dateValidate(data.mintDate);
      data.lockDate = dateValidate(data.lockDate);
      setDetails(data);
      let assets = data?.assets?.slice(1, 5); //don't include the first asset, its usually not an actual NFT
      if(assets?.length) {
          setSampleAssetData(assets[0] ?? data?.assets[0] ?? {});
      } else {
          setSampleAssetData(data?.assets?.length ? data?.assets[0] : {});
      }
      setCollectionInfo({assets:data?.assets, collectionId: data?.collectionId})
      dispatch("hideSpinner");
      setCollectionLoading(false)
      setCollectionImageLoading(false);
      setCollagePictureLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const dateValidate = useCallback((date) => {
    const dateValue = new Date(date);
    if (isNaN(dateValue)) {
      return '';
    } else {
      return dateValue.toISOString();
    }
  }, [])
  const getDetailedCollection = useCallback(() => {
    dispatch("showSpinner");
    if (isTemporary)
    {
      triggerAPI(
        {
          url: 'community/collection/temp/get-one',
          data: {
            id: Number(id),
          },
          method: "post",
        },
        getDetailedCollectionResult
      );
    }
    else
    {
      const params = new URLSearchParams({
       id
      });        
      triggerAPI(
        {
          url: `community/collection/get-one?${params}`,            
          method: "get",
        },
        getDetailedCollectionResult
      );
    }        
  }, [dispatch, isTemporary, triggerAPI, id, getDetailedCollectionResult]);
  useEffect(() => {
    if (details?.createdBy || details?.user?.id) getCommunityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.createdBy, details?.user?.id]);

  useEffect(() => {
    if(dataFetched?.communityList && dataFetched?.data) {
      const tempList = communityList?.filter((list => list?.id === formValues?.communityId))
      setCommunityList(tempList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);
  const getCommunityListResult = useCallback(
    (res) => {
      const { data } = res;
      setCommunityList(data?.list);
      setDataFetched((prev) => {
        return { ...prev, communityList: true };
      });
    },
    []
  );
  const getCommunityList = useCallback(() => {
    triggerAPI(
      {
        url: `community/get`,
        method: "post",
        data: {
          page: 1,
          items: 100,
          filter: {},
          search: "",
        },
      },
      getCommunityListResult
    );
  }, [getCommunityListResult, triggerAPI]);

  const gotToBack = () => {
    if(props?.onClosePopup){
      props?.onClosePopup();
    }else{
      navigate(-1);
    }
  };

  const copytoClip = (e) => {
    const { id } = e.currentTarget.dataset;
    copyClipHandler(id, (res) => {
      res &&
        dispatch("showToast", {
          toast: { toastMode: "success", message: "Copied to clipboard" },
        });
    });
  };
  
  const reFetchCollectionResult = useCallback((res) => {
    const { data } = res;
    setDetails(prev => {return {...prev, 
      ownerCount: data?.ownerCount,
      mintDate: dateValidate(data?.mintDate),
      lockDate: dateValidate(data?.lockDate),
      assetCount: data?.assetCount      
    }});
    
    setMetricsData(prev => {       
      if (!metricsData?.metrics?.length)
      {
        metricsData?.metrics.push({});
      }
      prev.metrics[0].supply = data?.assetCount;
      prev.metrics[0].owners = data?.ownerCount;
      return prev;      
    });
        
    setDisplayPicture(data?.filePath);
    setCollagePicture(data?.collage);
    setCollectionLoading(false)
    setCollectionImageLoading(false);
    setCollagePictureLoading(false);
    setReloading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[metricsData]);
  const reFetchCollectionData = (overrideImage) => {
    if (overrideImage)
    {
      // setCollectionImageLoading(true);
      setCollagePictureLoading(true);
      setReloading(true);
    }
    else
    {
      setCollectionLoading(true);
    }    
    triggerAPI(
      {
        url: `user/nft-collection/collectiondata`,
        method: "post",
        data: {
          id,
          networkType: "MAINNET",
          assetName: sampleAssetData?.asset_name,
          isTemporary,
          overrideImage,
          includeCounts:!overrideImage
        }
      },
      reFetchCollectionResult
    );
  }  

  const menuTabs = useMemo(()=>{
    let tabList= [{ id: "details", element: 'Details'},{ id: "pieces", element: 'Pieces' }]
    return tabList
  },[]);

  const tabsHandler = (selectTab) => {
      setTab(selectTab);
  }

  const handleDetailsPopup = (piece) => {
    const collectionId = <div className="">
      {!!details?.collectionId && (
        <div className="content flex items-center gap-[0.6rem] !text-[1rem]">
          {!!blockchainData && <img
            className="w-[1.3rem]"
            alt="Img"
            src={getBlockchainIcon(blockchainData?.code)}
          />}
          <p className="mt-1">
            {details?.collectionId?.length < 11
              ? details?.collectionId
              : details?.collectionId?.slice(0, 4) +
              "..." +
              details?.collectionId?.slice(-4)}
          </p>
          <img
            onClick={copytoClip}
            data-id={details?.collectionId}
            className="cursor-pointer w-4"
            alt="Img"
            src={copyIcon}
          />
        </div>
      )}
    </div>
    setPieceDetails({piece, community: communityList?.length ? communityList[0]: null, collectionId});
    setDetailsPopup(true);
}

  return (
    <div className="edit-collection mb-3">
      <div className="w-full flex items-center justify-between px-[1rem]" >
        <span />
        <h2 className="!text-[1rem]">{isVerfifyPage ? 'Verify' : 'Edit'} Collection</h2>
        <button onClick={gotToBack}><img src={closeIcon} alt="close" /></button>
      </div>
      <div className="tab-container-details">
        <div className="tab-data">
          {menuTabs?.map((menu) => {
            return (
              <div
                onClick={() => tabsHandler(menu?.id)}
                key={menu?.id}
                className={`tab ${menu?.id === tab ? "active" : formValues?.collectionId ? "not-active" : "cursor-not-allowed"}`}
              >
                {menu?.element}{" "}
              </div>
            );
          })}
        </div>
      </div>
      {tab==="details" ? <>
      <div className="details-main-container">

          <div className="flex items-center gap-[4rem]">
            <h2 className="!text-[1rem]">{details?.name}</h2>
            {!!details?.collectionId && (
              <div className="content flex items-center gap-[0.6rem] !text-[1rem]">
                {!!blockchainData && <img
                    className="w-[1.3rem]"
                    alt="Img"
                    src={getBlockchainIcon(blockchainData?.code)}
                  />}
                <p className="mt-1">
                  {details?.contractAddress?.length < 11
                    ? details?.contractAddress
                    : details?.contractAddress?.slice(0, 4) +
                    "..." +
                    details?.contractAddress?.slice(-4)}
                </p>
                <img
                  onClick={copytoClip}
                  data-id={details?.contractAddress}
                  className="cursor-pointer w-4"
                  alt="Img"
                  src={copyIcon}
                />
              </div>
            )}
          </div>

          <MetricsData data={metricsData} shapes={shapes}/>

          <div className="flex items-center gap-[1rem] mt-[1rem]">
            <div className="thumbnail_image flex flex-col gap-2">
              <p className="label-text">Thumbnail</p>
              <div
                className="upload-banner-image profile-img displayPictureCollection"
                ref={profileWrapRef}
                onDrop={(e) =>
                  handleDrop(
                    e,
                    profileWrapRef,
                    imageHandle,
                    "displayPicture",
                    "pro-pic"
                  )
                }
                id="pro-pic"
                onDragOver={handleDragOver}
                onDragEnter={(e) =>
                  handleDragEnter(e, profileWrapRef, "pro-pic")
                }
                onDragLeave={(e) =>
                  handleDragLeave(e, profileWrapRef, "pro-pic")
                }
              >
                <div
                  className={"relative flex h-full flex-col justify-center"}
                >
                  <label
                    className="cursor-pointer"
                    htmlFor="upload-button-displayPicture"
                  >

                    {collectionImageLoading ?
                      <SkeletonLoader /> :
                      <img
                        alt="No Img"
                        src={userAvatarHover}
                        className={`upload-icon`}
                      />
                    }
                    {!collectionImageLoading && !displayPicture && (
                      <div className="dummy-wrap">
                        <img
                          className={`image-uploaded`}
                          alt="NoImg"
                          src={dummyImage}
                        />
                        <p>
                          Maximum file <br />
                          size 2MB <br />
                          Minimum size <br />
                          92px x 92px <br />
                          1:1
                        </p>
                      </div>
                    )}
                    {!collectionImageLoading && displayPicture && (
                      <img
                        alt="No Img"
                        className={`image-uploaded actual-img`}
                        src={displayPicture}
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    id="upload-button-displayPicture"
                    name={"displayPicture"}
                    className={"hidden"}
                    accept="image/*"
                    onChange={(e) =>
                      imageHandle({ displayPicture: e.target.files[0] })
                    }
                  />
                </div>
              </div>
            </div> 

            <div className="thumbnail_image flex flex-col gap-2">
              <div className="flex items-center gap-4">
                <p className="label-text">Collage</p>
                <div className="flex justify-end cursor-pointer reload-section">
                  <img src={ReloadIcon} className={`${reloading && `animate-spin`} ${reloading || collagePictureLoading ? 'cursor-not-allowed' : ''}`} alt="" onClick={(e) =>{
                    if(reloading || collagePictureLoading) return;
                     reFetchCollectionData(true);
                     }} />
                </div>
              </div>
              <div
                className="upload-banner-image profile-img displayPictureCollection">
                {!collagePictureLoading && collagePicture ? (
                  <img
                    alt="No Img"
                    className={`image-uploaded-collage`}
                    src={collagePicture}
                  />)
                  :
                  collagePictureLoading ? <SkeletonLoader />
                    :
                    <div className="h-full w- full flex justify-center items-center">
                      <p className="inline-block w-1/2 text-center text-gray-300">
                        Click the reload button to generate collage image
                      </p>
                    </div>}
              </div>
            </div>

          </div>


      <div>
        <div className="banner flex gap-2 mt-3">
          <div className="details-section">
            <div className="flex justify-end cursor-pointer reload-section">
              <img src={ReloadIcon} alt="" onClick={(e)=>reFetchCollectionData(false)} />
            </div>
            <div className="details-box">
              <p className="title">Collection</p>
              <p className="content">{details?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Blockchain</p>
              <p className="content">{details?.blockchainType?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Collection ID</p>
              {!!details?.collectionId && (
                <p className="content flex">
                  {details?.collectionId?.length < 11
                    ? details?.collectionId
                    : details?.collectionId?.slice(0, 4) +
                      "..." +
                      details?.collectionId?.slice(-4)}
                  <img
                    onClick={copytoClip}
                    data-id={details?.collectionId}
                    className="cursor-pointer w-4 ml-2.5"
                    alt="Img"
                    src={copyIcon}
                  />
                </p>
              )}
            </div>
            {!!details?.contractAddress && (<div className="details-box">
              <p className="title">Contract Address</p>
              
                <p className="content flex">
                  {details?.contractAddress?.length < 11
                    ? details?.contractAddress
                    : details?.contractAddress?.slice(0, 4) +
                      "..." +
                      details?.contractAddress?.slice(-4)}
                  <img
                    onClick={copytoClip}
                    data-id={details?.contractAddress}
                    className="cursor-pointer w-4 ml-2.5"
                    alt="Img"
                    src={copyIcon}
                  />
                </p>              
            </div>)}
            <div className="details-box">
              <p className="title">Items</p>
              <p className="content">{collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : details?.assetCount}</p>
            </div>
            <div className="details-box">
              <p className="title">Owners</p>
              <p className="content">{collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : details?.ownerCount}</p>
                
            </div>
            <div className="details-box">
              <p className="title">Community</p>
              <p className="content">{details?.community?.name}</p>
            </div>
            <div className="details-box">
              <p className="title">Mint/Lock Date</p>
              <div className="content">
                {collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : (details?.mintDate && (
                  <FormatField
                    type="dayMonthYearDate"
                    value={details?.mintDate}
                  />
                ))}
                /
                {collectionLoading ? 
                <SkeletonLoader height={15} width={50} count={1} inline={true} /> : (details?.lockDate && (
                  <FormatField
                    type="dayMonthYearDate"
                    value={details?.lockDate}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="community-form collection text-left flex flex-col gap-4 mt-4">
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Collection ID</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  {/* <InputBox
                    maxlength={50}
                    label="Blockchain"
                    validateOnBlur
                    placeholder={"Cardano"}
                    type="text"
                    required={true}
                    onChange={inputOnChange}
                    controlName="blockchainTypeId"
                    value={formValues.blockchainTypeId}
                  /> */}
                  <SelectBox
                    validateOnBlur
                    list={blockchainTypeList}
                    required={false}
                    placeHolder="Select"
                    label="Blockchain"
                    keyName={"id"}
                    className="frame-form"
                    controlName="blockchainTypeId"
                    onChange={inputOnChange}
                    disabled={!!isEditPage}
                    currentId={formValues.blockchainTypeId}
                    tabIndex={0}
                  />
                  <Validators
                    type="Required"
                    value={formValues.blockchainTypeId}
                    message="Required field"
                    controlName="blockchainTypeId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    type="text"
                    placeholder={"Collection ID"}
                    label="Collection ID"
                    validateOnBlur
                    required={true}
                    onChange={inputOnChange}
                    controlName="collectionId"
                    value={formValues.collectionId}
                    disabled={!!isEditPage}
                  />
                  <Validators
                    type="Required"
                    value={formValues.collectionId}
                    message="Required field"
                    controlName="collectionId"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">
              Collection Details
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
             
              <div className="flex flex-col gap-4 w-full">
                <div className="flex sm:flex-row flex-col gap-2 ">
                  <div className="w-full">
                    <InputBox
                      maxlength={35}
                      type="text"
                      label="Name"
                      validateOnBlur
                      required={true}
                      onChange={inputOnChange}
                      controlName="name"
                      value={formValues.name}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <TextArea
                      placeholder={""}
                      label="Description"
                      validateOnBlur
                      type="text"
                      required={true}
                      rows={4}
                      onChange={inputOnChange}
                      maxLength={300}
                      showTextCount={true}
                      controlName="description"
                      value={formValues.description}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Categorization</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={category}
                    required={false}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Category"
                    className="frame-form"
                    controlName="categoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.categoryId}
                    tabIndex={0}
                  />
                </div>
                    <Validators
                      type="Required"
                      value={formValues?.categoryId}
                      message="Required field"
                      controlName="categoryId"
                      onErrors={collectErrors}
                    />
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={[...firstSubCategory]}
                    required={true}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Sub-Category"
                    className="frame-form"
                    controlName="firstSubcategoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.firstSubcategoryId}
                    tabIndex={0}
                  />
                </div>
                    <Validators
                      type="Required"
                      value={formValues?.firstSubcategoryId}
                      message="Required field"
                      controlName="firstSubcategoryId"
                      onErrors={collectErrors}
                    />
              </div>
            </div>
            {/* <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <SelectBox
                    validateOnBlur
                    list={[...secondSubCategory]}
                    required={false}
                    keyName={"id"}
                    placeHolder="Select"
                    label="Sub-Category-2"
                    className="frame-form"
                    controlName="secondSubcategoryId"
                    onChange={inputOnChange}
                    currentId={formValues?.secondSubcategoryId}
                    tabIndex={0}
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Formats (Choose all that apply)</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionFormatList?.map((format) => (
                  <span
                    key={format?.id}
                    className={
                      formValues?.collectionFormatIds?.includes(format?.id) ? `active` : ""
                    }
                    data-id={format?.id}
                    onClick={handleCollectionFormat}
                  >
                    {format?.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Types (Choose all that apply)</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionTypeList?.map((type) => (
                  <span
                    key={type?.id}
                    className={
                      formValues?.collectionTypeIds?.includes(type?.id) ? `active` : ""
                    }
                    data-id={type?.id}
                    onClick={handleCollectionType}
                  >
                    {type?.name}
                  </span>
                ))}
              </div>                  
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Storage</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionStorageList?.map((storage) => (
                  <span
                    key={storage?.id}
                    className={
                      formValues?.collectionStorageId === storage?.id ? `active` : ""
                    }
                    data-id={storage?.id}
                    onClick={handleCollectionStorage}
                  >
                    {storage?.name}
                  </span>
                ))}
              </div>
              <Validators
                type="Required"
                value={formValues?.collectionStorageId}
                message="Required field"
                controlName="collectionStorageId"
                onErrors={collectErrors}
              />
            </div>
          </div>
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Editions</label>
            </div>
            <div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionEditionsList?.map((edition) => (
                  <span
                    key={edition?.id}
                    className={
                      formValues?.collectionEditionsId === edition?.id ? `active` : ""
                    }
                    data-id={edition?.id}
                    onClick={handleCollectionEdition}
                  >
                    {edition?.name}
                  </span>
                ))}
              </div>
              <Validators
                type="Required"
                value={formValues?.collectionEditionsId}
                message="Required field"
                controlName="collectionEditionsId"
                onErrors={collectErrors}
              />
            </div>
          </div>        
              <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
                <div className="flex flex-col gap-2">
                  <label>NSFW</label>
                </div>
                <div className="flex items-center gap-[1rem] w-[min-content]">
                  <Button
                    label="No"
                    onClick={() => setNsfwValue('no')}
                    className={`common_button ${nsfwValue === 'no' && 'active'}`}
                  />
                  <Button
                    label="Yes"
                    onClick={() => setNsfwValue('yes')}
                    className={`common_button ${nsfwValue === 'yes' && 'active'}`}
                  />
                </div>
              </div>    
          <div className="flex w-full  flex-col sm:gap-2 gap-[0.375rem]">
            <div className="upload-banner flex flex-col gap-2">
              <label>Tags (Choose all that apply)</label>
            </div>
            <div>
              <div className={"flex mb-2"} style={{width:'20rem'}}>
                <InputBox                      
                  maxlength={100}
                  type="text"                                       
                  placeholder="Enter New Tag..."
                  onChange={inputOnChange}
                  width={10}  
                  controlName="newtag"    
                  value={formValues.newtag}                                                                            
                >
                </InputBox>                                           
                <Button
                  label="Save Tag"
                  onClick={handleNewCollectionTag}
                  className="transaction-cancel savetag"
                />
              </div>
              <div className="flex flex-wrap sm:gap-[1rem] gap-[0.5rem] items-center new-checkbox">
                {collectionTagList?.map((tag) => (
                  <span
                    key={tag?.id}
                    className={
                      formValues?.collectionTagIds?.includes(tag?.id) ? `active` : ""
                    }
                    data-id={tag?.id}
                    onClick={handleCollectionTag}
                  >
                    {tag?.name}
                  </span>
                ))}                    
              </div>                  
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Community*</div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div className="community-list-container flex gap-[1.42857rem] body flex-wrap">
                  {communityList?.map((community) => {
                    return (
                      <div
                        title={community?.name}
                        className={`item-container cursor-not-allowed ${
                          formValues.communityId === community?.id && "active"
                        }`}
                        data-id={community?.id}
                        key={community?.id}
                        // onClick={handleCommunity}
                      >
                        <div className="image-container">
                          <img src={community?.displayPicture} alt="" />
                        </div>
                        <div className="title-container py-[0.72rem]">
                          <span>{community?.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Socials</div>
          </div>
          <div className="flex flex-col w-full gap-4">
            <div className="flex w-full gap-4">
              <div className="flex w-full flex-col gap-2">
                <div>
                  <InputBox
                    placeholder="https://www.website.com"
                    label="Website"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="website"
                    value={formValues.website}
                  />
                  {/* Task: remove the URL conditions implemented on admin side2171 */}
                  {/* <Validators
                    type="url"
                    value={formValues.website}
                    message="URL not valid"
                    controlName="website"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    placeholder="username"
                    label="Twitter"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="twitter"
                    value={formValues.twitter}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.twitter}
                    message="URL not valid"
                    controlName="twitter"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Instagram"
                    placeholder="username"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="instagram"
                    value={formValues.instagram}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.instagram}
                    message="URL not valid"
                    controlName="instagram"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Discord"
                    placeholder="invitecode"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="discord"
                    value={formValues.discord}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.discord}
                    message="URL not valid"
                    controlName="discord"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Youtube"
                    placeholder="channel"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="youtube"
                    value={formValues.youtube}
                  />
                  {/* <Validators
                    type="url"
                    value={formValues.youtube}
                    message="URL not valid"
                    controlName="youtube"
                    onErrors={collectErrors}
                  /> */}
                </div>
              </div>
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Email"
                    placeholder="email@domain.com"
                    validateOnBlur
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="email"
                    value={formValues.email}
                  />
                  <Validators
                    type="Email"
                    value={formValues.email}
                    message="Email not valid"
                    controlName="email"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
            <TermsConditions/>
          <div
            className={`${isEditPage && "disabled"} flex flex-col w-full gap-4`}
          >
            <TermsTable
              termsList={termsList}
              handleTerms={handleTerms}
              values={formValues?.termsId}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="section-title brd-btm w-full">Marketplace Websites</div>
          </div>          
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Primary Mint URL"
                    validateOnBlur
                    placeholder="https://www.mintingsite.com/link"
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="primaryMintUrl"
                    value={formValues.primaryMintUrl}
                  />
                  <Validators
                    type="url"
                    value={formValues.primaryMintUrl}
                    message="URL not valid"
                    controlName="primaryMintUrl"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="flex w-full  flex-col gap-2">
                <div>
                  <InputBox
                    label="Secondary Trade URL"
                    validateOnBlur
                    placeholder="https://www.marketplace.com/link"
                    type="text"
                    required={false}
                    onChange={inputOnChange}
                    controlName="marketplace"
                    value={formValues.marketplace}
                  />
                  <Validators
                    type="url"
                    value={formValues.marketplace}
                    message="URL not valid"
                    controlName="marketplace"
                    onErrors={collectErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="flex flex-col gap-4 buttons_container">
          <div className="flex justify-center gap-4 pt-4">
            <Button
              label="Cancel"
              onClick={gotToBack}
              className="transaction-cancel"
            />
            <Button
              className={`transaction-ok ${!isValid ? "disable" : ""}`}
              label={isVerfifyPage ? 'Verify' :`Update`}
              onClick={saveHandler}
            />
          </div>
        </div>
      </>
      : 
      <>
        <PiecesList collectionInfo={collectionInfo} onSelectHandler={handleDetailsPopup}/>
      </>}

      {/* Piece Details Popup */}
      {detailsPopup && <PieceDetailsView
        onClosePopup={() => {
          setPieceDetails(null);
          setDetailsPopup(false);
        }}
        openPopup={detailsPopup}
        data={pieceDetails}
      /> }
      {creatorScreenshotInfo && <CreatorCard {...creatorScreenshotInfo} setScreenshotTaken={setScreenshotTaken} />}
    </div>
  );
};

export default EditCollection;

import Button from "App/Components/UI/Button";
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import SelectBox from "App/Components/UI/SelectBox";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
import {
  getInitial,
  markAsTouched,
  validateFormGroup,
} from "App/helpers/formValidation";
import { extractFormFieldKeys } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useMemo, useState } from "react";

function SubscriptionModal(props) {
  const [formVaildState, setFormVaildState] = useState({});
  const [formValues, setFormvalues] = useState({});
  const { triggerAPI } = useHttp();
  const dispatch = useStore(false)[1];
  const galleryTypeList = [
    {
      id: 1,
      name: "Basic",
      value: "Basic",
    },
    {
      id: 2,
      name: "All",
      value: "All",
    },
  ];
  const profileType = [
    {
      id: 1,
      name: "Yes",
      value: true,
    },
    {
      id: 2,
      name: "No",
      value: false,
    },
  ];
  const dropdownValues = [];

  for (let i = 1; i <= 12; i++) {
    dropdownValues.push({
      id: i,
      name: i,
      value: i,
    });
  }
  const formGroup = useMemo(() => {
    let list = {
      name: {
        value: undefined,
        isRequired: true,
      },
      planPrice: {
        value: undefined,
        isRequired: true,
      },
      regularPlanPrice: {
        value: undefined,
        isRequired: true,
      },
      requiredMonetHolding: {
        value: undefined,
        isRequired: true,
      },
      galleryType3D: {
        value: undefined,
        isRequired: true,
      },
      availableGalleries: {
        value: "0",
        isRequired: true,
      },
      walletLimit: {
        value: "0",
        isRequired: true,
      },
      availablePremiumFrame: {
        value: "0",
        isRequired: true,
      },
      devices: {
        value: undefined,
        isRequired: true,
      },
      anywallDisplayCount: {
        value: "0",
        isRequired: true,
      },
      discoverableProfile: {
        value: undefined,
        isRequired: true,
      },
      isAvailablePremiumWall: {
        value: false,
        isRequired: false,
      },

      availableCollections: {
        value: "0",
        isRequired: false,
      },
      mintRebate: {
        value: "0",
        isRequired: false,
      },
      mediaStorage: {
        value: "0",
        isRequired: false,
      },
    };
    setFormvalues(getInitial(list));
    return list;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.row]);

  const onClose = ({ mode = false }) => {
    props.triggerClose(mode);
  };

  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    onClose({ mode: true });
  };

  const formHandler = () => {
    const { valid } = validateFormGroup({
      errors: formVaildState,
      formGroup,
      values: formValues,
    });
    if (valid) {
      if (props.row?.id) {
        dispatch("showSpinner");
        let data = {
          name: formValues.name,
          planPrice: formValues.planPrice,
          regularPlanPrice: formValues.regularPlanPrice,
          requiredMonetHolding: formValues.requiredMonetHolding,
          galleryType3D: galleryTypeList.find(
            (g) => g.id === formValues.galleryType3D
          ).value,
          availableGalleries: formValues.availableGalleries,
          availablePremiumFrame: formValues.availablePremiumFrame,
          devices: formValues.devices,
          anywallDisplayCount: formValues.anywallDisplayCount,
          mediaStorage: formValues.mediaStorage,
          walletLimit:formValues?.walletLimit,
          isAvailablePremiumWall:!!formValues?.isAvailablePremiumWall,
          availableCollections:formValues?.availableCollections,
          mintRebate:formValues?.mintRebate,
          discoverableProfile: profileType.find(
            (p) => p.id === formValues.discoverableProfile
          ).value,
        };
        triggerAPI(
          {
            url: `subscription/${props?.row?.id}/update`,
            data,
            method: "patch",
          },
          apiResult,
          (err) => {
            dispatch("hideSpinner");
            dispatch("showToast", {
              toast: {
                toastMode: "error",
                message:
                  err?.response?.data?.error?.message ||
                  "Sub admin updation failed",
              },
            });
          }
        );
      } else {
        dispatch("showSpinner");
        let data = {
          name: formValues.name,
          planPrice: formValues.planPrice,
          regularPlanPrice: formValues.planPrice,
          requiredMonetHolding: formValues.requiredMonetHolding,
          galleryType3D: galleryTypeList.find(
            (g) => g.id === formValues.galleryType3D
          ).value,
          availableGalleries: formValues.availableGalleries,
          availablePremiumFrame: formValues.availablePremiumFrame,
          devices: formValues.devices,
          anywallDisplayCount: formValues.anywallDisplayCount,
          mediaStorage: formValues.mediaStorage,
          walletLimit:formValues?.walletLimit,
          availableCollections:formValues?.availableCollections,
          mintRebate:formValues?.mintRebate,
          discoverableProfile: profileType.find(
            (p) => p.id === formValues.discoverableProfile
          ).value,
        };
        triggerAPI(
          {
            url: "subscription/add",
            data: data,
            method: "post",
          },
          apiResult,
          (err) => {
            dispatch("hideSpinner");
            dispatch("showToast", {
              toast: {
                toastMode: "error",
                message:
                  err?.response?.data?.error?.message ||
                  "Failed to add new subscription plan",
              },
            });
          }
        );
      }
    } else {
      const newValues = markAsTouched(formValues);
      setFormvalues(newValues);
    }
  };

  const inputOnChange = (e) => {
    setFormvalues((prev) => {
      return {
        ...prev,
        ...e,
      };
    });
  };

  const collectErrors = useCallback((error) => {
    setFormVaildState((prev) => {
      return {
        ...prev,
        ...error,
      };
    });
  }, []);

  useEffect(() => {
    if (props.row?.id) {
      const result = extractFormFieldKeys(
        { ...formGroup, id: "" },
        { ...props.row }
      );
      result.galleryType3D = galleryTypeList.find(
        (g) => g.value.toLowerCase() === result.galleryType3D.toLowerCase()
      ).id;
      result.discoverableProfile = profileType.find(
        (p) => p.value === result.discoverableProfile
      ).id;
      result.availableGalleries =
        result.availableGalleries === 0 ? "0" : result.availableGalleries;
      result.planPrice = result.planPrice === 0 ? "0" : result.planPrice;
      result.regularPlanPrice= result.regularPlanPrice === 0 ? "0" : result.regularPlanPrice;
      result.anywallDisplayCount =
        result.anywallDisplayCount === 0 ? "0" : result.anywallDisplayCount;
      result.devices = result.devices === 0 ? "0" : result.devices;
      result.requiredMonetHolding =
        result.requiredMonetHolding === 0 ? "0" : result.requiredMonetHolding;
        result.walletLimit=result.walletLimit===0 || !result.walletLimit ?"0":result.walletLimit;

        result.availableCollections= 0 ? "0" : result.availableCollections;
        result.mintRebate= 0 ? "0" : result.mintRebate;
        result.mediaStorage= 0 ? "0" : result.mediaStorage;
      setFormvalues(result);
    } else {
      setFormvalues(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row]);
const onToggleChange=(key,checked)=>{
  inputOnChange({[key]:checked})
}
  return (
    <div className="sub-admin-create">
      <div className="w-full">
        <div className="flex gap-2 pt-1 column">
          <div className="sub-admin-form">
            <InputBox
              label="Name"
              validateOnBlur
              type="text"
              controlName="name"
              onChange={inputOnChange}
              value={formValues.name}
            />
            <Validators
              type="Required"
              value={formValues.name}
              message="Required field"
              controlName="name"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              label="Price"
              validateOnBlur
              type="number"
              controlName="planPrice"
              onChange={inputOnChange}
              value={formValues.planPrice}
            />
            <Validators
              type="Required"
              value={formValues.planPrice}
              message="Required field"
              controlName="planPrice"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              label="Regular Plan Price"
              validateOnBlur
              type="number"
              controlName="regularPlanPrice"
              onChange={inputOnChange}
              value={formValues.regularPlanPrice}
            />
            <Validators
              type="Required"
              value={formValues.regularPlanPrice}
              message="Required field"
              controlName="regularPlanPrice"
              onErrors={collectErrors}
            />
          </div>
          {/* <div className="sub-admin-form">
            <InputBox
              label="Monet Holdings"
              validateOnBlur
              type="number"
              autoComplete="new-password"
              controlName="requiredMonetHolding"
              onChange={inputOnChange}
              value={formValues.requiredMonetHolding}
            />
            <Validators
              type="Required"
              value={formValues.requiredMonetHolding}
              message="Required field"
              controlName="requiredMonetHolding"
              onErrors={collectErrors}
            />
          </div> */}

          <div><br></br><span className="pt-50">Features for Collectors</span></div>
          <div className="sub-admin-form">
            <SelectBox
              validateOnBlur
              list={galleryTypeList}
              placeHolder="Select"
              label={`3D Gallery`}
              className="frame-form"
              controlName={"galleryType3D"}
              onChange={inputOnChange}
              currentId={formValues?.galleryType3D}
              tabIndex={0}
            />
            <Validators
              type="Required"
              value={formValues.galleryType3D}
              message="Required field"
              controlName="galleryType3D"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              validateOnBlur
              placeHolder="Galleries Available"
              label={`Galleries Available`}
              type="number"
              controlName={"availableGalleries"}
              onChange={inputOnChange}
              value={formValues.availableGalleries}
            />
            <Validators
              type="Required"
              value={formValues.availableGalleries}
              message="Required field"
              controlName="availableGalleries"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              validateOnBlur
              placeHolder="Premium Frames"
              label={`Premium Frames`}
              type="number"
              controlName={"availablePremiumFrame"}
              onChange={inputOnChange}
              value={formValues.availablePremiumFrame}
            />
            <Validators
              validators={[{
                type: 'minValue',
                message: 'Minimum value is 0'
              }]}
              value={formValues.availablePremiumFrame}
              message="Required field"
              controlName="availablePremiumFrame"
              onErrors={collectErrors}
              minValue={'0'}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              validateOnBlur
              placeHolder="Device Available"
              label={`Device Available`}
              type="number"
              controlName={"devices"}
              onChange={inputOnChange}
              value={formValues.devices}
            />
            <Validators
              type="Required"
              value={formValues.devices}
              message="Required field"
              controlName="devices"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              validateOnBlur
              placeHolder="Anywall Displays"
              label={`Anywall Displays`}
              type="number"
              controlName={"anywallDisplayCount"}
              onChange={inputOnChange}
              value={formValues.anywallDisplayCount}
            />
            <Validators
              type="Required"
              value={formValues.anywallDisplayCount}
              message="Required field"
              controlName="anywallDisplayCount"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
            <InputBox
              validateOnBlur
              placeHolder="Viewable Wallet Limit"
              label={`Viewable Wallet Limit`}
              type="number"
              controlName={"walletLimit"}
              onChange={inputOnChange}
              value={formValues.walletLimit}
            />
            <Validators
              type="Required"
              value={formValues.walletLimit}
              message="Required field"
              controlName="walletLimit"
              onErrors={collectErrors}
            />
          </div>                  
          <div className="sub-admin-form">
            <SelectBox
              validateOnBlur
              list={profileType}
              placeHolder="Select"
              label={`Discoverable Profile`}
              className="frame-form"
              controlName={"discoverableProfile"}
              onChange={inputOnChange}
              currentId={formValues?.discoverableProfile}
              tabIndex={0}
            />
            <Validators
              type="Required"
              value={formValues.discoverableProfile}
              message="Required field"
              controlName="discoverableProfile"
              onErrors={collectErrors}
            />
          </div>
          <div className="sub-admin-form">
              <InputBox
              validateOnBlur
              placeHolder="Media Storage"
              label={`Media Storage (MB)`}
              type="number"
              controlName={"mediaStorage"}
              onChange={inputOnChange}
              value={formValues.mediaStorage}
            />
             <Validators
            validators={[{
              type: 'minValue',
              message: 'Minimum value is 0'
            }]}
              type="Required"
              value={formValues.mediaStorage}
              message="Required field"
              controlName="mediaStorage"
              onErrors={collectErrors}
              minValue={'0'}
            />
          </div>
          <div className="sub-admin-form">
          <div className="flex items-center gap-4">
              <div className={"form-label"}>Premium walls</div>
            <div className="tab-content">
              <div className={"tab-pane fade show active"}>
                <ToggleSwitch
                  className="role-switch"
                  check={formValues?.isAvailablePremiumWall}
                  row={{ id: "isAvailablePremiumWall" }}
                  onChange={onToggleChange}
                />
              </div>
          </div>
        </div>
          </div>

          
          <div><br></br><span className="pt-50">Features for Creators</span></div>

          <div className="sub-admin-form">
            <InputBox
              validateOnBlur
              placeHolder="Available Collections"
              label={`Available Collections`}
              type="number"
              controlName={"availableCollections"}
              onChange={inputOnChange}
              value={formValues.availableCollections}
            />
            <Validators
            validators={[{
              type: 'minValue',
              message: 'Minimum value is 0'
            }]}
              type="Required"
              value={formValues.availableCollections}
              message="Required field"
              controlName="availableCollections"
              onErrors={collectErrors}
              minValue={'0'}
            />
          </div>
          
          <div className="sub-admin-form">
            <InputBox
              validateOnBlur
              placeHolder="Mint Rebate"
              label={`Mint Rebate (%)`}
              type="number"
              controlName={"mintRebate"}
              onChange={inputOnChange}
              value={formValues.mintRebate}
            />
            <Validators
            validators={[{
              type: 'minValue',
              message: 'Minimum value is 0'
            },
            {
              type: 'maxValue',
              message: 'Maximum value is 100'
            }]}
              type="Required"
              value={formValues.mintRebate}
              message="Required field"
              controlName="mintRebate"
              onErrors={collectErrors}
              maxValue={'100'}
              
            />
          </div>
        </div>
      </div>

      <div className="flex transaction-button justify-center pt-12">
        <Button
          label="Cancel"
          onClick={onClose}
          className="transaction-cancel"
        />
        <Button
          className="transaction-ok"
          label={`${props?.row?.id ? "Update" : "Create"}`}
          onClick={formHandler}
        />
      </div>
    </div>
  );
}

export default SubscriptionModal;

import PageTab from "App/Components/UI/PageTab";
import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const tabs = [{
    link: 'free-frames',
    label: 'Basic'
}, {
    link: 'premium-frames',
    label: 'Premium'
}, {
    link: 'frame-projects',
    label: 'Frame Projects'
}
]
const FrameManagement = () => {
    const dispatch = useStore(false)[1];
    useEffect(() => {
        dispatch('setPageTitle', 'Frame Management')
    }, [dispatch])

    return (
        <div>
            <PageTab tabs={tabs} />
            <div className="py-4">
                <Outlet />
            </div>
        </div>
    )
}
export default FrameManagement;
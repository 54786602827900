import "./PdfUpload.scss";
import PDF from "Assets/Images/pdf.png";
import { useState } from "react";

const PdfUploader = (props) => {
    const [fileName, setFileName] = useState('')
    const pdfUpload = (e) => {
        props.onChange(e.target.files[0])
        setFileName(e.target?.files[0]?.name)
    }

    return (
        <div className={`p-8 ${props.className} pdf-upload`}>
            <div className="justify-center flex gap-2 items-center">
                <img src={PDF} alt="Noimg" height="2rem" className="pdf-img" />
                <div className="filename">{fileName ? fileName : 'No file added'}</div>
            </div>
            {props.upload && <div className="upload flex justify-center items-center pt-3">
                <div className="image-label">
                    <input type="file" accept="application/pdf" onChange={pdfUpload} />
                </div>
                Upload
            </div>}
        </div>
    )
}

export default PdfUploader
import "./Card.scss";

const Card = ({children})  => {
 return(
    <div className="card p-3">
    {children}
    </div>
 )
}

export default Card
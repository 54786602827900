import React, { useCallback, useEffect, useState } from "react"

const MultiSelectBoxOption = React.memo((props) => {
    const { checked = false } = props
    const [checkedValue, setCheckedValue] = useState(false)
    const checkHandler = useCallback(() => {
        setCheckedValue((prev) => !prev)

    }, [])
    useEffect(() => {
        setCheckedValue(checked)
    }, [checked])

    useEffect(() => {
        const obj = { ...props, checked: checkedValue }
        props.onChange(obj)
    }, [checkedValue, props])

    return (
        <li onClick={checkHandler} className="multiselect-group gap-3 items-center">
            <div className="multiselect-checkbox">
                {checkedValue &&
                    <div className="multiselect-checkbox-checked">
                    </div>}
            </div>
            <div>
                {props.name}
            </div>
        </li>
    )
})

export default MultiSelectBoxOption
import Button from "App/Components/UI/Button";
import Card from "App/Components/UI/Card"
import InputBox from "App/Components/UI/Forms/InputBox";
import Validators from "App/Components/UI/Forms/Validators";
import ImageReplace from "App/Components/UI/ImageReplace"
import MultiSelectBox from "App/Components/UI/MultiSelectBox";
import TextArea from "App/Components/UI/TextArea";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import AccessPlanImg from "Assets/Images/Access.jpeg";

const AccessPlanEdit = (props) => {
    const [formVaildState, setFormVaildState] = useState({});
    const [file, setFile] = useState(null)
    const [fileUpload, setFileUpload] = useState()
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [list, setList] = useState(null)
    const formGroup = {
        name: {
            value: undefined,
            isRequired: true
        },
        details: {
            value: undefined,
            isRequired: true
        },
        imageUrl: {
            value: undefined,
            isRequired: true
        },
        minimumStake: {
            value: undefined,
            isRequired: true
        },
        artBanks: {
            value: undefined,
            isRequired: true
        },
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup));

    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        onClose({ mode: true });
    }

    const onClose = ({ mode = false }) => {
        props.triggerClose(mode)
    }

    const editAccessHandler = async () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });

        const commonData = {
            name: formValues.name,
            details: formValues.details,
            minimumStake: formValues.minimumStake,
            artBanks: formValues.artBanks,
        }
        if (valid) {
            let imageUrlResult = {}
            if (fileUpload) {
                imageUrlResult = await uploadImageApi(formValues.id)
            }
            else {
                imageUrlResult.imageUrl = formValues.imageUrl
            }
            if (formValues.id) {
                let data = {
                    ...commonData,
                    id: formValues.id,
                    imageUrl: imageUrlResult.imageUrl
                }

                dispatch('showSpinner');
                triggerAPI({
                    url: 'access-plan/update', data, method: 'put'
                }, apiResult);
            }
            else {
                let data = {
                    ...commonData,
                    imageUrl: imageUrlResult.imageUrl,
                }
                dispatch('showSpinner');
                triggerAPI({
                    url: 'access-plan/add', data, method: 'post'
                }, apiResult);
            }
        }
        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }

    const uploadHandler = (e) => {
        setFileUpload(true)
        const reader = new FileReader()
        reader.addEventListener('load', (res) => {
            const imageUrl = res.target.result
            setFile(e);
            setFormvalues((prev) => {
                return {
                    ...prev,
                    imageUrl
                }
            });
        })
        reader.readAsDataURL(e)
    }

    const uploadImageApi = (id = '') => {
        const formData = new FormData()
        const url = new URLSearchParams({
            id
        })
        formData.append('file', file)
        return new Promise((resolve) => {
            dispatch('showSpinner');
            triggerAPI({
                url: `access-plan/upload-image?${url}`, data: formData, method: 'patch'
            }, (res => {
                dispatch('hideSpinner');
                resolve(res.data)
            }));
        })
    }

    const inputOnChange = useCallback((e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }, [])

    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    const getArtBankResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data
        setList(list)
    }, [dispatch])

    const getArtBankDetails = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `art-bank/get`, method: 'get'
        }, getArtBankResult);
    }, [dispatch, getArtBankResult, triggerAPI])
    useEffect(() => {
        if (props.row.id) {
            setFormvalues(props.row);
        }
    }, [props.row])
    useEffect(() => {
        getArtBankDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div className="flex">
                <Card>
                    <ImageReplace src={formValues.imageUrl} content="Only JPG and PNG" dummyImage={AccessPlanImg} upload={formValues.id ? 'Replace' : 'Upload'} controlName="imageUrl" className="access-image" onChange={uploadHandler} />
                    <div className="ml-5">
                        <Validators type="Required" value={formValues.imageUrl} message="Image is required" controlName="imageUrl" onErrors={collectErrors} />
                    </div>
                </Card>
            </div>
            <div className="flex justify-between w-full gap-6 flex-wrap pt-6">
                <div className="access-plan-edit-form-field">
                    <InputBox label="Access Plan Name" type="text" validateOnBlur controlName="name" value={formValues.name} onChange={inputOnChange} />
                    <Validators type="Required" value={formValues.name} message="Required field" controlName="name" onErrors={collectErrors} />

                </div>
                <div className="access-plan-edit-form-field">
                    <InputBox label="Minimum ADA staked" type="text" validateOnBlur value={formValues.minimumStake} controlName="minimumStake" onChange={inputOnChange} />
                    <Validators type="Required" value={formValues.minimumStake} message="Required field" controlName="minimumStake" onErrors={collectErrors} />
                </div>
            </div>

            <div className="pt-4">
                <MultiSelectBox validateOnBlur list={list} placeHolder="Select" label="Artbank Collections" value={formValues.artBanks} onChange={inputOnChange} tabIndex='0' />
                <Validators type="Required Array" value={formValues.artBanks} message="Required field" controlName="artBanks" onErrors={collectErrors} />
            </div>

            <div className="pt-4">
                <TextArea label="Description" className="access-plan" validateOnBlur controlName="details" value={formValues.details} onChange={inputOnChange} />
                <Validators type="Required" value={formValues.details} message="Required field" controlName="details" onErrors={collectErrors} />
            </div>

            <div className="flex justify-center items-center gap-3 pt-4">
                <Button label="Cancel" row={{ status: false }} className='transaction-cancel' onClick={onClose} />
                {props.row?.id ? <Button className='transaction-ok' row={{ status: true }} label="Update" onClick={editAccessHandler} />
                    : <Button className='transaction-ok' row={{ status: true }} label="Save" onClick={editAccessHandler} />
                }
            </div>

        </div>
    )
}

export default AccessPlanEdit
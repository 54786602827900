import { regex } from "App/configs/regex";

const validationCheck = ({type,value}) => {
    let isValid = false;
    switch (type) {
        case 'email':
            isValid = !regex.email.test(value);
            break;
        case 'alphamuneric':
            isValid = !value.match(regex.alphamuneric);
            break;
            case 'url':
            isValid = !regex.url.test(value);
            break;
        default:
            break;
    }
    return isValid;
}
export default validationCheck;
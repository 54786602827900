import { useState } from "react"
import "./Image.scss"
import Images from "Assets/Images/frame-upload.png";
import ButtonAction from "../ButtonAction";

const Image = (props) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const onLoadHandler = (e) => {
        if (e.target.complete) {
            setTimeout(() => {
                setIsLoaded(true)
            }, 300);
            if (props.loaded) {
                props.loaded(e.target.complete);
            }
        }
    }

    const onErrorHandler = (e) => {
        if (props.dummy) {
            e.target.src = props.dummy;
        }
        else {
            e.target.src = Images
        }
    }

    const stopHandler = (e) => {
        if (e.currentTarget?.dataset.info) {
            const value = props.list.infoDeskContents.find(val => val.id === Number(e.currentTarget?.dataset.info))
            props?.showData && props.showData(value)

        }
        else {
            props?.showData && props.showData({})

        }
        e.stopPropagation();
    }

    return (
        <div className={`${isLoaded ? `${props?.className}` : `${props?.loaderClass} loader`} image-container`} style={isLoaded ? { Width: 'auto' } : props.style}>
            <img onError={onErrorHandler} width={props?.width} height={props?.height} className="loader-image" src={props.src} onLoad={onLoadHandler} alt={`${isLoaded ? 'image' : ''}`} />
            {props.icon && isLoaded &&
                <div className="icon" onClick={stopHandler} data-info={props.datainfo ?? ''}>
                    <ButtonAction row={props?.row} menu={props.menu} src={props.icon} onAction={props.onAction} />
                </div>
            }
        </div>
    )
}

export default Image
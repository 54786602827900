import { useEffect } from "react";
import { useState } from "react";
import Button from "../Button";
import SelectBox from "../SelectBox";
import TemplatePopup from "../TemplatePopup"
import "./SelectFilter.scss"

const SelectFilter = (props) => {
    const [value, setValue] = useState(0);
    const [showAppluButton, setShowAppluButton] = useState(true);
    const handleChange = (e) => {
        if(e[props?.keyName] !== undefined) {
            props?.onChange(e);
            setValue(e[props?.keyName]);
        }      
        if(!showAppluButton) {
            props.onApply({
                ...props.data,
                [props?.keyName]: e[props?.keyName]
            })
        }  
    }
    useEffect(() => {
        setValue(props?.value);
    },[props?.value])
    useEffect(() => {
        setShowAppluButton(props?.showApplyButton);
    },[props?.showApplyButton])
    const onCloseHandler = (data) => {
        props?.onClose(data)
    }
    const applyHandler = () => {
        props.onApply({
            ...props.data,
            [props?.keyName]: value
        })
    }
    return (
        <TemplatePopup show={props.show} cssClass="date-desk" onClose={props.onCloseHandler}>
            <div className="select-filter date-picker p-9 flex flex-col">
                <div className="status-filter-head pb-4 ">{props?.selectName}</div>
                <div>
                    <SelectBox validateOnBlur list={props?.list} placeHolder="Select" label={`Select ${props?.selectName}`} className="frame-form" controlName={props?.keyName} onChange={handleChange} currentId={value} tabIndex={0} />
                </div>
                <div className="flex justify-center gap-4 pb-5 pt-9">
                    <Button label="Reset" className="transaction-cancel" row={{ action: 'resetSelect' }} onClick={onCloseHandler} />
                    {showAppluButton && <Button label="Apply" className="transaction-ok"  onClick={applyHandler} />}
                </div>
            </div>
        </TemplatePopup>
    )
}

export default SelectFilter;
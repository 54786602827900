const storage = () => {
    const store = localStorage;
    const getValue = (value) => {
        let val = '';
        try {
            val = typeof value === 'string' ? value : JSON.stringify(value);
        } catch (error) {
            val = 'not able to stringify';
        }
        return val;
    }
    const set = (key, value) => {
        store.setItem(key, getValue(value))
    }
    const get = (key) => {
        return store.getItem(key)
    }
    const remove = (key) => {
        store.removeItem(key)
    }
    const clearAll = () => {
        store.clearAll();
    }
    return {
        set,
        get,
        remove,
        clearAll
    }
}
export default storage;
import Button from "App/Components/UI/Button"
import Pagination from "App/Components/UI/Pagination";
import SearchFilter from "App/Components/UI/SearchFilter"
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewFrames from "../../Components/ViewFrames";
import NFTFrameCard from "./NFTFrameCard";
import { LIST_LIMIT } from "App/constants";

const paginationConfig = {
    perPage: LIST_LIMIT
}

const NFTFrames = () => {
    const [nftFramesMenu, setNftFramesMenu] = useState([
        {
            label: 'By name, A to Z',
            action: 'nameAsc',
            selected: false,
            type: 'name',
        },
        {
            label: 'By name, Z to A',
            action: 'nameDesc',
            selected: false,
            type: 'name',
        },
        {
            label: 'Price, Low to High',
            action: 'priceAsc',
            selected: false,
            type: 'price',
        },
        {
            label: 'Price, High to Low',
            action: 'priceDesc',
            selected: false,
            type: 'price',
        },
        {
            label: 'Date Range',
            action: 'dateRange',
            selected: false,
            popup: true
        },
    ])

    const dispatch = useStore(false)[1];
    const [list, setList] = useState()
    const { triggerAPI } = useHttp();
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [totalPage, setTotalPage] = useState(1);
    const [viewNFTFrames, setViewNFTFrames] = useState({ mode: false })
    const [selectedData, setSelectedData] = useState({})
    const [filteredData, setFilteredData] = useState('')
    const [search, setSearch] = useState('')
    const [initial, setInitial] = useState(true);

    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }

    const viewFrames = (e, data) => {
        setSelectedData(data)
        if (e.action === "edit") {
            navigate(`duplicate/${data.id}`)
        }
        else {
            setViewNFTFrames({ mode: true })

        }

    }
    const closeNFTFrames = () => {
        setViewNFTFrames({ mode: false })
    }

    const editNFTFrames = (e) => {
        navigate(`edit/${e}`)
    }
    const onFilterData = (e) => {
        setFilteredData(e)
    }
    const searchHandler = (e) => {
        setSearch(e)
    }
    const getFreeFrameResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getNFTFrames = useCallback(() => {
        dispatch('showSpinner');
        const data = new URLSearchParams({
            page: page,
            items: paginationConfig.perPage
        })
        let filter;
        if (filteredData) {
            filter = filteredData

        }
        else {
            filter = ''
        }
        triggerAPI({
            url: `frame-asset/non-free/get?${data}`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getFreeFrameResult);
    }, [dispatch, filteredData, getFreeFrameResult, page, search, triggerAPI])

    const toggleSwitch = (id, checked) => {
        getPromotionalDetails(id, checked);
    }
    const CreateNFTFrameHandler = () => {
        navigate("create")
    }
    const getPromotionalResult = (res) => {
        getNFTFrames()
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const getPromotionalDetails = (id, checked) => {
        dispatch('showSpinner');
        const data = {
            id: id,
            isPromotionalFrame: checked
        }
        triggerAPI({
            url: `frame-asset/promotional/update`, data, method: 'patch'
        }, getPromotionalResult);
    }

    const toggleDisplayStatusResult = (res) => {
        getNFTFrames()
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const toggleDisplayStatus = (id, checked) => {
        dispatch('showSpinner');
        const data = {
            id: id,
            displayStatus: checked
        }
        triggerAPI({
            url: `frame-asset/display-status/update`, data, method: 'patch'
        }, toggleDisplayStatusResult);
    }

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getNFTFrames();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, filteredData])

    useEffect(() => {
        if (!initial) {
            getNFTFrames();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 120, top: 10 }} actionMenu={nftFramesMenu} setActionMenu={setNftFramesMenu} onSearch={searchHandler} onFilterData={onFilterData} data={filteredData} />
                </div>
                <div>
                    <Button label="Create Frame" onClick={CreateNFTFrameHandler} />
                </div>
            </div>

            <div className="grid frame-list-container gap-5">
                {list?.map((NFTFrames) =>
                    <div className="nft-frames-card free-frames-pic cursor-pointer" key={NFTFrames.id}>
                        <NFTFrameCard row={NFTFrames} check={NFTFrames?.isPromotionalFrame} onChange={toggleSwitch} checkDisplayStatus={NFTFrames?.displayStatus} onChangeDisplayStatus={toggleDisplayStatus} onClickHandler={viewFrames} />
                    </div>)}
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>
                }
            </div>

            <div className="mt-6">
                <Pagination itemsPerPage={paginationConfig.perPage} rows={list?.length} total={totalPage} onPageChange={onPageChangeHandler} selected={page} />
            </div>
            <ViewFrames cssClass="nft-view" row={selectedData.id} show={viewNFTFrames} onClose={closeNFTFrames} onEdit={editNFTFrames} isNft={true} />
        </div>
    )
}

export default NFTFrames
import ArrowRight from "Assets/Images/arrow-right-enb.svg";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowLeft from "Assets/Images/pagination-left-active.svg";

import "./BreadCrumbs.scss";
import { Fragment } from "react";
const BreadCrumbs = ({ links = [], title, className }) => {
    let navigate = useNavigate();

    const goBack = () => {
        if (links.length > 1) {
            const url = links[links.length - 2]
            if (url?.back) {
                navigate(-1)
            }
            else {
                navigate(url.link)
            }

        }
    }

    const gotToBack = () => {
        navigate(-1)
    }
    return (
        <div>
            <div className={`flex gap-4 breadcrumbs ${className}`}>
                <img className="breadcrumbs-img" src={ArrowLeft} alt="img" onClick={goBack}></img>
                <div className="justify-between breadcrumbs-layout">
                    <div className="breadcrumbs-title">
                        <span>{title}</span>
                    </div>
                    <ul className="flex justify-between pt-2">
                        {links.map((brd, index) =>
                            <Fragment key={index}>
                                <li className={`back-${brd.link ? 'link' : 'label'}`}>{brd.link ?
                                    <NavLink to={brd.link}>
                                        {brd.label}
                                    </NavLink> :
                                    brd?.back ? <span onClick={gotToBack}>{brd.label}</span>
                                        :
                                        brd.label}

                                </li>
                                {

                                    (links.length - 1 !== index) ?
                                        <li className="justify-center items-center">
                                            <img src={ArrowRight} alt="img" />
                                        </li> : ''

                                }
                            </Fragment>
                        )}
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default BreadCrumbs
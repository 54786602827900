import useHttp from "App/hooks/use-http";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import tableSort from "App/helpers/tableSort";
import { useNavigate } from "react-router-dom";
import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import ButtonLink from "App/Components/UI/ButtonLink";
import Pagination from "App/Components/UI/Pagination";
import { useStore } from "App/hooks-store/store";
import Button from "App/Components/UI/Button";
import Popup from "App/Components/UI/Popup";
import InputBox from "App/Components/UI/Forms/InputBox";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import Validators from "App/Components/UI/Forms/Validators";
import FormatField from "App/Components/UI/FormatField";
import { LIST_LIMIT } from "App/constants";

const paginationConfig = {
    perPage: LIST_LIMIT
}
const TrailUsers = React.memo(() => {
    const [dateMenu, setDateMenu] = useState([{
        label: 'Date Range',
        action: 'dateRange',
        selected: false,
        popup: true
    }
    ])
    const [initial, setInitial] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ direction: '', sortKey: 'walletAddress' });
    const [showLimitPopup, setShowLimitPopup] = useState({ mode: false });
    const [formVaildState, setFormVaildState] = useState({});
    const [selectedDate, setSelectedDate] = useState('')
    const [defaultValue, setDefaultValue] = useState({})
    const formGroup = {
        limit: {
            value: undefined,
            isRequired: true
        },
        expiryDays: {
            value: undefined,
            isRequired: true
        }
    }
    const validatorsExpDays = useMemo(() => ([{
        type: 'Required',
        message: 'Required field'
    }, {
        type: 'minValue',
        message: 'Value should be greater than 1'
    }]), [])
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const { triggerAPI: createUserApi } = useHttp();

    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort]);
    const navigate = useNavigate()

    const viewWalletTransactionHandler = (e) => {
        const { id } = e.row;
         navigate(`/dashboard/user-transactions/${id}`, { state: { from: 'trial' } })
    }

    const searchHandler = (e) => {
        setSearch(e)
    }

    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const setLimitHandler = () => {
        setShowLimitPopup({ mode: true })
    }
    const onCloseLimitPopup = () => {
        setShowLimitPopup({ mode: false })
    }
    const onClosePopUp = () => {
        setFormvalues((prev) => {
            return {
                ...prev,
                limit: defaultValue?.limit,
                expiryDays: defaultValue?.expiryDays,
            }
        })
        setShowLimitPopup({ mode: false })
    }
    const onFilterData = (e) => {
        setSelectedDate(e)
    }
    const getUserlistResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getUserList = useCallback(() => {
        dispatch('showSpinner');
        let filter;
        if (sort.direction || selectedDate.from) {
            filter = {
                sortBy: sort.sortKey,
                orderBy: sort.direction,
                from: selectedDate.from,
                to: selectedDate.to
            }
        }
        else {
            filter = ''
        }

        triggerAPI({
            url: `admin/guests/get`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getUserlistResult);
    }, [dispatch, getUserlistResult, page, search, sort, triggerAPI, selectedDate])

    const getSettingsListResult = useCallback((res) => {
        dispatch('hideSpinner');
        const settingData = {
            limit: res.data[1]?.value,
            expiryDays: res.data[0]?.value
        }
        setDefaultValue(settingData);
        setFormvalues((prev) => {
            return {
                ...prev,
                ...settingData
            }
        })
    }, [dispatch])


    const getSettingsList = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `user/static`, data: {
                filter: {
                    type: 'Guest User Setting'
                }
            }, method: 'post'
        }, getSettingsListResult);
    }, [dispatch, getSettingsListResult, triggerAPI])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getUserList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, selectedDate])

    useEffect(() => {
        if (!initial) {
            getUserList();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])
    const apiResult = (res) => {
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message: 'Limit Updated Success' } })
        onCloseLimitPopup();
        const settingData = {
            limit: formValues?.limit,
            expiryDays: formValues?.expiryDays
        }
        setDefaultValue(settingData);
    }
    const createLimitHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            dispatch('showSpinner');
            createUserApi({
                url: `admin/guests/static-params`, data: formValues, method: 'post'
            }, apiResult);
        }

        else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }
    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])

    useEffect(() => {
        getSettingsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setDateMenu} actionMenu={dateMenu} onFilterData={onFilterData} />
                </div>
                <div>
                    <Button label="Set Limit" onClick={setLimitHandler} />
                </div>
            </div>
            <div className="mb-40">
                <Table>
                    <thead className="text-left">
                        <tr>

                            <th>
                                <TableHeader label="Wallet Address" sort={sort} sortKey="walletAddress" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="NFT Count" />
                            </th>
                            <th>
                                <TableHeader label="Transactions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((trial) => <tr key={trial?.id}>
                            <td>
                                <FormatField type="address" maxWidth="26rem" value={trial?.walletAddress} />
                            </td>
                            <td>
                                {trial?.nftCount}
                            </td>
                            <td>
                                <ButtonLink label="View" row={trial} onClick={viewWalletTransactionHandler} />
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>
                <Popup show={showLimitPopup}
                    style={{ 'maxWidth': '30rem' }}
                    title={`Set The Limit`} cssClass="user-create-pop" onClose={onClosePopUp}>
                    <div className="user-form">
                        <InputBox label="NFT Limit" validateOnBlur type="text" controlName="limit" onChange={inputOnChange} value={formValues.limit} />
                        <Validators type="Required" value={formValues.limit} message="Required field" controlName="limit" onErrors={collectErrors} />
                    </div>
                    <div className="user-form mt-4">
                        <InputBox label="Expiry Days" validateOnBlur type="number" controlName="expiryDays" onChange={inputOnChange} value={formValues.expiryDays} />
                        <Validators type="Required" value={formValues.expiryDays} controlName="expiryDays" minValue={1} onErrors={collectErrors} validators={validatorsExpDays} />
                    </div>
                    <div className="flex transaction-button justify-center pt-12">
                        <Button label="Cancel" onClick={onClosePopUp} className='transaction-cancel' />
                        <Button className='transaction-ok' label={'Submit'} onClick={createLimitHandler} />
                    </div>
                </Popup>
            </div>
        </div>
    )
})
export default TrailUsers;
import FormatField from "App/Components/UI/FormatField";
import Pagination from "App/Components/UI/Pagination";
import SearchFilter from "App/Components/UI/SearchFilter";
import Status from "App/Components/UI/Status";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import { LIST_LIMIT } from "App/constants";
import tableSort from "App/helpers/tableSort";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";

const paginationConfig = {
    perPage: LIST_LIMIT
}

const TransactionHistory = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [list, setList] = useState();
    const [sort, setSort] = useState({ direction: '', sortKey: 'id' });
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState('')
    const [initial, setInitial] = useState(true);

    const [transactionMenu, setTransactionMenu] = useState([
        {
            label: 'Status',
            action: 'status',
            selected: false,
            popup: true,
            type: 'statusValue'
        },
        {
            label: 'Date Range',
            action: 'dateRange',
            selected: false,
            popup: true,
            type: 'dateValue'

        }
    ])

    const onFilterData = (e) => {
        setFilteredData(e)
    }
    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort])

    const getTransactionResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getUserTransactions = useCallback(() => {
        dispatch('showSpinner');
        let filter;
        if (sort.direction || filteredData) {
            filter = {
                sortBy: sort.sortKey,
                orderBy: sort.direction,
                ...filteredData
            }
        }
        else {
            filter = ''
        }
        triggerAPI({
            url: `frame-asset/admin/get/transaction-status`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getTransactionResult);
    }, [dispatch, filteredData, getTransactionResult, page, search, sort, triggerAPI])

    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const searchHandler = (e) => {
        setSearch(e)
    }

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getUserTransactions();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, filteredData])

    useEffect(() => {
        if (!initial) {
            getUserTransactions();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        dispatch('setPageTitle', 'Transaction History')
    }, [dispatch])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setTransactionMenu} actionMenu={transactionMenu} onFilterData={onFilterData} data={filteredData} />
                </div>

            </div>
            <div>
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th>
                                <TableHeader label="ID" sort={sort} sortKey="id" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Date" sort={sort} sortKey="createdAt" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Sending Address" />
                            </th>

                            <th>
                                <TableHeader label="Receiving Address" />
                            </th>
                            <th>
                                <TableHeader label="Amount (₳)" />
                            </th>
                            <th>
                                <TableHeader label="Asset" />
                            </th>
                            <th>
                                <div className="flex justify-end items-center">
                                    <TableHeader label="Status" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {list?.map((transaction) =>
                            <tr key={transaction.id}>
                                <td>
                                    {transaction?.id}
                                </td>
                                <td>
                                    <FormatField type="dateTime" value={transaction?.createdAt} />
                                </td>
                                <td>
                                    <FormatField type="address" maxWidth="26rem" value={transaction?.sendingAddress} />

                                </td>
                                <td>
                                    <FormatField type="address" maxWidth="26rem" value={transaction?.receivingAddress} />
                                </td>
                                <td>
                                    ₳<FormatField type="adaConvert" value={transaction?.priceInLovelace} />
                                </td>
                                <td>
                                    {transaction?.asset?.name}
                                </td>
                                <td>
                                    <div className="flex justify-end items-center capitalize">
                                        <Status label={(transaction?.state)} />
                                    </div>
                                </td>

                            </tr>
                        )}
                    </tbody>

                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>
                }
                <div className="mt-6 mb-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} rows={list?.length} total={totalPage} onPageChange={onPageChangeHandler} selected={page} />
                </div>
            </div>
        </div>
    )
}

export default TransactionHistory
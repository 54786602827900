import "./EmbedVideo.scss"
const EmbedVideo = (props) => {

    return (
        <div className={`w-full video ${props.className}`}>
            <iframe src={props.src} className="w-full" title="video"></iframe>
        </div>
    )
}

export default EmbedVideo
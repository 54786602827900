import Pagination from "App/Components/UI/Pagination";
import SearchFilter from "App/Components/UI/SearchFilter";
import Status from "App/Components/UI/Status";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import { convertJsonToArray, convertPriceToAda, objectToFormdata } from "App/helpers/utilities";
import tableSort from "App/helpers/tableSort";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import Button from "App/Components/UI/Button";
import ButtonAction from "App/Components/UI/ButtonAction";
import ActionButton from "Assets/Images/ActionButton.svg";
import Popup from "App/Components/UI/Popup";
import UploadKeyFilesPopUp from "./Components/UploadKeyFilesPopUp";
import DuplicatePopUp from "./Components/DuplicatePopUp";
import { useNavigate } from "react-router-dom";
import getDateTime from "App/helpers/dateTime";
import './Project.scss';
import { LIST_LIMIT } from "App/constants";

const paginationConfig = {
    perPage: LIST_LIMIT
}
const actionMenu = [{
    label: 'Upload Key files',
    action: 'uploadKeyFiles',
}]
const defaultOptionMenu = [
    {
        label: 'Duplicate',
        action: 'duplicate',
    }
]
const Project = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [list, setList] = useState();
    const [sort, setSort] = useState({ direction: '', sortKey: 'id' });
    const [selectedData, setSelectedData] = useState({})
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState('')
    const [initial, setInitial] = useState(true);
    const [showUploadFilePop, setShowUploadFilePop] = useState(false);
    const [showDuplicatePop, setShowDuplicatePop] = useState(false);
    const navigate = useNavigate();

    const [projectFilterMenu, setProjectFilterMenu] = useState([
        {
            label: 'NFT Supply',
            action: 'nftSupply',
            selected: false,
            popup: true,
            type: 'nftSupplyValye'
        },
        {
            label: 'Year',
            action: 'year',
            selected: false,
            popup: true,
            type: 'yearValue'
        }
    ])

    const onFilterData = (e) => {
        setFilteredData(e)
    }
    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort])

    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const searchHandler = (e) => {
        setSearch(e)
    }

    useEffect(() => {
        dispatch('setPageTitle', 'Project')
    }, [dispatch])

    useEffect(() => {
    }, [filteredData])
    const actionHandler = (e) => {
        setSelectedData(e.row)
        if(e.action === 'uploadKeyFiles') {
            setShowUploadFilePop(true);
        } else if (e.action === 'duplicate') {
            setShowDuplicatePop(true);
        }
    }
    const onCloseUploadFile = () => {
        setSelectedData({});
        setShowUploadFilePop(false);
    }
    const onUploadFiles = (formValues) => {
        const formData = objectToFormdata(formValues)  
        dispatch('showSpinner');   
        triggerAPI({
            url: 'nft-maker/project/upload-keys', data: formData, method: 'put'
        }, uploadKeyApiResult);           
    }

    const uploadKeyApiResult = (res) => {        
        setSelectedData({});
        setShowUploadFilePop(false);
        const { message } = res;
        getProjectList();
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }
    const onCloseDuplicate = () => {
        setSelectedData({});
        setShowDuplicatePop(false);
    }
    const onCreateDuplicate = () => {
        setShowDuplicatePop(false);
        navigate(`duplicate/${selectedData?.projectId}`);
        setSelectedData({});
    }
    const handleCreate = () => {        
        navigate("add")
    }

    const getProjectResponse = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data;
        const projectList = list.map((project) => {
            project.policyLocksDateTime = getDateTime(project.policyLocksDateTime);
            return project;
        })
        setList(projectList);
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getProjectList = useCallback(() => {
        dispatch('showSpinner');
        const data = new URLSearchParams({
            page: page,
            items: paginationConfig.perPage
        })
        let filter;
        if (sort.direction || filteredData) {
            filter = {
                sortBy: sort.sortKey,
                orderBy: sort.direction,
                maxNftSupply: filteredData?.nftValue,
                year: filteredData?.year,
            }
        }
        else {
            filter = {}
        }
        triggerAPI({
            url: `nft-maker/project/list?${data}`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getProjectResponse);
    }, [dispatch, filteredData, getProjectResponse, page, search, sort, triggerAPI])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getProjectList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, filteredData])
    useEffect(() => {
        if (!initial) {
            getProjectList();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])
    const getPriceData = useCallback((pricelist)=>{
        const priceData = convertJsonToArray(pricelist);
        let price = 0, currency = 'ADA';
        if (priceData?.length) {
            price = priceData[0].priceInLovelace ? convertPriceToAda(priceData[0].priceInLovelace) : priceData[0].price;
            currency = priceData[0].priceInLovelace? currency : (priceData[0].currency)?.toUpperCase();
        }
        return `${currency} ${price}`;
    },[])
    return (
        <div className="project-management">
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setProjectFilterMenu} actionMenu={projectFilterMenu} onFilterData={onFilterData} data={filteredData} />
                </div>
                <div>
                    <Button label="Create Project" onClick={handleCreate} />
                </div>
            </div>
            <div>
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th>
                                <TableHeader label="Project Name" />
                            </th>
                            <th>
                                <TableHeader label="Policy Lock Date & Time" />
                            </th>
                            <th>
                                <TableHeader label="NFT Supply" sort={sort} sortKey="maxNftSupply" onSort={onSortHandler} />
                            </th>
                            <th>
                                <TableHeader label="Price" />
                            </th>
                            <th>
                                <TableHeader label="Key Status" />
                            </th>
                            <th>
                                <TableHeader label="Action" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {list?.map((project, index) =>
                            <tr key={`${project.id} ${index}`}>
                                <td>
                                    {project?.projectname}
                                </td>
                                <td>
                                    {project?.policyLocksDateTime}
                                </td>
                                <td>
                                    {project?.maxNftSupply}
                                </td>
                                <td>
                                    {getPriceData(project?.pricelist)}
                                </td>
                                <td>
                                    <Status label={(project?.privateSigningkey && project?.privateVerifykey) ? 'Uploaded' : 'Not Uploaded'} />
                                </td>
                                <td>
                                    <div className="flex justify-end items-center">
                                        <ButtonAction src={ActionButton} 
                                        menu={(project?.privateSigningkey && project?.privateVerifykey) ? defaultOptionMenu : actionMenu} 
                                            row={project} 
                                            onAction={actionHandler} 
                                            />
                                    </div>

                                </td>
                            </tr>
                        )}
                    </tbody>

                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>
                }
                <div className="mt-6 mb-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} rows={list?.length} total={totalPage} onPageChange={onPageChangeHandler} selected={page} />
                </div>
                <Popup show={{mode:showUploadFilePop}}
                    style={{ 'maxWidth': '40rem' }}
                    title={`Upload Key files`} cssClass="" onClose={onCloseUploadFile}>
                        <UploadKeyFilesPopUp onClose={onCloseUploadFile} onUploadFiles={onUploadFiles} data={selectedData} />
                </Popup>
                <Popup show={{mode:showDuplicatePop}}
                    style={{ 'maxWidth': '40rem' }}
                    title={`Duplicate this project`} cssClass="" onClose={onCloseDuplicate}>
                        <DuplicatePopUp onClose={onCloseDuplicate} data={selectedData} onCreateDuplicate={onCreateDuplicate} />
                </Popup>
            </div>
        </div>
    )
}

export default Project
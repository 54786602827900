/* eslint-disable react-hooks/exhaustive-deps */
import "./SelectBox.scss";
import { useRef, useState, useEffect, useCallback } from "react";
const SelectBox = ({ list = [], controlName = '',keyName,required=true, validateOnBlur, onChange = {}, 
    currentId = '', label = '', placeHolder = '', className = '', row='',
    tabIndex = -1, disabled = false }) => {
    const select = useRef();
    const [show, setShow] = useState(false);
    const [selectCount, setSelectCount] = useState(0);
    const [selectedValue, setSelectedValue] = useState({ name: null, value: null });
    const applyToggle = useCallback(() => {
        const parent = select.current;
        const list = parent.querySelector('div.selectbox-list');
        if(list) {
            if (show) {
                parent.classList.remove('selectbox-list-closed');
                parent.classList.add('selectbox-list-open');
                list.style.maxHeight = `${list.scrollHeight}px`;
            } else {
                parent.classList.remove('selectbox-list-open');
                parent.classList.add('selectbox-list-closed');
                list.style.maxHeight = `0px`;
            }
        }
        
    }, [show])
    const onSelectToggle = () => {
        setShow((prv) => !prv);
    }
    const selectOption = (e) => {
        onSelectToggle();
        select.current.blur();
        selectValues(e.target.innerHTML, e.currentTarget?.dataset?.id)
    }
    const selectValues = (e, id) => {
        const value = e;
        const found = list?.find(item =>(item?.id) === +id || (item?.name).toString() === value.toString());
        setSelectedValue(found)

            const values = {
                [controlName]: keyName?found[keyName]:found?.id
            }
            if (onChange) {
                onChange(values)
            }
        
       
        
    }
    const setSelectCountWithTimer = (value) => {
        setTimeout(() => {
            setSelectCount(value);
        }, 500);
    }
    const onBlur = (e) => {
        setShow(false);
        setSelectCountWithTimer(0)
        applyToggle();
        if (validateOnBlur && !(selectedValue?.value || selectedValue?.name || selectedValue?.id)) {
            const value = '';
            const values = {
                [controlName]: value
            }
            onChange(values)
        }
    }
    const clickHandler = () => {
        if (selectCount === 1) {
            select.current.blur();
        }
    }
    const focusHandler = () => {
        setShow(true);
        //escape initial
        setSelectCountWithTimer(1)
    }
    useEffect(() => {
        applyToggle();

        if (currentId) {
            const found = list?.filter(val => {
                let content=keyName?val[keyName]:val?.id
                content = typeof content === "string" ? content?.toLowerCase() : content;
                let check = typeof currentId === "string"? currentId?.toLowerCase() : currentId;
                return content === check
            })
            setSelectedValue(found?.length ? found[0] : { name: null, value: null })
        }
    }, [applyToggle, currentId, list])

    return (
        <div className={`relative selectbox ${className} `} ref={select} onFocus={focusHandler} onBlur={onBlur} tabIndex={tabIndex}>
            <div className="selectbox-label" onClick={clickHandler}>
                <div>
                    <label className="form-label">{label} {(validateOnBlur)&&required ? '*' : ''}</label>
                    <span className="selectbox-placeHolder form-box flex justify-start 
                    items-center mt-1 px-2"> {(selectedValue?.name) ??
                            placeHolder} </span>
                </div>
            </div>
            {!disabled && 
                <div className="absolute selectbox-list">                
                    <ul>
                        {list?.map((item) =>
                            <li key={item.id} className={selectedValue?.name===item?.name?"active":""} onClick={selectOption} data-value={item.value} data-id={item.id}>{item.name}</li>
                        )}
                    </ul>
                </div>
            }
        </div>
    )
}
export default SelectBox
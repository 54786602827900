import "./Header.scss"
import BellIcon from "Assets/Images/BellIcon.svg";
import Settings from "Assets/Images/Settings.svg";
import Person from "Assets/Images/Person.svg";
import { useStore } from "App/hooks-store/store";
import ButtonAction from "App/Components/UI/ButtonAction";
import useHttp from "App/hooks/use-http";
import useAuth from "App/hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

const actionMenu = [{
    label: 'Profile',
    action: 'profile',
}, {
    label: 'Change Password',
    action: 'changePassword',
},
{
    label: 'Logout',
    action: 'logout',
},
]

const Header = () => {
    const store = useStore()[0];
    const { pageTitle, profile } = store;
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const logout = useAuth()[1];
    const [list, setList] = useState([]);
    let navigate = useNavigate();
    const logOutApiResult = (res) => {
        const { message } = res;
        logout()
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }
    const logOutHandler = () => {
        dispatch('showSpinner');
        triggerAPI({
            url: "admin/logOut", data: {}, method: 'post'
        }, logOutApiResult)

    }
    const getProfileResult = useCallback((res) => {
        const list = res.data;
        setList(list)
    }, [])

    const getProfileDetails = useCallback(() => {
        triggerAPI({
            url: `admin/profile/get`, method: 'get'
        }, getProfileResult);
    }, [getProfileResult, triggerAPI])

    const menuActionHandler = (e) => {
        switch (e.action) {
            case 'logout':
                logOutHandler()
                break;
            case 'profile':
                navigate("settings")
                break;
            case 'changePassword':
                navigate("settings/password")
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        getProfileDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    return (
        <div className="headerArea-wrap flex-1 flex justify-between items-center">
            <h3 className="pageTitle">{pageTitle}</h3>
            <div className="headerArea-wrap-right">
                <ul>
                    <li>
                        <img src={BellIcon} width="28px" height="26px" alt="notification" />
                    </li>
                    <li>
                        <ButtonAction layout="settings" menuAlign="right" className="settings" menu={actionMenu} src={Settings} onAction={menuActionHandler} />
                    </li>
                    <li>
                        <div className="headerArea-wrap-dp" style={{ backgroundImage: `url(${list?.profilePicUrl ?list?.profilePicUrl: Person})` }}>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Header;
import Button from "App/Components/UI/Button"
import FormatField from "App/Components/UI/FormatField";
import Image from "App/Components/UI/Image"
import StatusButton from "App/Components/UI/StatusButton";
import ToggleSwitch from "App/Components/UI/ToggleSwitch"
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteGallery from "../DeleteGallery";
import ViewDetails from "../ViewDetails";
import Images from "Assets/Images/frame-upload.png";

const GalleryDetailsHeader = (props) => {
    const { row } = props
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const navigate = useNavigate();
    const [list, setList] = useState(row);
    const [viewDetailsPopup, setViewDetailsPopup] = useState({ mode: false })
    const [viewDeletePopup, setViewDeletePopup] = useState({ mode: false })
    const [mainImage, setMainImage] = useState('')

    const viewDetailsHandler = () => {
        setViewDetailsPopup({ mode: true })
    }

    const closeViewDetailshandler = () => {
        setViewDetailsPopup({ mode: false })
    }

    const deleteHandler = () => {
        setViewDeletePopup({ mode: true })
    }

    const closeDeletePopup = (data) => {
        setViewDeletePopup({ mode: false })
        if (data) {
            getDeleteGallery()
        }
    }

    const toggleSwitch = (id, checked) => {
        getshowOnInfoDesk(id, checked);
    }

    const getshowOnInfoDeskResult = (res) => {
        setList((prev) => {
            return {
                ...prev,
                showOnHomePageDisplay: !list.showOnHomePageDisplay
            }
        })
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const apiResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        navigate("/dashboard/gallery-management/gallery")
    }, [dispatch, navigate])

    const apiResultVerify = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }, [dispatch])

    const getshowOnInfoDesk = (id, checked) => {
        if (props.NFTDrop) {
            dispatch('showSpinner');
            const data = {
                id: id,
                showOnHomePageDisplay: checked
            }
            triggerAPI({
                url: `nft-drop/display-status/update`, data, method: 'patch'
            }, getshowOnInfoDeskResult);
        }
        else {
            dispatch('showSpinner');
            const data = {
                id: id,
                showOnHomePageDisplay: checked
            }
            triggerAPI({
                url: `gallery/display-status/update`, data, method: 'patch'
            }, getshowOnInfoDeskResult);
        }

    }

    const getDeleteGallery = useCallback(() => {
        dispatch('showSpinner');
        const deleteData = {
            id: props.row?.id
        }
        triggerAPI({
            url: `gallery/admin/delete`, data: deleteData, method: 'delete'
        }, apiResult);
    }, [dispatch, apiResult, props.row?.id, triggerAPI])

    const verificationHandler = (e) => {
        getVerified(e)
    }

    const getVerified = useCallback((e) => {
        dispatch('showSpinner');
        let verifiedData = {};
        if (e.action === 'accept') {
            verifiedData = {
                id: props.row?.id,
                verificationStatusId: e.verificationStatusId
            }
        }
        else {
            verifiedData = {
                id: props.row?.id,
                verificationStatusId: e.verificationStatusId
            }
        }
        setList((prev) => {
            return {
                ...prev,
                verificationStatusId: e.verificationStatusId
            }
        })
        triggerAPI({
            url: `admin/user-nft-drop/verify`, data: verifiedData, method: 'post'
        }, apiResultVerify);
    }, [dispatch, apiResultVerify, props.row?.id, triggerAPI])

    useEffect(() => {
        setList(row)
        setMainImage(row?.files?.sliders[0])
    }, [row])

    return (
        <Fragment>
            <div className="gallery-details flex flex-wrap justify-between gap-6">
                <div className="flex gap-3">
                    <div className="wall-image-details" style={{
                        backgroundImage: `url(${list?.thumbnailBackground?.imageUrl})`
                    }}>
                        <Image src={mainImage ?? Images} alt="abc" className="gallery-details-image" style={{ 'maxWidth': '6.5rem', 'maxHeight': '6.5rem' }} />
                    </div>
                    <div className="mr-3 pl-3">
                        <div className="gallery-details-title">{list?.name}</div>
                        {!props.NFTDrop && <div className="flex gap-1 items-center">
                            <div className="gallery-details-name"> By</div>
                            <div className="gallery-details-value"> {list?.communityName || list?.user?.username}</div>
                        </div>}
                        {props.NFTDrop && <div className="flex gap-1 items-center">
                            <div className="gallery-details-name">{list?.hashtags?.map(((name, index) => <span>{(index ? ', ' : '') + `#` + name}</span>))}</div>
                        </div>}
                        <div className="flex flex-wrap gap-3 pt-5">
                            <div className="gallery-details-name gallery-details-box">Gallery Size:
                                <span className="gallery-details-value ml-2">{list?.assetsCount}</span>
                            </div>
                            {!props.NFTDrop && <div className="gallery-details-name ml-2">Gallery Type:
                                <span className="gallery-details-value ml-2">Regular</span>
                            </div>}
                            {props.NFTDrop && <div className="gallery-details-name ml-2">Start Date:
                                <span className="gallery-details-value ml-2">
                                    <FormatField type="date" value={list?.nftDropStartedAt} />
                                </span>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-between">
                    <div className="flex flex-wrap gap-3">
                        <Button label="View Details" className="transaction-cancel" onClick={viewDetailsHandler} />
                        {!props.NFTDrop && <Button label="Delete Gallery" className='button-delete' onClick={deleteHandler} />}
                        {props.NFTDrop && list?.verificationStatusId === 1 && <Fragment>
                            <Button label="Reject" className='button-delete' row={{ action: 'reject', verificationStatusId: 3 }} onClick={verificationHandler} />
                            <Button label="Accept" className='button-accept' row={{ action: 'accept', verificationStatusId: 2 }} onClick={verificationHandler} /></Fragment>
                        }

                        {props.NFTDrop && list?.verificationStatusId === 2 &&
                            <div className="nft-status" >
                                <StatusButton noBtn label='Approved' />
                            </div>
                        }
                        {props.NFTDrop && list?.verificationStatusId === 3 &&
                            <div className="nft-status" >
                                <StatusButton noBtn label='Rejected' />
                            </div>

                        }
                    </div>
                    <div>
                        <div className="flex justify-end gap-2 gallery-details-name pt-5">
                            <div> Homepage Display</div>
                            <ToggleSwitch className="gallery-switch" check={list?.showOnHomePageDisplay} row={list} onChange={toggleSwitch} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="pb-7 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-6 lg:grid-cols-5 sm:grid-cols-3 gap-4">
                {list?.files?.files?.map((assests, key) => (
                    <div className="flex flex-col pt-6" key={key}>
                        <div className="gallery-details-card flex justify-center items-center">
                            <Image src={assests} alt="images" />
                        </div>
                    </div>
                ))}
            </div>

            <ViewDetails row={list} page={props.NFTDrop ?? false} show={viewDetailsPopup} onClose={closeViewDetailshandler} />
            <DeleteGallery row={list} show={viewDeletePopup} onClose={closeDeletePopup} />

        </Fragment>
    )
}

export default GalleryDetailsHeader
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './CreatorCard.scss';
import { getBlockchainIcon } from 'App/helpers/blockchain';
import { Html2Canvas } from 'App/helpers/html2canvas';
import useCreatorScreenshot from 'App/hooks/use-CreatorScreenshot';
import { getBLOBUrl } from 'App/helpers/utilities';

const useSlicedImageList = (dataList, sliceCount) => {
    return useMemo(() => {
      let list = [], remainCount = 0;
      if (dataList?.length) {
        list = dataList.slice(0, sliceCount);
        remainCount = dataList.length - list.length;
      }
      return { list, remainCount };
    }, [dataList, sliceCount]);
  };

const CreatorCard = ({blockChains = null, coverImage = '', profileImage = '', collectionCount = 0, creatorName = '',
    pieces = 0, collectorCount = 0, collectionList = null, id, setScreenshotTaken}) => {
    const sliceCount = useRef(5);
    const { saveCreatorScreenShot } = useCreatorScreenshot();
    const collectionImageList = useSlicedImageList(collectionList, sliceCount.current);
    const blockChainsImageList = useSlicedImageList(blockChains, sliceCount.current);
    const [collectionImageLoadedCount, setCollectionImageLoadedCount] = useState(0);
    const onCollectionImageLoad = () => {
        setCollectionImageLoadedCount(prev => prev+1)
    }
    const screenShotResult = (blob) => {
        console.log(getBLOBUrl(blob));
        saveCreatorScreenShot({data: {communityId: id, thumbnailPictureFile: blob}, callBack: () => {
            setScreenshotTaken(true);
        }});
      }
    const generateScreenshot = useCallback(() => {
        const input  = document.getElementById(`creator-card`);
        Html2Canvas({input, backgroundColor: '#191D23', callback: screenShotResult, cloneId: 'creator-card'})          
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if(collectionImageLoadedCount === collectionImageList?.list?.length) {
            generateScreenshot();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionImageLoadedCount, collectionImageList?.list?.length])
    return (
        <div className='creator-card' id='creator-card'>
           <div className='head-section flex flex-col gap-[4rem] justify-between items-center w-full'>
            <div className='cover-image' style={{backgroundImage: `url(${coverImage})`, objectFit: 'cover'}}>
                    <div className='profile-image'>
                        <img src={profileImage} alt=''/>
                    </div>
                    <div className='blockchain-section'>
                        <div className='blockchain-container'>
                            {blockChainsImageList?.list?.map((blockchainIcon, index) => {
                                let showCount = (sliceCount?.current === (index + 1));
                                return  <div className='blockchain-image relative'>
                                                <img src={getBlockchainIcon(blockchainIcon)} alt=' ' className='w-full h-full' />

                                                {!!blockChainsImageList?.remainCount && showCount && 
                                                    <div className='count-text'>
                                                        {`+${blockChainsImageList?.remainCount}`}
                                                    </div>
                                                }
                                        </div>
                                }
                            )}
                        </div>
                    </div>
                </div>
                <div className='creator-name px-4'>
                    <p>{creatorName}</p>
                </div>   
            </div>
            <div className='body-section px-4'>
                             
                <div className='collection-image-section'>
                    <div className='collection-image-container'>
                        {collectionImageList?.list?.map((collection, index) => {
                            let showCount = (sliceCount?.current === (index + 1));
                            return  <div className='collection-image relative'>
                                            <img src={collection} alt=' ' className='w-full h-full' onLoad={onCollectionImageLoad} />

                                            {!!collectionImageList?.remainCount && showCount && 
                                                <div className='count-text'>
                                                    <div className='count-container'>
                                                        <span>{`+${collectionImageList?.remainCount}`}</span>
                                                    </div>                                                    
                                                </div>
                                            }
                                    </div>
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreatorCard;
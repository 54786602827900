import useHttp from "./use-http";
import shapesThree from "Assets/Images/shapes/shapesThree.svg";
import shapesSquare from "Assets/Images/shapes/shapesSquare.svg";
import shapesLandscape from "Assets/Images/shapes/shapesLandscape.svg";
import shapesPortrait from "Assets/Images/shapes/shapesPortrait.svg";
import shapesSquareLandscape from "Assets/Images/shapes/shapesSquareLandscape.svg";
import shapesSquarePortrait from "Assets/Images/shapes/shapesSquarePortrait.svg";
import shapesLandscapePortrait from "Assets/Images/shapes/shapesLandscapePortrait.svg";
import shapesEmpty from "Assets/Images/shapes/shapesEmpty.svg";

const useShapes = () => {    
    const { triggerAPI } = useHttp();

    const saveCollectionShape = async (collection, ratio) => {                                   
        triggerAPI(
            {
            url: `user/shape/add`,
            data: [
                    { 
                        collectionId: collection.id,
                        aspectRatio: ratio                        
                    } 
                ],
            method: "post",
            }
        );                    
    }    
    const getShapes = (ratios) => {
        let hasSquare = ratios?.filter(ar => ar===1).length>0;
        let hasLandscape = ratios?.filter(ar => ar>1).length>0;
        let hasPortrait = ratios?.filter(ar => ar!=="" && ar<1).length>0;    
        let colShape = shapesEmpty;
        if (hasSquare && !hasLandscape && !hasPortrait)
        {
            colShape = shapesSquare;
        }
        if (hasLandscape && !hasSquare && !hasPortrait)
        {
            colShape = shapesLandscape;
        }
        if (hasPortrait && !hasSquare && !hasLandscape)
        {
            colShape = shapesPortrait;
        }
        if (hasSquare && hasLandscape && !hasPortrait)
        {
            colShape = shapesSquareLandscape;
        }
        if (hasSquare && hasPortrait && !hasLandscape)
        {
            colShape = shapesSquarePortrait;
        }
        if (hasPortrait && hasLandscape && !hasSquare)
        {
            colShape = shapesLandscapePortrait;
        }
        if (hasPortrait && hasLandscape && hasSquare)
        {
            colShape = shapesThree;
        }
        return colShape;   
    }
    return {
        saveCollectionShape,
        getShapes        
    }
}
export default useShapes
import { Fragment, useEffect, useRef, useState } from "react"

const ButtonAction = (
    { src,
        menu = [],
        row = {},
        onAction,
        layout = 'grid',
        className = '',
        menuAlign = 'left',
        apply,
        onApplyHandler,
        onApplyClose,
        position = { left: 0, right: 0, top: 0 }
    }) => {
    const btnRef = useRef();
    const menuRef = useRef();
    const [activeTab, setActiveTab] = useState(false)
    const [visibility, setVisibility] = useState(false);
    const menuOpen = () => {
        setVisibility(true);
    }
    const menuClose = () => {
        setVisibility(false);
    }
    const triggerAction = (e) => {
        const { dataset } = e.currentTarget;
        const action = {
            action: dataset.action,
            row
        }
        onAction(action);
       !apply && menuClose();
    }

    const onApply=()=>{
        onApplyHandler();
        menuClose();
    }

    useEffect(() => {
        const checkActive = menu?.some((val) => val.selected)
        setActiveTab(checkActive)
    }, [menu])

    useEffect(() => {
        const rect = btnRef.current.getBoundingClientRect();
        if (menuRef.current) {
            const top = rect.top + btnRef.current.clientHeight + 10;
            const base = window.getComputedStyle(document.body).fontSize.replace('px', '')
            const calcTop = (top + position.top) / Number(base)
            if (menuAlign === 'left') {
                const left = (rect.left + btnRef.current.clientWidth) - menuRef.current.clientWidth;
                const calcLeft = (left + position.left) / Number(base)
                menuRef.current.style.left = `${calcLeft}rem`;
            } else {
                const right = (window.innerWidth + btnRef.current.clientWidth) - (menuRef.current.clientWidth + rect.right);
                const calcRight = (right + position.right) / Number(base)
                menuRef.current.style.right = `${calcRight}rem`;
            }
            menuRef.current.style.top = `${calcTop}rem`;
        }
    }, [menuAlign, position.left, position.right, position.top, visibility])
    return (
        <Fragment>
            <div className={`btn-action ${className} ${activeTab && 'menu-active'}`} ref={btnRef} onClick={menuOpen}>
                <img src={src} alt="ico" width="32px" height="32px" />
            </div>
            {visibility && <Fragment>
                <div className="dropdown-menu-overlay" onClick={menuClose}></div>
                <div className={` ${className} dropdown-menu-list`} ref={menuRef}>
                    {menu?.length !== 0 && <ul>
                        {menu?.map(mnu => (<li key={mnu.label} className={mnu?.selected ? 'mnu-active' : ''} data-action={mnu.action} onClick={triggerAction}>
                            <div className="flex items-center gap-3">
                                {layout === 'grid' && mnu.src && <img className="dropdown-menu-list-icon" src={mnu.src} width="12px" height="12px" alt="menuIcon" />}
                                <span className={`dropdown-menu-list-label ${mnu?.className}`}>{mnu.label}</span>
                            </div>
                        </li>))}
                    </ul>}
                    {!!apply&&<button className="apply" onClick={onApply}>Apply</button>}
                </div>
            </Fragment>}

        </Fragment>
    )
}

export default ButtonAction
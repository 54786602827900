
const colors = {
    color: "#373943",
    borderColor: '#7D7E84',
    backgroundColor: "rgba(75,192,192,0.2)",
    borderColorGraph: "#33BACC",
    barGraphBackgroundColor: "#7151F0"
}

const getConfig = (mode = { showX: false, valueYear: '' }) => {
    const options = {

        responsive: true,
        plugins: {

            tooltip: {
                callbacks: {
                    title: function () {
                        return '';
                    },
                    label: function (tooltipItem) {
                        return tooltipItem.formattedValue;
                    },

                },
                padding: 10,
                yAlign: "bottom",
            },
            legend: {
                display: false
            },

        },
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 50,
                ticks: {
                    stepSize: 5,
                    padding: 15
                },

                grid: {
                    color: colors.color,
                    borderColor: colors.borderColor,
                    drawTicks: false,

                },
            },

            x: {
                ticks: {
                    padding: 15
                },
                grid: {
                    color: `${mode.showX ? colors.color : ''}`,
                    borderColor: colors.borderColor,
                    drawTicks: false
                },
            }


        },
        barThickness: 10,
    };
    return options
}

export const LineGraph = {
    getConfig,
    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

    colors
}

import "./TextArea.scss";

const TextArea = (props) => {
    const { label,rows,required=true,value="" } = props;
    const onChangeHandler = (e) => {
        const label = props.controlName ?? 'value'
        const value = {
            [label]: e.target.value
        }

        if (props.onChange) {
            props.onChange(value)
        }
    }
    const onBlurHandler = (e) => {
        if (props.validateOnBlur) {
            e.target.value = e.target.value ?? '';
            onChangeHandler(e);
        }
    }

    return (
        <div className={props.className}>
            <label className="form-label">{label} {props?.validateOnBlur && required ? '*' : ''}</label>
            <textarea rows={rows} className="textArea p-5" maxLength={props.maxLength} onChange={onChangeHandler} onBlur={onBlurHandler} value={value} />
            {props?.showTextCount && value && <div className="textarea-count text-right text-[0.625rem]">{value.length}/{props.maxLength}</div>}
        </div>

    )
}

export default TextArea
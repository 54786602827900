import Twitter from "Assets/Images/Twittersvg.svg";
import Discord from "Assets/Images/DiscordSVG.svg";
import Website from "Assets/Images/globe.svg";
import Instagram from "Assets/Images/instagramlarge.png";
import Mail from "Assets/Images/mailIcon.svg";
import Youtube from "Assets/Images/youtube.png";

import "./SocialMedia.scss";

const SocialMedia = (props) => {
  const onSrcHandler = (type) => {
    switch (type) {
      case "twitter":
        return <img src={Twitter} alt="images" />;
      case "instagram":
        return <img src={Instagram} alt="images" />;
      case "website":
        return <img src={Website} alt="images" />;
      case "discord":
        return <img src={Discord} alt="images" />;
      case "youtube":
        return <img src={Youtube} alt="images" />;
      case "email":
        return <img src={Mail} alt="images" />;
      default:
        return <img src={Website} alt="images" />;
    }
  };
  return (
    <div className="flex gap-2 socialmedia">
      <a href={props?.type==="email"?`mailto:props?.link`:props?.link} target={props?.type==="email"?"_self":"_blank"} rel="noopener noreferrer">
        {onSrcHandler(props?.type)}
      </a>
    </div>
  );
};

export default SocialMedia;

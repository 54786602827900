import validationCheck from "App/helpers/validationCheck";
import React, { Fragment, useCallback, useEffect, useState } from "react";

const Validators = React.memo(({ value, type, message, controlName, onErrors, matchValue = '', validators = [], multiple, minValue,maxValue }) => {
    const [error, setError] = useState({ [type]: null });
    const [multiError, setMultiError] = useState(validators)
    const validate = useCallback((type, message) => {
        let errors = null;
        if (value !== undefined) {
            switch (type) {
                case 'Required':
                    if (!value || !value.toString().trim()) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Alphamuneric':
                    if (!!value?.toString().trim() && validationCheck({ type: 'alphamuneric', value })) {
                        errors = {
                            [type]: message
                        }
                    }

                    break;
                case 'Email':
                    if (!!value && validationCheck({ type: 'email', value })) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Match':
                    if (!!value && value !== matchValue) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'NotMatch':
                    if (!!value && value === matchValue) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'Required Array':
                    if (!value || value.length === 0) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                    case 'url':
                        if (!!value && validationCheck({ type: 'url', value })) {
                            errors = {
                                [type]: message
                            }
                        }
                        break;
                case 'RequiredAny':
                    if ((!value) && (!matchValue)) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'minValue':
                    if (minValue && minValue > value) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                case 'maxValue':
                    if (maxValue && maxValue < value) {
                        errors = {
                            [type]: message
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return errors
    }, [value, matchValue, minValue, maxValue])
    useEffect(() => {
        if (validators.length) {
            let result = null
            const mErrors = [];
            validators.map((v) => {
                const exec = validate(v.type, v.message);
                if (exec) {
                    result = { ...result, ...exec }
                    mErrors.push({ type: v.type, message: v.message })
                }
                return exec
            })
            setMultiError(mErrors)
            onErrors({ [controlName]: result })
            setError(result)
        } else {
            const exec = validate(type, message);
            onErrors({ [controlName]: exec })
            setError(exec)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlName, onErrors, validate, type, message])
    return (
        <Fragment>
            <div className="error-validation">
                <Fragment>
                    {error && !validators.length && (
                        <p>
                            {error[type]}
                        </p>
                    )}
                </Fragment>

                <Fragment>
                    {
                        multiError.map((x, i) =>
                        (multiple ? <p key={i}>
                            {x.message}
                        </p> :
                            (i === 0 && <p key={i}>{x.message}</p>))

                        )
                    }
                </Fragment>
            </div>
        </Fragment>
    )
})
export default Validators;
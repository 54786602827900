import Button from "App/Components/UI/Button"
import Card from "App/Components/UI/Card"
import InputBox from "App/Components/UI/Forms/InputBox"
import Validators from "App/Components/UI/Forms/Validators";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const Password = () => {
    const [formVaildState, setFormVaildState] = useState({});
    const formGroup = {
        oldPassword: {
            value: undefined,
            isRequired: true
        },
        newPassword: {
            value: undefined,
            isRequired: true
        },
        confirmPassword: {
            value: undefined,
            isRequired: true
        }
    }
    const [formValues, setFormvalues] = useState(getInitial(formGroup));
    let navigate = useNavigate();
    const dispatch = useStore(false)[1];

    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const { triggerAPI } = useHttp();

    const changePasswordHandler = () => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        if (valid) {
            const data = {
                oldPassword: formValues.oldPassword,
                newPassword: formValues.confirmPassword,
            }
            dispatch('showSpinner');
            triggerAPI({
                url: 'admin/profile/change-password', data, method: 'patch'
            }, apiResult);
        } else {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
    }

    const cancelHandler = () => {
        navigate("/dashboard/settings/profile");
    }

    const inputOnChange = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <p className='page-sub-title'>Password</p>
                    <p className="password-sub">Change Password</p>
                </div>
                <div className="flex gap-3">
                    <Button label="Cancel" className="transaction-cancel" onClick={cancelHandler} />
                    <Button label="Save Password" onClick={changePasswordHandler} />
                </div>
            </div>
            <div className="pt-5">
                <Card>

                    <div className="password-card">
                        <div className="password-current-password">
                            <InputBox label="Enter Current Password" type="password" validateOnBlur controlName="oldPassword" onChange={inputOnChange} value={formValues.oldPassword}/>
                            <Validators type="Required" value={formValues.oldPassword} message="Required field" controlName="oldPassword" onErrors={collectErrors} />
                        </div>

                        <div className="flex justify-between gap-6 flex-wrap pt-6">
                            <div className="password-form-field">
                                <InputBox label="Enter New Password" type="password" validateOnBlur controlName="newPassword" onChange={inputOnChange} value={formValues.newPassword}/>
                                <Validators type="Required" value={formValues.newPassword} message="Required field" controlName="newPassword" onErrors={collectErrors} />
                                <Validators type="NotMatch" value={formValues.newPassword} matchValue={formValues.oldPassword} message="Old Password and new password should be different" controlName="confirmPassword" onErrors={collectErrors} />

                            </div>
                            <div className="password-form-field">
                                <InputBox label="Confirm Password" type="password" validateOnBlur controlName="confirmPassword" onChange={inputOnChange} config value={formValues.confirmPassword}/>
                                <Validators type="Required" value={formValues.confirmPassword} message="Required field" controlName="confirmPassword" onErrors={collectErrors} />
                                <Validators type="Match" value={formValues.confirmPassword} matchValue={formValues.newPassword} message="Password and confirm password should be same" controlName="confirmPassword" onErrors={collectErrors} />
                            </div>
                        </div>

                    </div>
                </Card>

            </div>
        </div>
    )
}

export default Password
import Popup from "App/Components/UI/Popup"
import User from "App/Components/UI/User"
import "./ViewDetails.scss"
const ViewDetails = ({ show, row }) => {
    return (
        <Popup show={show} title="User Details" cssClass="details-user" style={{ 'maxWidth': '53.78rem' }}>
            <div className="user-details">
                <User src={row.profilePicUrl} label={row.name} email={row.email} className="wallet-profile" />
            </div>
            <div className="user-details mt-2">
                <div className="flex flex-wrap gap-7 user-values pb-2">
                    <div className="flex user-details-name justify-between">
                        <div>Nationality</div>
                        <div>:</div>
                    </div>
                    <span>
                        {row.nationality}
                    </span>
                </div>
                <div className="flex flex-wrap gap-7 user-values py-2">
                    <div className="flex user-details-name justify-between">
                        <div>
                            Hashtags
                        </div>
                        <div>:</div>
                    </div>

                    <span>
                        {row.hashtags}
                    </span>
                </div>
                <div className="flex flex-wrap gap-7 user-values py-2">
                    <div className="flex user-details-name justify-between">
                        <div>
                            Instagram
                        </div>
                        <div>:</div>
                    </div>
                    <span>
                        {row.instagram}
                    </span>
                </div>
                <div className="flex flex-wrap gap-7 user-values py-2">
                    <div className="flex user-details-name justify-between">
                        <div>
                            Twitter
                        </div>
                        <div>:</div>
                    </div>
                    <span>
                        {row.twitter}
                    </span>
                </div>
                <div className="flex flex-wrap gap-7 user-values py-2">
                    <div className="flex user-details-name justify-between">
                        <div>
                            Youtube
                        </div>
                        <div>:</div>
                    </div>
                    <span>
                        {row.youtube}
                    </span>
                </div>
                <div className="flex flex-wrap gap-7 user-values py-2">
                    <div className="flex user-details-name justify-between">
                        <div>
                            Website
                        </div>
                        <div>:</div>
                    </div>
                    <span>
                        {row.website}
                    </span>
                </div>
                <div className="flex flex-wrap gap-7 user-values py-2">
                    <div className="flex user-details-name justify-between">
                        <div>
                            Description
                        </div>
                        <div>:</div>
                    </div>
                    <span>
                        {row.description}
                    </span>
                </div>
            </div>
        </Popup>
    )
}

export default ViewDetails
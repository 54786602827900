import { useEffect, useState } from "react";
import "./StatusButton.scss";
const StatusButton = ({ label, noBtn, onClick, row = {} }) => {
    const [className, setClassName] = useState('');
    const clickHandler = () => {
        if (!noBtn) {
            onClick(row);
        }
    }
    useEffect(() => {
        let name = '';
        switch (label) {
            case 'Approve':
                name = 'status-button-approve';
                break;
            case 'Reject':
            case 'Delete':
                name = 'status-button-reject';
                break;

            case 'Approved':
                name = 'button button-approved';
                break;
            case 'Rejected':
                name = 'button button-rejected';
                break;
            default:
                break;
        }
        setClassName(name);
    }, [label])
    return (
        <div>
            <button onClick={clickHandler} className={`status-button ${noBtn ? 'no-pointer' : ''} ${className}`}>
                {label}</button>
        </div>
    )
}

export default StatusButton
import axios from "axios";
import storage from "./storage";
const baseURL = process.env.REACT_APP_BASE_URL

const API = ({ url = 'noUrl', data = {}, method = 'get' || 'post' || 'put' || 'delete' }) => {
    const token = storage().get('token')
    const headers = {
        Authorization: `Bearer ${token}`
    }
    return axios({
        method,
        url: `${baseURL}/${url}`,
        data,
        headers
    })
}
export default API;
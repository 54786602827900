import PageTab from "App/Components/UI/PageTab";
import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const tabs = [
//     {
//     link: 'wall-mono',
//     label: 'Mono Wall'
// },
 {
    link: 'wall-multi',
    label: 'Multi Wall'
}]
const BackgroundWall = () => {
    const dispatch = useStore(false)[1];
    useEffect(() => {
        dispatch('setPageTitle', 'Background Wall Management')
    }, [dispatch])
    return (
        <div >
            <PageTab tabs={tabs} />
            <div className="py-4">
                <Outlet />
            </div>
        </div>
    )
}
export default BackgroundWall;
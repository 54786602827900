import './TermsTable.scss';
const TermsTable = (props) => {
 return (
    <div className="flex w-full gap-4 collection-terms-container flex-col"> 
        <table className="collection-terms-table">
            <thead>
                <tr>
                    <th className='text-left'>License </th>
                    <th>Copy, Display & Distribute </th>
                    <th>Sublicense </th>
                    <th>Commercial Use </th>
                    <th>Modify & Adapt </th>
                    <th>All  Speech Permitted </th>
                    <th>Creator Retains no Rights </th>
                </tr>
            </thead>
            <tbody>
                {props?.termsList?.map((terms) => {
                    return (
                        <tr className='' key={terms?.id}>
                            <th className='flex row-head'>
                                <div className="overall-round round relative">
                                    <input type="checkbox" 
                                        id={`checkbox${terms?.id}`} 
                                        checked={props?.values === terms?.id}
                                        data-id={terms?.id} onChange={props?.handleTerms} />
                                    <label htmlFor={`checkbox${terms?.id}`}></label>
                                </div>
                                <span>{terms?.liscenceName}</span>
                            </th>
                            <td className=''>
                                {terms?.copyDisplayDistribute && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.subliscence && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.commercialUse && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.modifyAdapt && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.allSpeechPermitted && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>
                            <td className=''>
                                {terms?.creatorRetainNoRights && 
                                    <div className="round relative">
                                        <input type="checkbox" id="" defaultChecked={true} readOnly />
                                        <label htmlFor=""></label>
                                    </div>
                                }
                            </td>                        
                        </tr>
                    )
                })}
            </tbody>
        </table>
        {/* <div className='px-[1.5rem] py-[1rem] terms-details flex flex-col gap-4'>
            <div className='flex gap-[2rem]'>
                <div className='text-head'>
                    <span>
                        Copy, Display & Distribute -
                    </span>
                </div>
                <div className='text-body'>
                    <span>
                        You can display, distribute, and copy the artwork of your NFT.
                    </span>
                </div>
            </div>
            <div className='flex gap-[2rem]'>
                <div className='text-head'>
                    <span>
                        Sublicense -
                    </span>
                </div>
                <div className='text-body'>
                    <span>
                        You can sublincese your rights to the artwork of your NFT or 
                        your modifications and adaptations (if permitted).
                    </span>
                </div>
            </div>
            <div className='flex gap-[2rem]'>
                <div className='text-head'>
                    <span>
                        Commercial Use - 
                    </span>
                </div>
                <div className='text-body'>
                    <span>
                        You can use the artwork for your NFT for commercial purposes.
                    </span>
                </div>
            </div>
            <div className='flex gap-[2rem]'>
                <div className='text-head'>
                    <span>
                        Modify & Adapt -  
                    </span>
                </div>
                <div className='text-body'>
                    <span>
                        You can modify and adapt the artwork for your NFT.
                    </span>
                </div>
            </div>
            <div className='flex gap-[2rem]'>
                <div className='text-head'>
                    <span>
                        All Speech Permitted - 
                    </span>
                </div>
                <div className='text-body'>
                    <span>
                        You may use the artwork of your NFT in any speech. 
                        Licenses without a check enable the prohibition of 
                        use of the artwork of your NFT in a way that is 
                        unlawful, defamatory, harassing, abusive, fradulent, 
                        racist, hateful, vulgar, cruel, illegal or obscene, 
                        or that promotes any such activity.
                    </span>
                </div>
            </div>
            <div className='flex gap-[2rem]'>
                <div className='text-head'>
                    <span>
                        Creator Retains No Rights
                    </span>
                </div>
                <div className='text-body'>
                    <span>
                        You have exclusive rights to the artwork for your NFT 
                        and the creator retains no right to exploit it.
                    </span>
                </div>
            </div>
        </div> */}
    </div>
 )
}

 export default TermsTable;
import userAvatarHover from "Assets/Icons/avatar_hover.svg";
let counter = 0;
const dragEnterStyle = (ref,id) => {
    document.getElementById(id).style.background="red !important"
    document.getElementById(id).style.backgroundImage=`url(${userAvatarHover})`
    document.getElementById(id).style.backgroundRepeat="no-repeat"
    document.getElementById(id).style.backgroundSize="32px 32px"
    document.getElementById(id).style.backgroundPosition= "50% 50%";
    for (let i = 0, childNode; i <= ref.current?.childNodes?.length; i++) {
      childNode = ref.current.childNodes[i];
      if(childNode) childNode.style.display = "none";
    }
    
};

const dragLeaveStyle = (ref,id) => {
    document.getElementById(id).style.backgroundImage=`none`
    for (let i = 0, childNode; i <= ref.current.childNodes?.length; i++) {
        childNode =ref.current.childNodes[i];
        if(childNode)  childNode.style.display = "block";
      }
      
};
export const handleDragOver = (event) => {
  event.preventDefault();
};
export const handleDragLeave = (event, ref,id) => {
  event.preventDefault();
  if (ref !== null && ref.current !== null) {
    counter--;
    if (counter === 0) {
      dragLeaveStyle(ref,id);
    }
  }
};
export const handleDragEnter = (event, ref,id) => {
  event.preventDefault();

  if (ref !== null && ref.current !== null) {
    counter++;
    dragEnterStyle(ref,id);
  }
};
export const handleDrop = (event, ref, onChange, controlName,id) => {
  event.stopPropagation();
  event.preventDefault();
  const label = controlName ?? "value";
  if (event.dataTransfer.files) {
    const selectedFile = event.dataTransfer.files[0];
    let value = {
      [label]: selectedFile,
    };
    onChange(value);
    dragLeaveStyle(ref,id);
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./ToggleAccordion.scss";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";

const ToggleAccordion = ({ list, onToggle }) => {
  const onToggleChange = (id, checked) => {
    onToggle(id, checked);
  };
  return (
    <div className="wrap toggle-accordion">
      <div className="container">
        <div className="flex">
          <div className="">
            <div className="nav column nav-pills">
              {[...list]?.map((val) => {
                return (
                  <div
                    key={val?.id}
                    data-id={val?.label}
                    className={"nav-link active"}
                  >
                    {val.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="">
            <div className="tab-content">
              {[...list]?.map((e) => {
                return (
                  <div key={e?.id} className={"tab-pane fade show active"}>
                    <ToggleSwitch
                      className="role-switch"
                      check={e?.isEnabled}
                      row={e}
                      onChange={onToggleChange}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ToggleAccordion;

import { useEffect, useState } from "react";
import arrow from "Assets/Images/arrow-gray.svg";
import CheckBox from "App/Components/UI/CheckBox";

const SubMenu = ({menuData, children, selected = [], onCheckClick = () => {}}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const toggleOpen = () => {
      setIsOpen(!isOpen);
      if(!menuData?.subcategories?.length) handleClick(!isSelected)
    };
    useEffect(() => {
        if(selected)
            setIsSelected(selected?.includes(menuData?.id));
    }, [menuData, selected])
    const handleClick = (e) => {
        const temp = {checked: e, id: menuData?.id,data:menuData};
        onCheckClick(temp)
        
    }
    const preventCheckboxClickPropogate = (e) => {
        e.stopPropagation();        
    }
    return (
          <li className={`flex flex-col ${isSelected && 'selected'}`} key={menuData?.id}>
                    <div className={`flex w-full items-center justify-between gap-6`} onClick={toggleOpen}>
                        <div className="flex gap-4 items-center">
                            <div onClick={preventCheckboxClickPropogate}><CheckBox checked={isSelected} greyCheck={true} onClick={handleClick} /></div>                                                               
                            <div className="item-name" >{menuData?.name}</div>
                        </div>                                      
                        {!!menuData?.subcategories?.length && <div className={`item-arrow ${isOpen ? 'open-arraow' : ''}`}><img src={arrow} alt="arrow" /></div>}
                    </div>
                    {(isOpen && !!menuData?.subcategories?.length) &&
                        <ul className="drop-down-items w-full" key={menuData?.id}>
                            {children}
                        </ul>                            
                    }
                </li>
      );
}
export default SubMenu;
import ButtonAction from "App/Components/UI/ButtonAction";
import EmbedVideo from "App/Components/UI/EmbedVideo";
import Image from "App/Components/UI/Image";
import { aspectRatio } from "App/helpers/utilities";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import "./ContentUpload.scss";

const ContentUpload = ({ src, icon, width, height, style, disableDragging, showData, onAction, list, row, className, menu, datainfo, videoUrl, upload, onChange, onFramePosUpdate, metaInfo, dummyImage }) => {
    const defaultConfig = {
        x: 10,
        y: 10,
        width: 150,
        height: 150,
    }
    const [mprConfig, setMprConfig] = useState(defaultConfig)
    const mapOuter = useRef()
    const imgSrc = src ?? ''
    const onDragStopHandler = (e, pos) => {
        const { x, y } = pos;
        setMprConfig((prev) => {
            return {
                ...prev, x, y
            }
        })
    }
    const onResizeHandler = (e, direction, ref, delta, pos) => {
        const { x, y } = pos;
        const { offsetWidth: width, offsetHeight: height } = ref;
        setMprConfig((prev) => {
            return {
                ...prev,
                width, height, x, y
            }
        })
    }
    const emitFrameDim = useCallback(() => {
        const { offsetWidth: width, offsetHeight: height } = mapOuter.current;
        const xPercent = mprConfig.x / width * 100;
        const yPercent = mprConfig.y / height * 100;
        const widthPercent = mprConfig.width / width * 100;
        const heightPercent = mprConfig.height / height * 100;
        const imgAspct = aspectRatio(mprConfig.width, mprConfig.height);
        const obj = {
            frame: {
                width,
                height,
                aspectRatio: aspectRatio(width, height)
            },
            image: {
                ...mprConfig,
                xPercent,
                yPercent,
                widthPercent,
                heightPercent,
                aspectRatio: imgAspct
            }
        }
        if (onFramePosUpdate) {
            onFramePosUpdate(obj)
        }
    }, [mprConfig, onFramePosUpdate])
    const buildFrame = (frameMeta, outerMeta) => {
        const { xPercent, yPercent, widthPercent = 0, heightPercent = 0 } = frameMeta;
        const { offsetWidth, offsetHeight } = outerMeta;
        const calcWidth = Math.ceil(offsetWidth / 100 * widthPercent);
        const calcHeight = Math.ceil(offsetHeight / 100 * heightPercent);
        const calcX = Math.ceil(offsetWidth / 100 * xPercent);
        const calcY = Math.ceil(offsetHeight / 100 * yPercent);
        const obj = { x: calcX, y: calcY, width: calcWidth, height: calcHeight };
        return obj
    }
    useEffect(() => {
        emitFrameDim();
    }, [emitFrameDim, imgSrc]);
    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const obj = buildFrame(metaInfo.image, mapOuter.current);
            setMprConfig(obj)
        })
        if (metaInfo) {
            resizeObserver.observe(mapOuter.current)
        }
        return () => {
            resizeObserver.disconnect();
        }
    }, [metaInfo])

    const stopHandler = (e) => {
        if (e.currentTarget?.dataset.info) {
            const value = list.infoDeskContents.find(val => val.id === Number(e.currentTarget?.dataset.info))
            showData && showData(value)

        }
        else {
            showData && showData({})

        }
        e.stopPropagation();
    }
    return (
        <div className={`${className}`}>
            <div className="upload-image content-menu">
                <div className="video-wrapper" ref={mapOuter}>
                    {videoUrl && (<Rnd
                        className="video-mapper"
                        default={defaultConfig}
                        onDragStop={onDragStopHandler}
                        onResize={onResizeHandler}
                        size={{ width: mprConfig.width, height: mprConfig.height }}
                        position={{ x: mprConfig.x, y: mprConfig.y }}
                        disableDragging={disableDragging ? true : false}
                        enableResizing={disableDragging ? { top: false, right: false, bottom: false, left: false } : { top: true, right: true, bottom: true, left: true }}
                    >

                        {videoUrl && <EmbedVideo src={videoUrl} className="content-video" alt="images" />}
                    </Rnd>)}
                    <Image dummyImage={dummyImage} src={imgSrc} width={width} height={height}
                        style={style} />
                </div>
                {icon &&
                    <div className="icon" onClick={stopHandler} data-info={datainfo ?? ''}>
                        <ButtonAction row={row} menu={menu} src={icon} onAction={onAction} />
                    </div>
                }
            </div>

        </div >
    )
}


export default ContentUpload
import configureProfileStore from "App/hooks-store/profile-store";
import configureSpinnerStore from "App/hooks-store/spinner-store";
import configureTitleStore from "App/hooks-store/title-store";
import configureToastStore from "App/hooks-store/toast-store";
import { Fragment } from "react";

const Store = (props) => {
    configureSpinnerStore();
    configureToastStore();
    configureTitleStore();
    configureProfileStore();
    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}
export default Store;
import { useNavigate } from "react-router-dom";
import storage from "App/helpers/storage"
import { useCallback } from "react";
const useAuth = () => {
    let navigate = useNavigate();
    const store = storage();
    const token = store.get('token') ?? '';
    const clearToken = useCallback(() => {
        store.remove('token');
        navigate("/", { replace: true });
    },[navigate, store])
    return [token, clearToken]
}
export default useAuth;
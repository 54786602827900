import Button from "App/Components/UI/Button"
import Pagination from "App/Components/UI/Pagination"
import SearchFilter from "App/Components/UI/SearchFilter"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "../../Components/FrameManagement.scss"
import ViewFrames from "../../Components/ViewFrames"
import FreeFramesCard from "./FreeFramesCard"
import { LIST_LIMIT } from "App/constants"

const paginationConfig = {
    perPage: LIST_LIMIT
}

const FreeFrames = () => {
    const dispatch = useStore(false)[1];
    const [list, setList] = useState()
    const { triggerAPI } = useHttp();
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [totalPage, setTotalPage] = useState(1);
    const [selectedData, setSelectedData] = useState({})
    const [viewFreeFrames, setViewFreeFrames] = useState({ mode: false })
    const [selectedDate, setSelectedDate] = useState('')
    const [search, setSearch] = useState('')
    const [initial, setInitial] = useState(true);
    const [freeFramesMenu, setFreeFramesMenu] = useState([
        {
            label: 'By name, A to Z',
            action: 'nameAsc',
            selected: false,
            type: 'name',
        },
        {
            label: 'By name, Z to A',
            action: 'nameDesc',
            selected: false,
            type: 'name',
        },
        {
            label: 'Date Range',
            action: 'dateRange',
            selected: false,
            popup: true
        }
    ])
    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }

    const getFreeFrameResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)

    }, [dispatch])
    const viewFrames = (e, data) => {
        setSelectedData(data)
        if (e.action === "edit") {
            navigate(`edit/${data.id}`)
        }
        else {
            setViewFreeFrames({ mode: true })

        }

    }
    const onFilterData = (e) => {
        setSelectedDate(e)
    }
    const searchHandler = (e) => {
        setSearch(e)
    }
    const editFreeFrames = (e) => {
        navigate(`edit/${e}`)
    }
    const closeFreeFrames = () => {
        setViewFreeFrames({ mode: false })
    }

    const getFreeFrames = useCallback(() => {
        dispatch('showSpinner');
        const data = new URLSearchParams({
            page: page,
            items: paginationConfig.perPage
        })
        const filter = selectedDate
        triggerAPI({
            url: `frame-asset/free/get?${data}`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getFreeFrameResult);
    }, [dispatch, getFreeFrameResult, page, search, selectedDate, triggerAPI])


    const CreateFreeFramesHandler = () => {
        navigate("create")
    }

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getFreeFrames();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, selectedDate])

    useEffect(() => {
        if (!initial) {
            getFreeFrames();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 100, top: 10 }} setActionMenu={setFreeFramesMenu} onSearch={searchHandler} actionMenu={freeFramesMenu} onFilterData={onFilterData} data={selectedDate} />
                </div>
                <div>
                    <Button label="Create Frame" onClick={CreateFreeFramesHandler} />
                </div>
            </div>
            <div className="grid frame-list-container gap-5">

                {list?.map((freeFrames) =>
                    <div className="nft-frames-card free-frames-pic cursor-pointer" key={freeFrames?.id}>
                        <FreeFramesCard row={freeFrames} key={freeFrames.id} onClickHandler={viewFrames} />

                    </div>)}
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>
                }
            </div>
            <div className="mt-6">
                <Pagination itemsPerPage={paginationConfig.perPage} rows={list?.length} total={totalPage} onPageChange={onPageChangeHandler} selected={page} />
            </div>
            <ViewFrames row={selectedData.id} show={viewFreeFrames} free onClose={closeFreeFrames} onEdit={editFreeFrames} />
        </div >
    )
}

export default FreeFrames
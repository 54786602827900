import "../Image/Image.scss"
import ButtonAction from "../ButtonAction";

const BackgroundImage = (props) => {
    const stopHandler = (e) => {
        if (e.currentTarget?.dataset.info) {
            const value = props.list.infoDeskContents.find(val => val.id === Number(e.currentTarget?.dataset.info))
            props?.showData && props.showData(value)

        }
        else {
            props?.showData && props.showData({})

        }
        e.stopPropagation();
    }

    return (
        <div className={`${props.className} image-container`} style={props.style}>
            <div className="image" style={props.newStyle}>
            </div>
            {props.icon &&
                <div className="icon" onClick={stopHandler} data-info={props.datainfo ?? ''}>
                    <ButtonAction row={props?.row} menu={props.menu} src={props.icon} onAction={props.onAction} />
                </div>
            }
        </div>
    )
}

export default BackgroundImage
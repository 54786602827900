
import Button from "App/Components/UI/Button";
import Validators from "App/Components/UI/Forms/Validators";
import { useMemo, useState, useEffect, useCallback } from "react";
import { getInitial, markAsTouched, validateFormGroup } from "App/helpers/formValidation";
import { useStore } from "App/hooks-store/store";
const UploadKeyFilesPopUp = ({onClose, onUploadFiles, data}) => {
    const dispatch = useStore(false)[1];
    const formGroup = useMemo(() => ({
        vkey: {
            value: undefined,
            isRequired: true
        },
        skey: {
            value: undefined,
            isRequired: true
        },
        projectId: {
            value: data?.projectId,
            isRequired: true
        },
        paymentgatewaysalestart: {
            value: new Date(),
            isRequired: true
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])
    const [formValues, setFormvalues] = useState(getInitial(formGroup))
    const [formVaildState, setFormVaildState] = useState({});
    const [isValid, setIsValid] = useState(false)
    const [touched, setTouched] = useState(false)

    const handleFileSelect = (e) => {
        const file = e.target.files && e.target.files[0];
        const name = e.currentTarget.name;
        if(file) {
            const extenArr = file?.name.split('.');
            if(extenArr.length && extenArr[1].toLowerCase() === name) {
                setFormvalues((prev) => {
                    return {
                        ...prev,
                        [name]: file,
                    }
                })
            } else {
                dispatch('showToast', { toast: { toastMode: 'error', message : `Select ${name} File` } })
            }            
        }
        
    }
    useEffect(() => {
        const { valid } = validateFormGroup({ errors: formVaildState, formGroup, values: formValues });
        setIsValid(valid)
    }, [formValues, formVaildState, formGroup])
    const collectErrors = useCallback((error) => {
        setFormVaildState((prev) => {
            return {
                ...prev,
                ...error
            }
        })
    }, [])
    useEffect(() => {
        if (touched) {
            const newValues = markAsTouched(formValues);
            setFormvalues(newValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [touched])
    
    const handleUploadFile = () => {
        if (isValid) {      
            onUploadFiles(formValues); 
        }
        else {
            setTouched(true)
        }
    }
    const removeFile = (e) => {
        setFormvalues((prev) => {
            return {
                ...prev,
                [e.currentTarget?.dataset?.info]: undefined
            }
        })
    }
    return (
        <div className="">
            <div className="upload-file-container">
                <div className="upload-file-item">
                    {formValues.vkey === undefined || !formValues.vkey ? 
                        <>
                            <label> 
                                <input name="vkey" type="file" hidden onChange={handleFileSelect} accept=".vkey" />
                                <span className="upload-text cursor-pointer">Browse .vkey File</span>
                            </label>
                                <Validators type="Required" value={formValues.vkey} message="Required field" controlName="vkey" onErrors={collectErrors} />
                            <p className="mt-[0.5rem]">File name should be same as project E.g. projectname.Vkey</p>
                        </>
                        :
                        <>
                            <p className="file-name">{formValues.vkey?.name}</p>
                            <p className="remove-text cursor-pointer" onClick={removeFile} data-info='vkey'>Remove</p>
                        </>
                    }                    
                </div>
                <div className="upload-file-item">
                    {formValues.skey === undefined || !formValues.skey ? 
                        <>
                            <label> 
                                <input name="skey" type="file" hidden onChange={handleFileSelect} accept=".skey" />
                                <span className="upload-text cursor-pointer">Browse .skey File</span>
                            </label>
                                <Validators type="Required" value={formValues.skey} message="Required field" controlName="skey" onErrors={collectErrors} />
                            <p className="mt-[0.5rem]">File name should be same as project E.g. projectname.Skey</p>
                        </>
                        :
                        <>
                            <p className="file-name">{formValues.skey?.name}</p>
                            <p className="remove-text cursor-pointer" onClick={removeFile} data-info='skey'>Remove</p>
                        </>
                    }                    
                </div>
            </div>
            <div className="flex gap-[1rem] justify-center mt-[2rem]">
                <Button label="Cancel" onClick={onClose} className='transaction-cancel' />
                <Button className='transaction-ok' label={`Upload Files`} onClick={handleUploadFile} />
            </div>
        </div>
    )
}

export default UploadKeyFilesPopUp
import { useState } from "react";
import { useEffect } from "react";
import "./FramePreview.scss"
const FramePreview = ({ metaInfo, src, toggleChange = false, noStyle = false, noPreview = false, className, classes = '', }) => {
    const [frameStyle, setFrameStyle] = useState(null)
    const [imageStyle, setImageStyle] = useState(null)
    const [frameSrc, setFrameSrc] = useState(null)

    useEffect(() => {
        if (metaInfo) {
            let val;
            if (typeof metaInfo !== 'object') {
                val = JSON?.parse(metaInfo)
            }
            else {
                val = metaInfo
            }

            const { frame: { width, height }, image: { heightPercent, widthPercent, xPercent, yPercent } } = val;
            let frameWidth = width;
            let frameHeight = height;

            let maxFrame = Math.max(frameWidth,frameHeight);
            if (maxFrame > 250)
            {
                frameWidth *= 250/maxFrame;
                frameHeight *= 250/maxFrame;
            }            

            setFrameStyle((prev) => {
                return { ...prev, width:frameWidth, height:frameHeight }
            });
            setImageStyle({
                left: `${xPercent}%`,
                top: `${yPercent}%`,
                height: `${heightPercent}%`,
                width: widthPercent ? `${widthPercent}%` : 0
            });
        }
    }, [metaInfo])
    useEffect(() => {
        if (src) {
            setFrameSrc(src)
        }
    }, [src])


    return (

        (src ?
            <div className={classes}><div className={`frame-preview-outer ${className}`} style={noStyle ? {  } : frameStyle}>
                <img src={frameSrc} alt="Noimg" className="frame-toggle-image" />
                <div className={`${toggleChange ? 'frame-image-on' : 'frame-image-off'} frame-preview-image`} style={imageStyle}></div>
            </div> </div> :
            !noPreview &&
            <div className="test flex w-full items-center">
                <span className="name flex h-full justify-center items-center  w-full">No Preview Available</span>
            </div>)
    )
}
export default FramePreview;
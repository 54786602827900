import "./User.scss"
import Person from "Assets/Images/Person.svg";
const User = (props) => {

    return (
        <div className={props.className}>
            <div className='flex items-center user-outer'>
            <div className='user' style={{backgroundImage:`url(${props.src ?? Person})`}}>
            </div>
    
               <div>
                    <div className="mr-4 user-name">{props.label}</div>
                    {props.email && <p className="user-desc">{props.email}</p>}
                    {props.userName && <p className="user-desc">{props.userName}</p>}
                </div>
            </div>
        </div>
    )
}
export default User;
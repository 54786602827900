import { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import './DateTimePicker.scss';
var count = 0;
const DateTimePicker = ({value, onChange, controlName}) => {
    const [dateTimeValue, setDateTimeValue] = useState('');
    const [dateTime, setDateTime] = useState(new Date(value));
    useEffect(() => {
        var date = dateTimeValue?.isValid ? new Date(`${dateTimeValue?.year}-${dateTimeValue?.month?.number}-${dateTimeValue?.day} ${dateTimeValue?.hour}:${dateTimeValue?.minute}:${dateTimeValue?.second}`) : '';
        if(count === 0 && value) {
            date = new Date(value);
        }
        setDateTime(date)
        if(count > 0)
            onChange({[controlName]: date});
        count++;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dateTimeValue])
    useEffect(() => {
        return () => {
            count = 0;
        }
    },[])
    return (
        <div className="custome-datetimepicker">
            <DatePicker 
                value={dateTime}
                format="MMM, DD YYYY HH:mm:ss"
                containerClassName='datetimepickerContainer'
                inputClass="formControl"
                className="close-arrow"
                calendarPosition='bottom'
                fixMainPosition={true}
                fixRelativePosition={true}
                onChange={setDateTimeValue}
                plugins={[
                    <TimePicker position="bottom" />
                ]} 
            />
        </div>
    )
}


export default DateTimePicker;
import { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ show }) => {
    return (
        <Fragment>
            {show && (<div className="spinner">
                <ClipLoader color='#fff' loading={show} size={150} />
            </div>)}
        </Fragment>
    )
}
export default Spinner;
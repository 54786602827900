import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import GalleryDetailsHeader from "../../../Gallery/Components/GalleryDetailsHeader"
import { useCallback, useEffect, useState } from "react";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useParams } from "react-router-dom";

const NFTDropDetails = () => {
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    const [list, setList] = useState();
    const { id } = useParams();
    const breadCrumbs = [
        {
            link: '',
            label: 'Gallery',
            back: true
        },
        {
            link: null,
            label: 'Gallery Details'
        }
    ]

    const getNFTDropDetailsResult = useCallback((res) => {
        dispatch('hideSpinner');
        const list = res.data
        setList(list)
    }, [dispatch])

    const getNFTDropDetailsList = useCallback(() => {
        dispatch('showSpinner');
        triggerAPI({
            url: `gallery/get/${id}`, method: 'get'
        }, getNFTDropDetailsResult);
    }, [dispatch, getNFTDropDetailsResult, id, triggerAPI])

    useEffect(() => {
        getNFTDropDetailsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={breadCrumbs} className={'w-full'} title="Gallery Details" />
                </div>
            </div>
            <div className="pt-7">
                <GalleryDetailsHeader row={list} />
            </div>
        </div>

    )
}

export default NFTDropDetails
import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import Button from "App/Components/UI/Button"
import { objectToFormdata } from "App/helpers/utilities"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import AddFrameForm from "../../../../Components/AddFrameForm"
import "../../../../Components/FrameManagement.scss"

const breadCrumbs = [
    {
        link: '/dashboard/frames/free-frames',
        label: 'Basic Management'
    },
    {
        link: null,
        label: 'Create Basic Frame'
    }
]

const CreateFreeFrames = () => {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState();
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [isValid, setIsValid] = useState(false)
    const [touched, setTouched] = useState(false)

    const onChange = (formValues, valid) => {
        setDataList(formValues)
        setIsValid(valid)
    }
    const goToFreeFrames = () => {
        navigate("/dashboard/frames/free-frames")
    }

    const saveHandler = (data) => {
        if (isValid) {
            const list = { ...data }
            list.name = list.displayname;
            delete list.displayname;
            delete list.uid;
            delete list.price;
            delete list.totalEdition;
            delete list.s3ImageUrl;
            delete list.s3ImageUrlLandscape;
            if(!list.landscapeFile) 
                delete list.landscapeFile;
            const formData = objectToFormdata(list)
            dispatch('showSpinner');
            triggerAPI({
                url: 'frame-asset/free/add', data: formData, method: 'post'
            }, apiResult);
        }
        else{
            setTouched(true)
        }
    }
    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } });
        goToFreeFrames();
    }

    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={breadCrumbs} title="Create Free Frame" className="frame" />
                </div>
                <div className="flex gap-3">
                    <Button label="Cancel" className="transaction-cancel" onClick={goToFreeFrames} />
                    <Button label="Save" row={dataList} onClick={saveHandler} />
                </div>
            </div>
            <div className="pt-6">
                <AddFrameForm free onChange={onChange} touched={touched}/>
            </div>
        </div>
    )
}

export default CreateFreeFrames
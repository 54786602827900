/* eslint-disable react-hooks/exhaustive-deps */
import Pagination from "App/Components/UI/Pagination";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";
import Button from "App/Components/UI/Button";
import ButtonAction from "App/Components/UI/ButtonAction";
import ActionButton from "Assets/Images/ActionButton.svg";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import Status from "App/Components/UI/Status";
import MenuDel from "Assets/Images/menu-del.svg";
import MenuPen from "Assets/Images/menu-pen.svg";
import MenuLink from "Assets/Images/menu-link.svg";
import Popup from "App/Components/UI/Popup";
import CreateSubAdmin from "./Components/CreateSubAdmin";
import "./SubAdmin.scss";
import { LIST_LIMIT } from "App/constants";

const paginationConfig = {
  perPage: LIST_LIMIT,
};

const SubAdmin = () => {
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [list, setList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [initial, setInitial] = useState(true);
  const [showRemovePop, setShowRemovePop] = useState({ mode: false });
  const [showActivatePop, setShowActivatePop] = useState({ mode: false });
  const [showCreateSubAdminPopup, setShowCreateSubAdminPopup] = useState({
    mode: false,
  });
  const onPageChangeHandler = (page) => {
    setPage(page);
            const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
  };

  useEffect(() => {
    dispatch("setPageTitle", "Admin Management");
  }, [dispatch]);

  const actionHandler = (e) => {
    setSelectedData(e.row);
    switch (e?.action) {
      case "remove":
        return setShowRemovePop({ mode: true });
      case "edit":
        return setShowCreateSubAdminPopup({ mode: true });
      case "block":
      case "unblock":
        return setShowActivatePop({ mode: true });
      default:
        return;
    }
  };
  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showRemovePop?.mode) setShowRemovePop({ mode: false });
    else if (showActivatePop?.mode) setShowActivatePop({ mode: false });
    else setShowCreateSubAdminPopup({ mode: false })
    getSubAdminList();
  };
  const removeHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/sub-admin/${selectedData?.id}/remove`,
        data: {},
        method: "delete",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              "Removing sub admin failed",
          },
        });
      }
    );
  };

  const handleAdd = () => {
    setSelectedData({});

    setShowCreateSubAdminPopup({ mode: true });
  };
  const onCloseCreateSubAdmin = (data) => {
    setShowCreateSubAdminPopup({ mode: false });
    if (data) {
      getSubAdminList();
    }
  };

  const activateHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `admin/sub-admin/${selectedData?.id}/${
          selectedData?.isBlocked ? "unblock" : "block"
        }`,
        data: {},
        method: "patch",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              `${
                selectedData?.isBlocked ? "Blocking" : "Unblocking"
              } sub admin failed`,
          },
        });
      }
    );
  };


  const onCloseRemove = (data) => {
    if (data) {
      removeHandler();
    } else {
      setShowRemovePop({ mode: false });
    }
  };

  const onCloseActivate = (data) => {
    if (data) {
      activateHandler();
    } else {
      setShowActivatePop({ mode: false });
    }
  };

  const getSubAdminRoleResponse = useCallback(
    (res) => {
      let role = res?.data?.map((val) => {
        return { ...val, name: val?.role };
      });
      setRoleList(role);
    },
    [dispatch]
  );

  const getSubAdminRoleList = useCallback(() => {
    triggerAPI(
      {
        url: `role`,
        method: "get",
      },
      getSubAdminRoleResponse
    );
  }, [dispatch, getSubAdminRoleResponse, triggerAPI]);

  const getSubAdminResponse = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { list, pageMeta } = res.data;
      setList(list);
      setTotalPage(pageMeta?.totalItems);
    },
    [dispatch]
  );

  const getSubAdminList = useCallback(() => {
    dispatch("showSpinner");

    triggerAPI(
      {
        url: `admin/sub-admin/list`,
        data: {
          page: page,
          items: paginationConfig.perPage,
        },
        method: "post",
      },
      getSubAdminResponse
    );
  }, [dispatch, getSubAdminResponse, page, triggerAPI]);

  useEffect(() => {
    getSubAdminRoleList();
    if (page !== 1) {
      setPage(1);
    } else {
      getSubAdminList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!initial) {
      getSubAdminList();
    }
    setInitial(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const actionActivateMenu = [
      {
        label: "Edit",
        action: "edit",
        src: MenuPen,
      },
      {
        label:  "Block",
        action: "block",
        src: MenuLink,
      },
      {
        label: "Remove",
        action: "remove",
        src: MenuDel,
      },
    ];
    const actionInactivateMenu = [
      {
        label: "Edit",
        action: "edit",
        src: MenuPen,
      },
      {
        label:  "Unblock" ,
        action: "unblock",
        src: MenuLink,
      },
      {
        label: "Remove",
        action: "remove",
        src: MenuDel,
      },
    ];
  return (
    <div className="sub-admin-management">
      <div className="flex justify-end items-center mb-4">
        <div>
          <Button label="Add Sub Admin" onClick={handleAdd} />
        </div>
      </div>
      <div>
        <Table>
          <thead className="text-left">
            <tr>
              <th>
                <TableHeader label="Name" />
              </th>
              <th>
                <TableHeader label="Email" />
              </th>
              <th>
                <TableHeader label="Role" />
              </th>
              <th>
                <TableHeader label="Status" />
              </th>
              <th>
                <TableHeader label="Action" />
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((list, index) => (
              <tr key={`${list?.id}`}>
                <td>{list?.name}</td>
                <td>{list?.email}</td>
                <td className="capitalize">{list?.roleInfo?.role}</td>
                <td>
                  <Status label={list?.isBlocked ? "Inactive" : "Active"} />
                </td>
                <td>
                  <div className="flex justify-end items-center">
                    <ButtonAction
                      src={ActionButton}
                      menu={list?.isBlocked?actionInactivateMenu:actionActivateMenu}
                      row={list}
                      onAction={actionHandler}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!list?.length && (
          <div className="text-center no-rows pt-4">
            <p>No data found</p>
          </div>
        )}
        <div className="mt-6 mb-6">
          <Pagination
            itemsPerPage={paginationConfig.perPage}
            rows={list?.length}
            total={totalPage}
            onPageChange={onPageChangeHandler}
            selected={page}
          />
        </div>
        <ConfirmPopup
          row={selectedData}
          show={showRemovePop}
          content="Are you sure want to remove this sub admin?"
          buttonYes="Yes"
          buttonCancel="No"
          style={{ maxWidth: "30rem" }}
          title="Remove Sub Admin"
          cssClass="delete-user"
          onClose={onCloseRemove}
        ></ConfirmPopup>
        <ConfirmPopup
          row={selectedData}
          show={showActivatePop}
          content={`Are you sure want to ${
            selectedData?.isBlocked ? "unblock" : "block"
          } this sub admin?`}
          buttonYes="Yes"
          buttonCancel="No"
          style={{ maxWidth: "30rem" }}
          title={`${
            selectedData?.isBlocked ? "Unblock" : "Block"
          } Sub Admin`}
          cssClass="delete-user"
          onClose={onCloseActivate}
        ></ConfirmPopup>
        <Popup
          show={showCreateSubAdminPopup}
          style={{ maxWidth: "40rem" }}
          title={`${selectedData.id ? "Edit Sub Admin" : "Add Sub Admin"}`}
          cssClass="user-create-pop"
          onClose={onCloseCreateSubAdmin}
        >
          <CreateSubAdmin
            list={roleList}
            triggerClose={onCloseCreateSubAdmin}
            row={selectedData}
          />
        </Popup>
      </div>
    </div>
  );
};

export default SubAdmin;

import { Bar, Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import SelectBox from "App/Components/UI/SelectBox";
import { LineGraph } from "App/configs/lineGraph";
import { useState } from "react";
import { useEffect } from "react";
import FormatField from "App/Components/UI/FormatField";
import Calender from "Assets/Images/Calender.svg";
import DatePickerFilter from "App/Components/UI/DatePickerFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);
const Graph = (props) => {
  const [value, setValue] = useState([]);
  const month = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [valueYear, setValueYear] = useState([]);
  const [dayDates, setDayDates] = useState([]);
  const [range, setRange] = useState({ mode: true });
  const [userSelected, setUserSelected] = useState("month");
  const [policySelected, setPolicySelected] = useState("month");
  const [salesSelected, setSalesSelected] = useState("month");
  const [visitorsSelected, setVisitorsSelected] = useState("month");
  const [dates, setDates] = useState([]);

  let oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [calender, setCalender] = useState({
    from: oneWeekAgo.toISOString(),
    to: new Date().toISOString(),
  });

  const [calenderToggle, setCalenderToggle] = useState(false);
  const onCalenderToggle = () => {
    setCalenderToggle(!calenderToggle);
  };
  const onCloseHandler = (e) => {
    setCalenderToggle(false);
  };
  const onCloseHandlerPop = (e) => {
    setCalenderToggle(false);
    setCalender({
      from: oneWeekAgo.toISOString(),
      to: new Date().toISOString(),
    });
    setDates([]);
  };
  const onApply = (date) => {
    const value = new Date(date.to);
    value?.setUTCHours(0, 0, 0, 0);
    setCalender({ from: date?.from, to: value?.toISOString() });
    onCloseHandler();
  };

  const config = LineGraph.getConfig({ showX: props.showX });

  const options = {
    ...config,
  };
  const data = {
    labels:
      userSelected === "month"
        ? LineGraph.months
        : userSelected === "day"
        ? dayDates
        : valueYear,
    datasets: [
      {
        data: value,
        fill: true,
        backgroundColor: LineGraph.colors.backgroundColor,
        borderColor: LineGraph.colors.borderColorGraph,
      },
    ],
  };
  const barData = {
    labels: range.mode ? LineGraph.months : valueYear,
    datasets: [
      {
        data: value,
        fill: true,
        backgroundColor: LineGraph.colors.barGraphBackgroundColor,
        borderRadius: Number.MAX_VALUE,
        hoverBackgroundColor: "yellow",
      },
    ],
  };

  useEffect(() => {
    if (props?.data?.length) {
      const arr = [];
      let Count = 0;
      const checkMonthIsPresent = props.data?.some((item) => item?.month);
      const checkDayIsPresent = props.data?.some((item) => item?.day);
      if (checkMonthIsPresent) {
        month.forEach((val, index) => {
          props?.data.forEach((element) => {
            const date = new Date(element.month);
            if (date.getMonth() === val) {
              Count = element.totalCount;
            }
          });
          if (Count) {
            arr[index] = Count;
            Count = 0;
          } else {
            arr[index] = Count;
          }
        });
      } else if (checkDayIsPresent) {
        props.data?.forEach((val, index) => {
          // props?.data.forEach(element => {
          //     const date = new Date(element.year)
          //     if (date.getFullYear() === val.name) {
          //         Count = element.totalCount
          //     }

          // });
          Count = props?.data[index]["totalCount"];
          if (Count) {
            arr[index] = Count;
            Count = 0;
          } else {
            arr[index] = Count;
          }
        });
      } else {
        props.year?.forEach((val, index) => {
          props?.data.forEach((element) => {
            const date = new Date(element.year);
            if (date.getFullYear() === val.name) {
              Count = element.totalCount;
            }
          });
          if (Count) {
            arr[index] = Count;
            Count = 0;
          } else {
            arr[index] = Count;
          }
        });
      }
      setValue(arr);
    } else {
      setValue([]);
    }
    const yearSelected = props.year?.map((item) => item.name);
    setValueYear(yearSelected);
    const daysRange = props.data?.map(
      (item) =>
        `${("0" + (new Date(item.day).getUTCMonth() + 1)).slice(-2)}/${(
          "0" + new Date(item.day).getUTCDate()
        ).slice(-2)}`
    );
    setDayDates(daysRange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, range]);

  const inputOnChange = (e) => {
    if (e?.user) {
      setUserSelected(e.user);
    } else if (e?.visitors) {
      setVisitorsSelected(e.visitors);
    } else if (e?.sales) {
      setSalesSelected(e.sales);
    } else {
      setPolicySelected(e.policy);
    }
    props.onSelectedRange(e);
    let exists = Object.values(e).includes("month");
    if (exists) {
      setRange({ mode: true });
    } else {
      setRange({ mode: false });
    }
  };
  const onSelectedYear = (e) => {
    props.onSelectedYear(e);
    let exists = Object?.keys(e)?.includes("userSelectedYear");
    let existsPolicy = Object?.keys(e)?.includes("policySelectedYear");
    let existsSales = Object?.keys(e)?.includes("salesSelectedYear");
    let existsVisitors = Object?.keys(e)?.includes("visitorsSelectedYear");
    if (exists || existsPolicy || existsSales || existsVisitors) {
      setRange({ mode: true });
    } else {
      setRange({ mode: false });
    }
  };

  return (
    <div className="mt-5 py-6">
      <div className="flex justify-between pl-4 ">
        <div className="back">
          <div className="analytics-label">{props.label}</div>
        </div>
        <div className="flex gap-3">
          {props.download && (
            <div>
              <img
                src={props.download}
                alt="dropdown"
                className="cursor-pointer"
                onClick={props.onDownloadHandler}
              />
            </div>
          )}
          {props.list && (
            <div className="flex analytics-dropdown">
              <SelectBox
                list={props.list}
                controlName={props.controlName}
                placeHolder="Monthly"
                className="graph-range"
                onChange={inputOnChange}
              />
            </div>
          )}
          {userSelected === "day" && props.controlName === "user" && (
            <div className="flex justify-end py-2">
              <div className="flex cursor-pointer" onClick={onCalenderToggle}>
                <div className="flex">
                  <img src={Calender} alt="calender" className="mr-2" />
                  <div className="flex analytics-date">
                    <FormatField type="date" value={calender?.from} />
                    {`  -  `}
                    <FormatField type="date" value={calender?.to} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.year &&
            ((userSelected === "month" && props.controlName === "user") ||
              (visitorsSelected === "month" &&
                props.controlName === "visitors") ||
              (policySelected === "month" && props.controlName === "policy") ||
              (salesSelected === "month" && props.controlName === "sales")) && (
              <div className="flex">
                <SelectBox
                  list={props.year}
                  controlName={props.selectedYear}
                  placeHolder={props?.chosenYear ?? new Date().getFullYear()}
                  className="graph-range"
                  onChange={onSelectedYear}
                />
              </div>
            )}
        </div>
      </div>
      {!props?.graphType ? (
        <Line data={data} options={options} />
      ) : (
        <Bar type="bar" options={options} data={barData} />
      )}
      {calenderToggle && (
        <DatePickerFilter
          className="analytics-template"
          show={{ mode: true }}
          onClose={onCloseHandlerPop}
          onCloseHandler={onCloseHandler}
          onFilterData={onApply}
          data={calender}
          setDates={setDates}
          dates={dates}
        />
      )}
    </div>
  );
};

export default Graph;

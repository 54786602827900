import PageTab from "App/Components/UI/PageTab";
import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "../InformationDesk.scss";
const tabs = [
  {
    link: "category",
    label: "Tutorials",
  },
  {
    link: "terms",
    label: "Terms",
  },
  {
    link: "policy",
    label: "Policy",
  },
];
const InformationDeskLayout = () => {
  const dispatch = useStore(false)[1];

  useEffect(() => {
    dispatch("setPageTitle", "Information Desk");
  }, [dispatch]);

  return (
    <div>
      <PageTab tabs={tabs} />
      <div className="py-4">
        <Outlet />
      </div>
    </div>
  );
};

export default InformationDeskLayout;

import adaIcon from "Assets/Icons/ada_svg_grey.svg";
import { formatLargeNumber, numberWithCommas } from "App/helpers/utilities";
import "./MetricsData.scss";
import { useEffect, useState } from "react";
import SkeletonLoader from "App/Components/UI/SkeletonLoader";

const MetricsData = ({ data, shapes }) => {
    const [loader, setLoader] = useState(true);
    useEffect(() => {
       setLoader(!data?.id)
    }, [data]);

    return (
      <div className={`flex w-full justify-between py-[1rem]`}>
        {/* Detail Boxes */}
        <div className={`flex w-full mt-[0.5rem] max-h-[3.5rem]`}>
          {/* Floor box */}
          <div className={`common__data-box !pl-0`}>
            <p className={`data-title`}>Floor</p>
            <div className={`data-value`}>
              {loader ? (
                <SkeletonLoader height={15} count={1} inline={true} />
              ) : (
                <div className={`data-value`}>
                  {data?.metrics?.[0]?.floorPrice ? (
                    <span className={`flex items-center gap-1`}>
                      {" "}
                      <img
                        className={`common_image_style`}
                        alt="Img"
                        src={adaIcon}
                      />{" "}
                      {data?.metrics[0]?.floorPrice}
                    </span>
                  ) : (
                    <p className="data-value">{'-'}</p>
                  )}
                </div>
              )}
            </div>
          </div>
  
          {/* Volume box */}
          <div className={`common__data-box`}>
            <p className={`data-title`}>Volume</p>
            <div className={`data-value`}>
              {loader ? (
                <SkeletonLoader height={15} count={1} inline={true} />
              ) : data?.metrics?.[0]?.allVolume ? (
                <span className={`flex items-center gap-1`}>
                  {" "}
                  <img
                    className={`common_image_style`}
                    alt="Img"
                    src={adaIcon}
                  />{" "}
                  {formatLargeNumber(data?.metrics[0]?.allVolume) ?? "-"}
                </span>
              ) : (
                <p className="data-value">{'-'}</p>
              )}
            </div>
          </div>
  
          {/* Pieces box */}
          <div className={`common__data-box`}>
            <p className={`data-title`}>{"Pieces"}</p>
  
            {loader ? (
              <div className={`data-value`}>
                <SkeletonLoader height={15} count={1} inline={true} />
              </div>
            ) : (
              <div className={`data-value flex`}>
                {numberWithCommas(
                  data?.assetCount === 1 ? data?.assetCount : data?.metrics?.[0]?.supply ?? data?.assetCount
                ) ?? "-"}
              </div>
            )}
          </div>
  
          {/* Owners box */}
          <div className={`common__data-box`}>
            <p className={`data-title`}>Owners</p>{" "}
            {loader ? (
              <div className={`data-value`}>
                <SkeletonLoader height={15} count={1} inline={true} />
              </div>
            ) : (
              <div className={`data-value flex`}>
                {numberWithCommas(
                  data?.metrics?.[0]?.owners ?? data?.ownerCount
                ) ?? "-"}
              </div>
            )}
          </div>
          <div className={`common__data-box`}>
            <p className={`data-title`}>Shapes</p>
            <div className={`data-value`}>
              {loader ? (
                <SkeletonLoader height={15} count={1} inline={true} />
              ) : (
                <img className={`shapes_box-style mb-1`} alt="Img" src={shapes} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default MetricsData
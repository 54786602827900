import { Fragment } from "react"

const AnalyticsHeader = (props) => {
    return (
        <Fragment>
            <div>
                <div className="title text-white">{props.label}</div>
                <div className="count analytics-header-count pt-1 text-blue-500">{props.symbol && <span>{props.symbol}</span>}{props.totalCount}</div>
                <div className="title text-white text-sm">{props.labelTwo}</div>
                <div className="count analytics-header-count pt-1 text-blue-500 text-sm">{props.symbol && <span>{props.symbol}</span>}{props.count}</div>

                <div className="analytics-header pt-3">
                    {`${props.percent} `}<span>over past {props.days} days</span>
                </div>
            </div>
            <div className="flex justify-start items-start analytics-header-image">
                <img src={props.src} alt="analytics" width="35px" height="35px" />
            </div>
        </Fragment>
    )
}

export default AnalyticsHeader
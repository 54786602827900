import "./MultiSelectFilter.scss"
const MultiSelectFilter = (props) => {

    const handleChange = (e) => {
        props.onChange(e)
    }
    return (
        <div className="pt-4 grid grid-cols-1 md:grid-cols-5 2xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-5 gap-9">
            {
                props.list?.map((item, index) => (
                    <label key={item} className="multiselect-filter-label multiselect capitalize">
                        <input type='checkbox' checked={props.checked?.includes(item)} name={item} value={index} onChange={handleChange} />
                        {item}<span class="checkmark"></span>
                    </label>
                ))
            }
        </div>
    )
}

export default MultiSelectFilter
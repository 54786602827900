import React, { useState } from "react";
import { useEffect } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./Range.scss";

const Range = ({ min = 0, max = 100, onChange, currentId }, props) => {
const [minVal, setMinVal] = useState(10);
const [maxVal, setMaxVal] = useState(50);
const handleChange = (e) => {   
  const minVal = e && e.length && e[0]; 
  const maxVal = e && e.length && e[1]; 
    setMinVal(minVal);
    setMaxVal(maxVal);
    onChange({minVal, maxVal})
}
useEffect(() => {
  if(currentId?.minVal)
    setMinVal(currentId?.minVal);
  if(currentId?.maxVal)
    setMaxVal(currentId?.maxVal);
},[currentId])
return (
    <div className="range-slider-container">
        <RangeSlider min={min} max={max} defaultValue={[10, 50]} onInput={handleChange} />
        <div className="rang-values flex justify-between mt-[1rem]">
          <span>{minVal}</span>
          <span>{maxVal}</span>
        </div>
    </div>
  );
};
export default Range;

import BreadCrumbs from "App/Components/UI/BreadCrumbs"
import Button from "App/Components/UI/Button"
import { objectToFormdata } from "App/helpers/utilities"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import AddFrameForm from "../../../../Components/AddFrameForm"
const breadCrumbs = [
    {
        link: '/dashboard/frames/nft-frames',
        label: 'Frame Management'
    },
    {
        link: null,
        label: 'Create NFT Frame'
    }
]

const CreateNFTFrames = () => {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState();
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [isValid, setIsValid] = useState(false)
    const [touched, setTouched] = useState(false)

    const goToNFTFrames = () => {
        navigate("/dashboard/frames/nft-frames")
    }

    const onChange = (formValues, valid) => {
        setDataList(formValues)
        setIsValid(valid)
    }

    const saveHandler = (data) => {
        if (isValid) {
            const date = new Date();
            const list = { ...data }
            delete list.s3ImageUrl;
            delete list.s3LandImageUrl;
            delete list.s3ImageUrlLandscape;
            delete list.price;
            list.metaInfo.portrait = list?.metaInfo?.portrait?? {};
            list.metaInfo.landscape = list?.metaInfo?.landscape?? {};
            list.collectionName  = list?.displayname;
            list.categoryId  = 1;
            list.currencyTypeId  = 2;
            list.year  = date.getFullYear();
            if(!list.landscapeFile) 
                delete list.landscapeFile;
            const formData = objectToFormdata(list)
            dispatch('showSpinner');
            triggerAPI({
                url: 'nft-maker/project/create-nft', data: formData, method: 'post'
            }, apiResult);
        }
        else {
            setTouched(true)
        }
    }
    const apiResult = (res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        goToNFTFrames();
    }
    return (
        <div>
            <div className="flex justify-between">
                <div className="back">
                    <BreadCrumbs links={breadCrumbs} title="Create NFT Frame" className="frame" />
                </div>
                <div className="flex gap-3">
                    <Button label="Cancel" className="transaction-cancel" onClick={goToNFTFrames} />
                    <Button label="Save" row={dataList} onClick={saveHandler} />
                </div>
            </div>
            <div className="pt-6">
                <AddFrameForm nft onChange={onChange} touched={touched} />
            </div>
        </div>
    )
}
export default CreateNFTFrames
import ToggleSwitch from "App/Components/UI/ToggleSwitch"
import { convertJsonToArray, convertPriceToAda } from "App/helpers/utilities"
import { useEffect, useState } from "react"
import FrameCard from "../../../Components/FrameCard"

const NFTFrameCard = ({ row = {}, onChange, onClickHandler, check, onChangeDisplayStatus, checkDisplayStatus }) => {
    const [framePrice, setFramePrice] = useState(0);
    useEffect(()=>{
        const pricelist = row?.project?.pricelist;
        if(pricelist) {
            const priceData = convertJsonToArray(pricelist);
            const price = priceData?.length ? (priceData[0]?.priceInLovelace ? `ADA ${convertPriceToAda(priceData[0]?.priceInLovelace)}` : `${priceData[0]?.currency?.toUpperCase()} ${priceData[0]?.price}`) : 0;
            setFramePrice(price);
        }
    },[row])
    const frameDetailClick = () => {
        onClickHandler({ mode: true, action: "view" }, row)
    }
    return (
        <div>
            <FrameCard src={row.s3ImageUrl256} row={row} onClickHandler={onClickHandler} nftFrame={true} />
            <div className="pt-2 frames nft-frames">
                <ul className="ml-3 pb-2" onClick={frameDetailClick}>
                    {row.name}
                    <li>ID: {row.id}</li>
                    <li>Price: <span> {framePrice}</span>
                    </li>
                    <li>Total supply:
                        <span> {row.totalEdition}</span>
                    </li>
                </ul>
                <div className="flex justify-between  m-3">
                    <div className="total">Promo Frame</div>
                    <ToggleSwitch row={row} onChange={onChange} check={check} />
                </div>
                <div className="flex justify-between  m-3">
                    <div className="total">Frame Shop</div>
                    <ToggleSwitch row={row} onChange={onChangeDisplayStatus} check={checkDisplayStatus} />
                </div>
            </div>

        </div>
    )
}
export default NFTFrameCard
import Spinner from "App/Components/UI/Spinner";
import useAuth from "App/hooks/use-auth";
import ForgotPassword from "App/Pages/Auth/ForgotPassword";
import Login from "App/Pages/Auth/Login";
import ResetPassword from "App/Pages/Auth/ResetPassword";
import { lazy, Suspense } from "react";
import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

const LoginLayout = lazy(() => import("App/Pages/Auth/LoginLayout"));
const Public = () => {
    const token = useAuth()[0];
    return (
        <Suspense fallback={<Spinner show={true} />}>
            <Routes>
                <Route element={<LoginLayout />} >
                    <Route index element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    {!token && (<Route
                        path="*"
                        element={<Navigate to="" />}
                    />)}
                </Route>
            </Routes>
        </Suspense>
    )
}
export default Public;